import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ShippingService } from '../_services/shipping.service';
import { User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';

@Component({
  templateUrl: 'admin.component.html',
  styleUrls: ['./admin.component.scss'] })
export class AdminComponent implements OnInit {
  customers: string[] = AuthenticationService.customers;
  loading = false;
  users: User[] = [];
  timeout: string;
  uspsCarrier: boolean;
  rollbackInputs = new RollbackInput();

  constructor(
    private userService: UserService,
    private shippingService: ShippingService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.getCustomer().then(x => this.customers = x);
    this.authenticationService.user.subscribe(x => {
      this.uspsCarrier = x.uspsCarrier == "Endicia";
    });
  }

  ngOnInit() {
    this.loading = true;
    this.userService.getAll().pipe(first()).subscribe(users => {
      this.loading = false;
      this.users = users;
    });

    this.getTimeoutOnClick();
  }

  getTimeoutOnClick() {
    console.log('this.authenticationService.tokenTimeOut', this.authenticationService.tokenTimeOut);
    this.timeout = this.authenticationService.tokenTimeOut;
  }

  refreshToken() {
    this.authenticationService.refreshTokenTimer();
  }
  onCarrierChange(event) {
    //const carrier = event.target.checked ? "Endicia" : "Pitney Bowes";
    //if (confirm(`Do you really want to change the carrier service to ${carrier}?`)) {

    //  return true;
    //} else {
    //  return false;
    //}
    this.shippingService.changeUspsCarrierService(event.target.checked).subscribe(data => {
    });;
  }

  rollbackOrder() {
    console.log(this.rollbackInputs);
    this.shippingService.rollbackOrder(this.rollbackInputs)
      .subscribe(data => {
        console.log(data);
        if (data["isSuccess"] == true) {
          alert('successfully rolled-back');
          this.rollbackInputs = new RollbackInput();
        } else {
          alert('failed to rollback');
        }
      });
    
  }
}

class RollbackInput {
  customer: string;
  orderno: number;
}
