import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })

export class LabelService {
  constructor(private http: HttpClient) { }

  getLabel(model) {
    var url: string = `${environment.apiUrl}/label/getlabel`;
    return this.http.post(url, model);
  }

  
  getList(model) {
    var url: string = `${environment.apiUrl}/label/GetList`;
    return this.http.post(url, model);
  }

  getLabelRate(model) {
    var url: string = `${environment.apiUrl}/label/GetLabelRate`;
    return this.http.post(url, model);
  }

  retrieveLabelsByRefNo(customer: string, refNo: string) {
    console.log(refNo);
    return this.http.get(`${environment.apiUrl}/label/RetrieveLabelsByReferenceNumber?customer=${customer}&referenceNo=${refNo}`);
  }

  postAddress(model) {
    console.log(model);

    var url: string = `${environment.apiUrl}/addressbook/PostAddress`;

    return this.http.post(url, model);
  }

  getAddressList() {
    var url: string = `${environment.apiUrl}/addressbook/GetList`;
    return this.http.get(url);
  }

  validateAddress(model) {
    var url: string = `${environment.apiUrl}/label/ValidateAddress`;
    return this.http.post(url, model);
  }
}

