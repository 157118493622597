import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<User[]>(`${environment.apiUrl}/users`);
  }

  sendNotification(cus, subject, message) {
    var url: string = `${environment.apiUrl}/users/SendNotification`;
    return this.http.post(url, { customer: cus, subject, message});
  }
}
