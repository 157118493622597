<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw;z-index: 1000; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="    position: absolute;
    width: 100vw;
    text-align: center;
    z-index: 1000;
    top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>
<div class="container">
  <h3 style="font-weight:700">Manual Order Creation</h3>
  <div style="position:relative;float:right;display:none;">
    <div>
      <label>Sheet line#:</label> &nbsp;
      <input type="number" [(ngModel)]="googleSheetLineNumber" style="padding-left:12px;border:solid 1px #eee;border-radius:1.5rem;width: 94px;" id="txtLineNumber" placeholder="Line #" />
      <button mdbBtn class="btn btn-amber" rounded="true" size="sm" type="submit" mdbWavesEffect (click)="importGoogleOrder()">Import Beauteers Orders from Google Sheets</button>
    </div>
    <div>
      Last updated: <label>{{googleSheetLastUpdated}}</label>
    </div>
  </div>

  <form [formGroup]="shippingForm" (ngSubmit)="loadinfo(shippingForm.value)">

    <table class="fixed">
      <tr>
        <td>Customer :</td>
        <td>
          <select id="customer" [formControl]="shippingForm.controls['customer']" (change)="loadinfo(shippingForm.value)">
            <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
          </select>
        </td>
      </tr>
      <!--<tr>
        <td>Order # :</td>
        <td>
          <input type="number" style="width: 94px;" id="orderno" placeholder="Reorder only" [formControl]="shippingForm.controls['orderno']" (change)="ordernumberchange()" />
        </td>
      </tr>
      <tr>
        <td>New Order # :</td>
        <td>
          <span>{{shippingForm.get('neworderno').value}}</span>
          <input type="number" style="width: 94px;" id="orderno" placeholder="Order #" [formControl]="shippingForm.controls['neworderno']" (change)="ordernumberchange()" />
        </td>

      </tr>-->
      <tr *ngIf="isManual">
        <td>Customer #:</td>
        <td>
          <input type="text" id="customerno" [formControl]="shippingForm.controls['customerno']" />
        </td>
        
      </tr>
    </table>
  </form>


  <h3 style="font-weight:500">1. Item Info</h3>
  <table class="table table-bordered table-sm">
    <thead>
      <tr align="center">
        <th scope="col">#</th>
        <th scope="col" for="sku">Sku *</th>
        <th scope="col" for="desc">Description</th>
        <th scope="col" for="qty">Qty *</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of items; let i = index">
        <td align="center">
          <span>{{i+1}}</span>
        </td>
        <td>
          <input type="text" list="skulist" id="sku" name="sku" required #sku="ngModel" [(ngModel)]="element.sku" (change)="selectSku(element.sku, element.qty, i)" />
          <datalist id="skulist">
            <select>
              <option *ngFor="let item of itemlist" [value]="item.sku"></option>
            </select>
          </datalist>
        </td>
        <td>
          <input type="text" list="desclist" id="desc" name="desc" [(ngModel)]="element.desc" (change)="selectDesc(element.desc, element.qty, i)" />
          <datalist id="desclist">
            <select>
              <option *ngFor="let item of itemlist" [value]="item.desc"></option>
            </select>
          </datalist>
        </td>
        <td>
          <input type="number" id="qty" name="qty" required #qty="ngModel" [(ngModel)]="element.qty" (change)="getWeight()">
        </td>
        <td>
          <span style="padding-right: 5px;">
            <button type="button" (click)="add()">add</button>
            <button *ngIf="items.length==1" type="button" (click)="reset()">clear</button>
            <button *ngIf="items.length>1" type="button" (click)="delete(i)">remove</button>
          </span>
        </td>
    </tbody>
  </table>

  <div style="padding-bottom: 20px;">Estimated Weight: {{estimWeight}} (lb)</div>



  <h3 style="font-weight:500">2. Shipping Info</h3>

  <div class="row">
    <div class="col-sm">
      <table class="table table-bordered table-sm">
        <tr>
          <td scope="col">Name *</td>
          <td>
            <input type="text" [(ngModel)]="shipTo.name" id="name" name="name" required #name="ngModel">
          </td>
        </tr>
        <tr>
          <td scope="col">Company</td>
          <td>
            <input type="text" [(ngModel)]="shipTo.company" id="company" name="company" #company="ngModel">
          </td>
        </tr>
        <tr>
          <td scope="col">Address 1 *</td>
          <td>

            <input type="text" [(ngModel)]="shipTo.address1" id="address1" name="address1" required #address1="ngModel">
          </td>
        </tr>
        <tr>
          <td scope="col">Address 2</td>
          <td>

            <input type="text" [(ngModel)]="shipTo.address2">
          </td>
        </tr>
        <tr>
          <td scope="col">City *</td>
          <td>

            <input type="text" [(ngModel)]="shipTo.city" id="city" name="city" required #city="ngModel">
          </td>
        </tr>
        <tr>
          <td scope="col">
            State
            <br /> <div style="font-size:x-small;">(Required for domestic shippings)</div>
          </td>
          <td>
            <input type="text" list="statelist" name="state" [(ngModel)]="shipTo.state" id="state" required #state="ngModel" />
            <datalist id="statelist">
              <select>
                <option *ngFor="let state of statelist" [value]="state.name"></option>
              </select>
            </datalist>
          </td>
        </tr>
        <tr>
          <td scope="col">ZipCode *</td>
          <td>

            <input type="text" [(ngModel)]="shipTo.zip" id="zip" name="zip" required #zip="ngModel">
          </td>

        </tr>
        <tr>
          <td scope="col">Country *</td>
          <td>
            <select [(ngModel)]="shipTo.country">
              <option *ngFor="let country of countryCodes" [ngValue]="country.countryCode">
                {{country.countryName}}
              </option>
            </select>
          </td>

        </tr>

      </table>
      <!--<div [hidden]="name.valid && name.touched" style="color:crimson; font-size:small">* Name is required</div>
      <div [hidden]="address1.valid && address1.touched" style="color:crimson; font-size:small">* Address1 is required</div>
      <div [hidden]="city.valid && city.touched" style="color:crimson; font-size:small">* City is required</div>
      <div [hidden]="state.valid && state.touched" style="color:crimson; font-size:small">* State is required</div>
      <div [hidden]="zip.valid && zip.touched" style="color:crimson; font-size:small">* Zipcode is required</div>-->
      <!--<div [hidden]="!sku.hasError('required')" style="color:crimson; font-size:small">* Sku is required</div>-->
    </div>



    <div class="col-sm">
      <table class="fixed" *ngFor="let label of labels; let i=index">
        <tr>
          <td class="r">Shipping Location</td>
          <td>
            <select [formControl]="shippingForm.controls['location']">
              <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
            </select>
          </td>
        </tr>
        <tr>
          <td class="r">Shipping Option</td>
          <td>
            <select [(ngModel)]="shippingOption" [ngModelOptions]="{standalone: true}">
              <option [hidden]="shipTo.country!='US'" *ngFor="let opt of shippingOptions" [ngValue]="opt">{{opt}}</option>
              <option [hidden]="shipTo.country=='US'" *ngFor="let opt of internationalShippingOptions" [ngValue]="opt.serviceCode">{{opt.serviceName}}</option>
            </select>
          </td>
        </tr>
        <tr>
          <td class="r">Signature</td>
          <td>
            <select [(ngModel)]="label.IsSignature">
              <option [ngValue]="true">True</option>
              <option [ngValue]="false">False</option>
            </select>
          </td>
        </tr>
        <tr>
          <td class="r">Certified</td>
          <td>
            <select [(ngModel)]="label.IsCertified">
              <option [ngValue]="true">True</option>
              <option [ngValue]="false">False</option>
            </select>
          </td>
        </tr>
      </table>

      <form [formGroup]="shippingForm">

        <table class="fixed">
          <tr>
            <td class="r">Charge to Customer</td>
            <td>
              <select [formControl]="shippingForm.controls['isChargeToCustomer']">
                <option [ngValue]="true">True</option>
                <option [ngValue]="false">False</option>
              </select>
            </td>
          </tr>
        </table>
      </form>

    </div>
  </div>

  <div *ngIf="shippingOption=='PriorityMailInternational'
                ||shippingOption=='PriorityMailExpressInternational'
                ||shippingOption=='FirstClassMailInternational'
                ||shippingOption=='FirstClassPackageInternationalService'
         ">
    <h3 style="font-weight:500">3. Customs Form</h3>
    <table class="table table-bordered table-sm">
      <thead>
        <tr align="center">
          <th scope="col">Item Description</th>
          <th scope="col">Country of Origin</th>
          <th scope="col">Value (US$)</th>
          <th scope="col">Quantity</th>
          <th scope="col">Weight (lbs.)</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody *ngFor="let customsItem of customsForm.customsItems; let i = index">
        <tr>
          <td><input type="text" [(ngModel)]="customsItem.description" /></td>
          <td>
            <select [(ngModel)]="customsItem.countryOfOrigin">
              <option *ngFor="let opt of countryCodes" [ngValue]="opt.countryCode">{{opt.countryName}}</option>
            </select>
          </td>
          <td><input type="number" [(ngModel)]="customsItem.value" /></td>
          <td><input type="number" [(ngModel)]="customsItem.quantity" /></td>
          <td><input type="number" [(ngModel)]="customsItem.weight" /></td>
          <td>
            <button (click)="addCustomItemInfo()">Add</button>
            <button (click)="deleteCustomItemInfo(i)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
    <table>
      <tr>
        <td>Non-Delivery Instructions: </td>
        <td>
          <mdb-checkbox (change)="nonDeliveryOption()">Return to sender</mdb-checkbox>
        </td>
      </tr>
    </table>
    <div style="color:red;padding-bottom:20px;">* If you prefer that the package be abandoned, leave this option unchecked.</div>
    <table>
      <tr>
        <td>Category Of Items: </td>
        <td>
          <select [(ngModel)]="customsForm.categoryOfItems">
            <option *ngFor="let opt of categoriesOfItems" [ngValue]="opt">{{opt}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Sender's Signature: </td>
        <td>
          <input type="text" [(ngModel)]="customsForm.senderSignature.name" />
        </td>
      </tr>

    </table>
  </div>
  <button type="button" style="margin-top:20px;"
          [disabled]="(name.invalid && name.untouched) ||
                      (address1.invalid && address1.untouched) ||
                      (city.invalid && city.untouched) ||
                      (state.invalid && state.untouched) ||
                      (zip.invalid && zip.untouched)
                     "
          (click)="submit()">
    Submit
  </button>
</div>




