<h1>Return List</h1>
<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>

<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" class="float-left">
  <div class="row" style="padding-bottom:5px; padding-right:10px;">

    <div style="padding-right:15px;">
      <select formControlName="location" class="browser-default custom-select" style="width: 100px;font-size: small;">
        <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
    <div>
      <select formControlName="customer" class="browser-default custom-select" style="width:120px;font-size:small;">
        <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
    <div style="padding-left:20px;padding-right:10px;">
      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'From'" formControlName="startAt" required></mdb-date-picker>
    </div>
    <div>
      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'To'" formControlName="endAt" required></mdb-date-picker>
    </div>
    <div style="padding-left:15px;padding-right:15px;">
      <button mdbBtn class="btn btn-amber" size="sm" type="submit" mdbWavesEffect>Submit</button>
    </div>
    <div style="padding-top:10px;" [hidden]="!total">
      Total: {{total}}
    </div>
  </div>
</form>

<form [formGroup]="memoForm" (ngSubmit)="onMemoSubmit(memoForm.value)" class="row float-right">
  <table>
    <tr>
      <td>
        <input type="text" class="form-control" formControlName="receivedNo" placeholder="receivedNo">
      </td>
      <td>
        <input type="text" class="form-control" formControlName="content" placeholder="memo">
      </td>
      <td>
        <button mdbBtn class="btn btn-amber" rounded="true" size="sm" type="submit" mdbWavesEffect>Submit</button>
      </td>
      <td>|</td>
      <td>
        <button mdbBtn type="button" color="amber" rounded="true" (click)="gotoRRCreation()" class="btn btn-success" size="sm" mdbWavesEffect>Create Return Receiving</button>
        <button mdbBtn type="button" color="success" rounded="true" (click)="ExportTOExcel()" class="btn btn-success" size="sm" mdbWavesEffect>Excel</button>
      </td>
    </tr>
  </table>

</form>

<table class="table table-bordered table-sm" style="margin-top: 10px;"  #TABLE #table>
  <thead>
    <tr>
     
      <th>Location</th>
    
      <th>RMA #</th>
     
      <th>Original Customer #</th>
      <th>Tracking #</th>
      <th>Status</th>
      <th>Items</th>
      <th>Receipt qty</th>
      <th>Unheld qty</th>
      <th>Return Code</th>
      <th>Note</th>
      <!--<th>Restock</th>-->
      <th>Created by</th>
      <th>Created At</th>
      <th>Memo</th>
      <th>Logs</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody *ngFor="let el of elements ; let i = index">
    <tr *ngFor="let item of el.items; let j = index">
     
      <td *ngIf="j==0">{{el.location}}</td>
      <td *ngIf="j>0" style="color:white;border-top:hidden">{{el.location}}</td>
      <td *ngIf="j==0">
        <a [routerLink]="['/return', el.location, el.receivedNo]">{{el.receivedNo}}</a>
      </td>
      <td *ngIf="j>0" style="color:white;border-top:hidden">{{el.receivedNo}}</td>
     
     
      <td *ngIf="j==0">{{el.originalCustomerNo}}</td>
      <td *ngIf="j>0" style="color:white;border-top:hidden">{{el.originalCustomerNo}}</td>
      <td *ngIf="j==0"> &#8203;{{el.trackingNo}}</td>
      <td *ngIf="j>0" style="color:white;border-top:hidden"> &#8203;{{el.trackingNo}}</td>
      <td><div align="center">{{item.statusSign}}</div></td>
      <td>{{item.sku}}</td>
      <td>{{item.receiptQty}}</td>
      <td>{{item.unheldQty}}</td>
      <td>{{item.grade}}</td>
      <td>{{item.note}}</td>
      <!--<td>
    <input style="margin-left:5px;border:thin;width:30%;" type="number" [(ngModel)]="item.unheldQty" placeholder="Qty" />
    <span *ngIf="el.isHold" class="red-text">
      <button mdbBtn type="button" size="sm" color="mdb-color" mdbWavesEffect (click)="unholdRR2(el.receivedNo, item.sku, item.unheldQty )">RESTOCK</button>
    </span>

  </td>-->
      <td *ngIf="j==0">{{el.createdBy}}</td>
      <td *ngIf="j>0" style="color:white;border-top:hidden">{{el.createdBy}}></td>
      <td *ngIf="j==0">{{el.createdAt | date:'MM/dd/yyyy HH:mm'}}</td>
      <td *ngIf="j>0" style="color:white;border-top:hidden">{{el.createdAt | date:'MM/dd/yyyy HH:mm'}}</td>
      
      <td *ngIf="j==0">
        <div *ngFor="let memo of el.memoes;">
          {{memo.content}}
        </div>
      </td>
      <td *ngIf="j>0" style="color:white;border-top:hidden"></td>
      <td *ngIf="j==0">
        <ul *ngFor="let log of el.logs;">
          <li>
            {{log.content}} At {{log.createdAt| date:'MM/dd/yyyy HH:mm'}} By {{log.from}}

          </li>
        </ul>
      </td>
      <td *ngIf="j>0" style="color:white;border-top:hidden"></td>
      <td *ngIf="j==0">
        <button mdbBtn type="button" size="sm" color="amber" mdbWavesEffect (click)="gotoReorder(el.receivedNo, el.originalNo)">ReOrder</button>
      </td>
      <td *ngIf="j>0" style="color:white;border-top:hidden"></td>
    </tr>
  </tbody>
 
</table>


