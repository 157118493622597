import { Component, HostListener, ViewChild, OnInit, Input, ElementRef, EventEmitter } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray, AbstractControl, ValidationErrors } from '@angular/forms';
import { Shipping, Package, ItemQty, Box, Address, SimpleLabel } from '../Class';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { delay, switchMap, map, tap } from 'rxjs/operators';
import { Time } from '@angular/common';
import { Router } from '@angular/router';
import { ItemService } from '../_services/item.service';
import { ShippingService } from '../_services/shipping.service';
import { AuthenticationService } from '../_services';
import { User } from '../_models';

@Component({
  templateUrl: './order-creation.component.html',
  styleUrls: ['./order-creation.component.scss']
})

export class OrderCreationComponent {

  customers: string[] = AuthenticationService.customers;
  locations: string[] = ["AWISEC", "AWICAS"];
  shippingOptions: string[] = ["Standard", "Ground", "Priority", "Express"]
  internationalShippingOptions: object[] = [
    { serviceName: "Priority Mail International", serviceCode: "PriorityMailInternational" },
    { serviceName: "Priority Mail Express International", serviceCode: "PriorityMailExpressInternational" },
    { serviceName: "FirstClass Mail International", serviceCode: "FirstClassMailInternational" },
    { serviceName: "FirstClass Package International Service", serviceCode: "FirstClassPackageInternationalService" }
  ]

  categoriesOfItems: string[] = [
    "Commercial Sample",
    "Gift",
    "Dangerous Goods",
    "Humanitarian Donations",
    "Documents",
    "Merchandise",
    "Returned Goods",
    "Other"
  ]

  customsForm: CustomsForm = new CustomsForm();

  shippingOption: string;
  countryCodes = AuthenticationService.countryCode;
  statelist = AuthenticationService.stateList;
  address: Address = new Address;
  itemlist: ItemList[];
  MaxOrderNo: number;
  //index: number;
  googleSheetLineNumber: number;
  googleSheetLastUpdated: string;
  shippingForm;
  estimWeight: string;
  isLoading: boolean = false;
  user: User;

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,
    private authenticationService: AuthenticationService,
    private itemService: ItemService, private shippingService: ShippingService) {
    this.authenticationService.getCustomer().then(x => this.customers = x);
    this.user = this.authenticationService.userValue;
    this.shippingForm = fb.group({
      customer: [this.user.customer],
      customerno: '',
      orderno: Number,
      neworderno: 0,
      parentorderno: Number,
      location: '',

      items: this.fb.array([
        this.additem()
      ]),

      shipTo: [new Address("US")],
      shippingOption: '',
      labels: this.fb.array([
        this.addlabel()
      ]),

      labelRequestInfo: new CustomsForm,

      isChargeToCustomer: []
    });

    this.shippingService.getGoogleSheetOrderLastNumber().subscribe(data => {

      this.googleSheetLineNumber = data.data.line_number;
      this.googleSheetLastUpdated = this.getFormattedDate(new Date(data.data.last_updated));
      
    });
    this.loadinfo(this.shippingForm.value);
  }

  initShippingForm() {
    this.shippingForm = this.fb.group({
      customer: this.shippingForm.get('customer').value,
      customerno: '',
      orderno: Number,
      neworderno: 0,
      parentorderno: Number,
      location: '',

      items: this.fb.array([
        this.additem()
      ]),

      shipTo: [new Address("US")],
      shippingOption: '',
      labels: this.fb.array([
        this.addlabel()
      ]),

      labelRequestInfo: new CustomsForm,

      isChargeToCustomer: []
    });
  }

  isManual: boolean = false;

  getFormattedDate(date): string {
    if (!date) date = this.googleSheetLastUpdated;
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    var hour = date.getHours().toString();

    hour = hour.length > 1 ? hour : '0' + hour;

    var min = date.getMinutes().toString();
    min = min.length > 1 ? min : '0' + min;

    var sec = date.getSeconds().toString();
    sec = sec.length > 1 ? sec : '0' + sec;

    return `${month}/${day}/${year} ${hour}:${min}:${sec}`;
  }

  loadinfo(formdata): void {
    // this.clearFormArray(this.shippingForm.get('items'));
    this.isManual = true;
    this.isLoading = true;
    this.itemService.getItemList(this.shippingForm.get('customer').value).subscribe(data => {
      this.isLoading = false;
      this.initShippingForm();
      this.itemlist = data;
      console.log(this.itemlist);
    });
  }

  isReturnToSender: boolean = false;
  nonDeliveryOption() {
    this.isReturnToSender = !this.isReturnToSender;
    if (this.isReturnToSender) {
      this.customsForm.nonDeliveryOption = "Return";
    } else {
      this.customsForm.nonDeliveryOption = "";
    }
    console.log(this.customsForm.nonDeliveryOption);
  }

  addCustomItemInfo() {
    this.customsForm.customsItems.push(new CustomsItem);
  }

  deleteCustomItemInfo(index: number) {
    this.customsForm.customsItems.splice(index, 1);
  }

  

  ordernumberchange(): void {
    this.clearFormArray(this.shippingForm.get('items'));
    console.log(this.items);
  }

  clearFormArray = (formarr: FormArray) => {
    while (formarr.length !== 0) {
      formarr.removeAt(0)
    }
    this.add();
  }

  add(): void {
    (<FormArray>this.shippingForm.get('items')).push(this.additem());
  }

  delete(index): void {
    (<FormArray>this.shippingForm.get('items')).removeAt(index);
    console.log(this.shippingForm.get('items').value);
  }

  additem(): FormGroup {
    return this.fb.group({
      sku: ['', Validators.required],
      desc: [''],
      qty: [Number],
      fulfillableQty: [Number]
    })
  }

  addlabel(): FormGroup {
    return this.fb.group({
      IsSignature: [Boolean],
      IsCertified: [Boolean],
      Carrier: String,
      ShippingOption: String
    })
  }

  reset() {
    (<FormArray>this.shippingForm.get('items')).reset();
  }

  

  submit(): void {

    //shippingOption update
    this.shippingForm.get('shippingOption').setValue(this.shippingOption);
    if (this.shippingOption == "Standard") {
      if (Number(this.estimWeight) <= 1 && Number(this.estimWeight) > 0) {
        this.labels[0].Carrier = "Endicia";
        this.labels[0].ShippingOption = "First";
      } else if (Number(this.estimWeight) > 1) {
        this.labels[0].Carrier = "FedEx";
        this.labels[0].ShippingOption = "SMART_POST";
      }
    } else if (this.shippingOption == "Ground") {
      this.labels[0].Carrier = "FedEx";
      this.labels[0].ShippingOption = "FEDEX_GROUND";
    } else if (this.shippingOption == "Priority") {
      this.labels[0].Carrier = "Endicia";
      this.labels[0].ShippingOption = "Priority";
    } else if (this.shippingOption == "Express") {
      this.labels[0].Carrier = "FedEx";
      this.labels[0].ShippingOption = "FEDEX_2_DAY";
    } else if (this.shippingOption == "PriorityMailInternational") {
      this.labels[0].Carrier = "Endicia";
      this.labels[0].ShippingOption = "PriorityMailInternational";
    } else if (this.shippingOption == "PriorityMailExpressInternational") {
      this.labels[0].Carrier = "Endicia";
      this.labels[0].ShippingOption = "PriorityMailExpressInternational";
    } else if (this.shippingOption == "FirstClassMailInternational") {
      this.labels[0].Carrier = "Endicia";
      this.labels[0].ShippingOption = "FirstClassMailInternational";
    } else if (this.shippingOption == "FirstClassPackageInternationalService") {
      this.labels[0].Carrier = "Endicia";
      this.labels[0].ShippingOption = "FirstClassPackageInternationalService";
    } else {
      alert('please select shippingoption.');
      return;
    }
    
    if (this.shippingForm.get('location').value == "") {
      alert('Please select a shipping location');
      return;
    }

    if (this.shipTo.country != "US") {
      this.shippingForm.get('labelRequestInfo').setValue(this.customsForm);
    }

    for (let i = 0; i < this.items.length; i++) {
      this.items[i].fulfillableQty = this.items[i].qty;
    }

    this.shippingForm.get('items').setValue(this.items);
    console.log(this.shippingForm.value);
    this.isLoading = true;
    this.shippingService.orderCreation(this.shippingForm.value)
      .subscribe(data => {
        this.isLoading = false;
        if (data['isSuccess'] == true) {
          alert('successrully order added at #' + data['orderNo']);
        } else {
          alert('failed to create an order because' + data['errorMsg']);
        }
        location.reload();
      });
  }

  getWeight() {
    var result = 0;
    for (let i = 0; i < this.items.length; i++) {
      let n = this.itemlist.findIndex(j => j.sku == this.items[i].sku);
      if (n > -1) {
        result += this.items[i].qty * this.itemlist[n].weight;
      }
    }
    this.estimWeight = (result / 453.592 + 0.2).toFixed(2);
  }

 

  get shipTo(): Address {
    return this.shippingForm.get('shipTo').value as Address;
  }

  get labels(): Array<SimpleLabel> {
    return this.shippingForm.get('labels').value as Array<SimpleLabel>;
  }

  get items(): Array<Item> {
    return this.shippingForm.get('items').value as Array<Item>;
  }


  selectSku(itemSku, qty, index) {
    console.log(itemSku, qty, index);
    var selectedItem = this.itemlist.find(i => i.sku == itemSku);
    this.items[index].desc = selectedItem != undefined ? selectedItem.desc : '';
    this.items[index].qty = qty;
    console.log(this.items);
  }

  selectDesc(itemDesc, qty, index) {
    var selectedItem = this.itemlist.find(i => i.sku == itemDesc);
    this.items[index].sku = selectedItem != undefined ? selectedItem.sku : '';
    this.items[index].qty = qty;
    console.log(this.items);
  }

  importGoogleOrder(): void {
    if (confirm(`Are you really sure to import orders from google sheet \r\nline number ${this.googleSheetLineNumber} for Beauteers?\r\nIf yes, please double check the line number which you want to import from.`)) {
      this.isLoading = true;
      this.shippingService.importGoogleOrder(this.googleSheetLineNumber).subscribe(data => {

        this.isLoading = false;
        if (data.isSuccess) {
          this.shippingService.getGoogleSheetOrderLastNumber().subscribe(lastOrderData => {
            this.googleSheetLineNumber = lastOrderData.data.line_number;
            this.googleSheetLastUpdated = this.getFormattedDate(new Date(lastOrderData.data.last_updated));
          });
          alert(`Successfully imported orders.`);
        }
        else {
          alert(`Failed to import from google sheet. \r\nWe sent to dev team to address this issue ASAP.\r\nPlease let them know about this issue as well.\r\nThank you for your patience.`);
        }
      });
    }
  }

}

class Item {
  sku: string;
  desc: string;
  qty: number;
  fulfillableQty: number;
}

class ItemList {
  id: string;
  sku: string;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
}

class Label {
  CustomerId: string;
  Type: string;
  carrier: string;
  ShippingOption: string;
  isSignature: boolean;
  isCertified: boolean;
  CreatedAt: Time;
  TransactionID: string;
  trackingNo: string;
  Price: number;
  AdjustPrice: number;
  RefundPrice: number;
  Image: string;
  EstimateAt: Time;
  ShipOutAt: Time;
  //DeliveryAt: Time;
}

class CustomsForm {
  categoryOfItems: string;
  customsItems: CustomsItem[];
  nonDeliveryOption: string;
  senderSignature: Signature;

  constructor() {
    this.customsItems = [new CustomsItem];
    this.senderSignature = new Signature();
  }
}

class Signature {
  name: string;
  signedAt: Date;
}

class CustomsItem {
  description: string;
  value: number;
  quantity: number;
  weight: number;
  countryOfOrigin: string;
}



