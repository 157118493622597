import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService, InventoryService } from '../_services';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';




@Component({
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})

export class InventoryComponent {
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'inventory.xlsx');
  }

  exportToPDF() {
    let DATA1 = this.TABLE.nativeElement;
    let doc = new jsPDF("p", "mm", "a4");
    var thetime = new Date();
    var datetime = `Date printed: ${thetime}`;
    html2canvas(DATA1).then(canvas => {
      var image1 = canvas.toDataURL('image/png', 0.1);
      var imgProps = doc.getImageProperties(image1);
      var width = doc.internal.pageSize.getWidth();
      var height = (imgProps.height * width) / imgProps.width;
      doc.setFontSize(8);
      doc.text(datetime, 5, doc.internal.pageSize.height - 3);
      doc.addImage(image1, 'png', 5, 10, width - 10, height);
      doc.save('inventory.pdf');
    });
  }

  customers: string[] = [];
  mainForm;
  isLoading: boolean = false;
  inventoryInSEC;
  inventoryInCAS;
  boxInventory;

  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder,
    private authenticationService: AuthenticationService, private location: Location,
    private inventoryService: InventoryService,) {
    this.authenticationService.getCustomer().then(x => this.customers = x);
    this.activatedRoute.paramMap.subscribe(params => {
      this.mainForm = fb.group({
        customer: params.get('customer') ? params.get('customer') : 'Krave',
      });
      this.onSubmit();
      this.getBoxInventory();
    });
  }

  onSubmit() {
    this.isLoading = true;
    this.location.replaceState(`/inventory/${this.mainForm.value.customer}`);
    this.inventoryInSEC = null;
    this.inventoryInCAS = null;
    this.inventoryService.getInventoryByCustomer(this.mainForm.value).subscribe(res => {
      this.isLoading = false;
      if (res["inventoryInSEC"].length > 0) {
        this.inventoryInSEC = res["inventoryInSEC"];
      }
      if (res["inventoryInCAS"].length > 0) {
        this.inventoryInCAS = res["inventoryInCAS"];
      }
      console.log(res);
    });
  }

  getBoxInventory() {
    this.inventoryService.getBoxInventory(this.mainForm.value).subscribe(data => {
      this.boxInventory = data as any[];
    });
  }
  

}



