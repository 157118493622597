import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ShippingService } from '../../_services/shipping.service';

@Component({
  selector: 'app-hold-list',
  templateUrl: './hold-list.component.html',
  styleUrls: ['./hold-list.component.scss']
})

export class HoldListComponent {
  elements: any = [];
  checkedlist: any = [];
  total: number;
  isLoading: boolean = false;
  masterSelected: boolean = false;
  mastercheck: boolean = false;
  mainForm;

  locationOptionsSelect: object[] = [
    { value: 'All', label: 'All' },
    { value: 'AWICAS', label: 'AWICAS' },
    { value: 'AWISEC', label: 'AWISEC' },
    { value: 'NotAssigned', label: 'NotAssigned' },
  ];

  constructor(
    //public datepipe: DatePipe,
    private fb: FormBuilder, private http: HttpClient, private router: Router,
    private shippingService: ShippingService) { }

  ngOnInit() {
    this.mainForm = this.fb.group({
      location: 'All',
      page: 1
    });

    this.onSubmit(this.mainForm);
  }

  onSubmit(customerData) {
    //console.warn(customerData);

    if (this.mainForm.valid) {
      this.isLoading = true;
      this.shippingService.getHoldList(this.mainForm.value).subscribe(data => {
        this.isLoading = false;
        console.log(data);
        this.elements = data;
        //this.total = data["total"];
        //alert('done search');
      });

    }
    else {
      alert(this.mainForm.status);
    }

    //this.checkoutForm.reset();
  }

  createCheckedList(value: any, customer: string, id: string) {
    if (value.currentTarget.checked == true) {
      this.checkedlist.push({Customer: customer, Id: id});
    }
    if (value.currentTarget.checked == false) {
      const index = this.checkedlist.map(x => x.Id).indexOf(id);
      if (index !== -1) {
        this.checkedlist.splice(index, 1);
      }
    }
    console.log(this.checkedlist);
  }

  masterCheck() {
    this.mastercheck = !this.mastercheck;
    this.checkedlist = [];
    if (this.mastercheck == true) {
      for (let i = 0; i < this.elements.length; i++) {
        this.elements[i].checked = this.mastercheck;
      }
      this.checkedlist.push(this.elements.map(a => ({ Customer: a.customer, Id: a.id })));
    }

    else if (this.mastercheck == false) {
      for (let i = 0; i < this.elements.length; i++) {
        this.elements[i].checked = this.mastercheck;
      }
    }
    console.log(this.checkedlist);
  }

  //api/shipping/unhold
  //unHold(customer, orderno) {
  //  var Rmodel = new modelone();
  //  Rmodel.customer = customer;
  //  Rmodel.orderno = orderno;

  //  this.shippingService.unhold(Rmodel).subscribe(data => {
  //    alert('unhold success');
  //    this.onSubmit(null);
  //  });
  //}

  unHoldMultipleOrders() {
    if (confirm(`Do you want to mark as unhold for ${this.checkedlist.length} selected order${this.checkedlist.length > 1 ? 's' : ''}?`)) {
      this.isLoading = true;
      this.shippingService.unholdMultipleOrders(this.checkedlist).subscribe(response => {
        this.isLoading = false;
        if (response['isSuccess'] == true) {
          alert(response['errorMsg']);
          this.onSubmit(this.mainForm);
        } else {
          alert(response['errorMsg']);
        }
      });
      this.checkedlist = [];
    }
  }
}

class modelone {
  customer: string
  orderno: number
}
