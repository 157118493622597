import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpUrlEncodingCodec } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { first, debounceTime, debounce } from 'rxjs/operators';
import { User, Role } from '@app/_models';
import { Time } from '@angular/common';
import { TaskService } from '../_services/task.service';
import { PackingService } from '../_services/packing.service';
import { BoxService } from '../_services/box.service';
import { ItemService } from '../_services/item.service';
import { ShippingService } from '../_services/shipping.service';
import { Shipping } from '../Class';
import { AuthenticationService, UserService } from '@app/_services';
import { environment } from '@environments/environment';
import * as printJS from 'print-js';
declare function print_zpl(zpl: string): void;
declare function print_zpl_usb(zpl: string): void;
declare function printLabelsFromFileUrl(url: string, successCallback: () => void, errorCallback: () => void): void;

@Component({
  templateUrl: './packing.component.html',
  styleUrls: ['./packing.component.scss']
})

export class PackingComponent {
  user: User;
  elements: any = [];
  total: number;
  isPrinted: boolean = false;
  filters: Filter[] = [];
  checkedlist: string[] = [];

  constructor(private http: HttpClient, private router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private taskService: TaskService, private packingService: PackingService,
    private boxService: BoxService, private itemService: ItemService,
    private userService: UserService,
    private shippingService: ShippingService, private authenticationService: AuthenticationService) {
    this.authenticationService.user.subscribe(x => this.user = x);
  }

  sub;
  cus;
  no;
  isLoading: boolean = false;
  optionsSelect: object[] = [];
  selectedValue: object;
  shipping: any;
  data: { shipping: any, setting: any };
  barcodeValue: string;
  itemList: object[] = [];
  packageWeight: any;
  isLockLabel: boolean;
  timeoutObj: any = null;
  loadingMessage: string = null;
  @ViewChild('input2') inputEl2: ElementRef;

  ngOnInit() {
    console.log(this.user.location);
    this.sub = this._ActivatedRoute.paramMap.subscribe(params => {
      this.cus = params.get('cus');
      this.no = params.get('no');
      this.packingService.getShippingWithSetting(this.cus, this.no).subscribe(response => {
        if (response["isSuccess"]) {
          this.data = response["data"];
          this.shipping = this.data.shipping;
          console.log(this.shipping);
          if (this.user.location != "All") {
            if (this.shipping.location != this.user.location) {
              window.location.href = `/task/${this.cus}/${this.shipping.packingSlipPrint}`; 
              alert(`${this.user.firstName} has no access to orders assigned to the location '${this.shipping.location}' `);
            }
          }
          
          if (this.shipping
            && this.shipping.packingSlip
            && this.shipping.packingSlip.items) {
            this.shipping.packingSlip.items.filter(x => !x["upc"]).map(x => { x["upc"] = x["sku"]; });
            this.shipping.packingSlip.items.filter(x => !x["sku"]).map(x => x["sku"] = x["desc"]);
          }
          
          //console.log(this.shipping.packages.length,'before');

          //if (this.shipping2.labels != null) {
          //  if (this.shipping2.labels[0].trackingNo != null) {
          //    setTimeout(this.goingBack, 4000)
          //  }
          //}

          if (this.shipping.packages == null || this.shipping.packages.length == 0) {
            this.newPackage();
          }

          //  this.checkQty();
          console.log(this.shipping);

          if (this.cus == "Krave") {
            this.shipping.packages[0].box.name = "#K Box";
            this.shipping.packages[0].box.height = 3;
            this.shipping.packages[0].box.weight = 0.19;
            this.shipping.packages[0].box.width = 7.5;
            this.shipping.packages[0].box.length = 7.5;
          }
          if (this.cus == "SuperEgg") {
            this.shipping.packages[0].box.name = "S Box";
            this.shipping.packages[0].box.height = 8.25;
            this.shipping.packages[0].box.weight = 0.45;
            this.shipping.packages[0].box.width = 10.25;
            this.shipping.packages[0].box.length = 2;
          }

          //console.log('onSearchChange nativeElement');
          //setTimeout(() => (document.querySelectorAll("#input2")[0] as any).focus(), 1000);

          if (typeof this.shipping.packingEndAt == "undefined" || this.shipping.packingEndAt == null)
            setTimeout(() => this.inputEl2.nativeElement.focus());
        }
      });
    });

    this.boxService.getBoxList(this.cus).subscribe(data => {
      this.optionsSelect = data.map(a => ({
        value: a, label: a["name"], orderBy: a["orderBy"]
      })).sort((x, y) => (x.orderBy > y.orderBy) ? 1 : -1);
      console.log(this.optionsSelect);
    });

    this.itemService.getItemList(this.cus).subscribe(data => {
      this.itemList = data;
      console.log(this.itemList);
      this.itemList.filter(x => !x["upc"]).map(x => { x["upc"] = x["sku"]; });
      this.itemList.filter(x => !x["sku"]).map(x => x["sku"] = x["desc"]);
    });


  }

  checkQty() {
    var SumOfItem = this.shipping.items.reduce((sum, current) => sum + current.fulfillableQty, 0);
    var SumOfPackingSlip = this.shipping.packingSlip.items.reduce((sum, current) => sum + current.qty, 0);

    console.log(SumOfItem, SumOfPackingSlip);
    if (SumOfItem > 0 && SumOfItem != SumOfPackingSlip) {
      for (let i = 0; i < this.shipping.items.length; i++) {
        let index = this.shipping.packingSlip.items.findIndex(a => a.sku == this.shipping.items[i].sku);
        //  console.log(this.shipping.items[i].sku,index);
        this.shipping.packingSlip.items[index].qty = this.shipping.items[i].fulfillableQty;
      }
    }

  }

  closeOrder() {
    if (!this.isPrinted) {
      alert(`You didn't print out the shipment label.\r\nPlease try again after printing the label for this order.`);
      return;
    }
    if (this.shipping.customer == 'Miro' || this.shipping.customer == 'Hansol') {
      this.shippingService.closeOrder(this.shipping).subscribe(response => {
        if (response["isSuccess"] == true) {
          alert(response["errorMsg"]);
        } else {
          alert(response["errorMsg"]);
        }
      });
    }
  }


  deletePackItem(sku) {
    console.log('deletePackItem: ' + sku);
    console.log(this.shipping.packages.length);
    for (var i = 0; i < this.shipping.packages.length; i++) {
      //console.log(this.shipping.packages[i].length);
      for (var j = 0; j < this.shipping.packages[i].packageItems.length; j++) {
        //console.log(this.shipping.packages[i].packageItems[j].sku);
        if (this.shipping.packages[i].packageItems[j].sku == sku) {
          if (this.shipping.packages[i].packageItems[j].qty > 1) {
            this.shipping.packages[i].packageItems[j].qty -= 1;
          }
          else {
            this.shipping.packages[i].packageItems.splice(j, 1);
          }
          this.getPackageWeight();
          return;
        }
      }
    }
    this.getPackageWeight();
  }
  markAsAllPacked(item: any, remainingQty: number) {
    const needsToFulfilled = remainingQty;
    this.onSkuChange(item.sku, needsToFulfilled);
  }
  generateLabel() {
    console.log(this.shipping);

    if (this.isLoading) {
      alert('Please wait.\r\n Still generating the label...');
      return;
    }
    this.loadingMessage = "Generating Label...";
    this.isLoading = true;
    this.packingService.generateLabel(this.cus, this.no).subscribe(response => {
      //this.packingService.generateLabel2(this.cus, JSON.stringify(this.shipping)).subscribe(response => {
      if (response["isSuccess"] == true) {
        //alert('label created');
        //console.log(response);
        this.shippingService.addLog(this.cus, this.no, "Shipping Label was printed").subscribe(x => { });

        console.log(response["data"]);
        console.log(response["labelImage"]);
        console.log(response["rmaImage"]);

        this.shipping.labels = response["data"];
        //if (response["rmaImage"] != null) {
        //  print_zpl(response["rmaImage"]);
        //}
        print_zpl_usb(response["labelImage"]); //'print_zpl_usb' is slower than 'print_zpl', so a shipping label is printed using 'print_zpl_usb'
        setTimeout(this.goingBack, 6000)
      }
      else {
        const errorMessage = response["errorMsg"];
        if (this.data.setting.customer_error_check_process == true
          && response["hasErrorCheckProcess"] == true) {
          if (confirm(`We found the address error message like below: \n${errorMessage}\n\nThis order marked as On-hold automatically and we sent to email to ${this.cus}\n\nDo you want to go back to task list?`)) {
            if (this.shipping.packingSlipPrint) {
              this.router.navigate(['/task', this.cus, this.shipping.packingSlipPrint]);
            }
            else {
              this.router.navigate(['/individualshipping', this.cus, this.shipping.orderNo]);
            }
          }
        }
        else {
          alert(response["errorMsg"]);
        }

      }
      this.isLockLabel = false;
      this.isLoading = false;
    }, error => {
      console.log(error);
      if (error != null) {
        alert('Failed to generate a label (' + error + ')');
      }
      else {
        alert('Failed to generate a label.');
      }
      this.isLockLabel = false;
      this.isLoading = false;
    });
    this.isLockLabel = true;
  }


  getBoxUpdated(event: any, i: number) {
    console.log(event);
    this.shipping.packages[i].box.name = event.name;
    this.shipping.packages[i].box.height = event.height;
    this.shipping.packages[i].box.weight = event.weight;
    this.shipping.packages[i].box.width = event.width;
    this.shipping.packages[i].box.length = event.length;
    this.getPackageWeight();
    this.shipping.weight = this.packageWeight;
    //console.log(this.shipping);
    //console.log(JSON.stringify(this.shipping));

    this.packingService.getBoxUpdated(this.cus, JSON.stringify(this.shipping)).subscribe(response => {
      alert('Updated successfully.');
    });
  }


  getSelectedBox(event: any, i: number) {
    console.log('getSelectedBox: ' + i);

    this.shipping.packages[i].box.name = event.name;
    this.shipping.packages[i].box.height = event.height;
    this.shipping.packages[i].box.weight = event.weight;
    this.shipping.packages[i].box.width = event.width;
    this.shipping.packages[i].box.length = event.length;

    console.log('getSelectedBox event.weight');
    console.log(event.weight);
    this.getPackageWeight();
  }

  getPackageWeight() {
    console.log('getPackageWeight start');
    console.log(this.shipping.packages);
    var result = 0;
    for (var i = 0; i < this.shipping.packages.length; i++) {
      for (var j = 0; j < this.shipping.packages[i].packageItems.length; j++) {
        result += this.shipping.packages[i].packageItems[j].qty * (this.shipping.packages[i].packageItems[j].weight ? this.shipping.packages[i].packageItems[j].weight : 0)
      }
      result += (this.shipping.packages[i].box.weight ? this.shipping.packages[i].box.weight : 0) * 453.592;
    }
    this.packageWeight = (result / 453.592).toFixed(2);
    console.log(this.packageWeight);
    console.log('getPackageWeight end');
  }

  getPackCount(sku): number {
    if (this.shipping == null)
      return 0;

    if (this.shipping.packages == null)
      return 0;
    //  console.log(this.shipping.packages.length, 'in paccount');
    if (this.shipping.packages.length == 0) {
      return 0;
    }

    var result = this.shipping.packages.reduce((g: number, package1: any) => {
      if (package1.packageItems.some(a => a.sku == sku)) {
        g += package1.packageItems.find(z => z.sku == sku).qty;
      }
      return g;
    }, 0);

    //console.log(result);
    return result;
  }

  goingBack() {
    window.history.go(-1);
  }

  newPackage() {
    if (this.shipping.packages == null)
      this.shipping.packages = [];
    else {
      this.shipping.packages = [];
    }

    this.shipping.packages.push({
      packageItems: [],
      box: {
        boxNo: this.shipping.packages.length + 1,
        width: 0,
        height: 0,
        length: 0,
        weight: 0
      }
    });
  }

  onCompletedPacking() {
    this.isLoading = true;
    this.loadingMessage = "Completing a Packing Process...";
    this.getPackageWeight();
    this.shipping.weight = this.packageWeight;

    this.packingService.completePacking(this.cus, JSON.stringify(this.shipping))
      .subscribe(response => {
        this.isLoading = false;
        if (response["isSuccess"] == true) {

          if (this.cus == "Miro") {
            this.isLoading = true;
            this.packingService.getLabelUrl(this.cus, this.shipping.customerNo)
              .subscribe(response => {
                this.isLoading = false;
                if (response["isSuccess"] == true) {
                  console.log(response["labelString"][0].image);
                  this.shipping.labels[0].image = response["labelString"][0].image;
                  console.log('success in getting document.');
                } else {
                  alert(`Failed to get a label image. See details below: \r\n${response["errorMsg"]}`);
                  console.log('Failed in getting doc');
                }
              });

          }
          else {
            
            alert('Completed a packing successfully.');
          }
          this.shipping.packingEndAt = new Date(); 
        }
      });
  }

  onInputBlur(event) {
    console.log(event);
    this.timeoutObj = setTimeout(function () { event.target.focus() }, 1500);
  }

  packingAll() {
    var i = this.shipping.packages.length - 1;
    for (let j = 0; j < this.shipping.packingSlip.items.length; j++) {
      var dbItem = this.itemList.find(a => a["sku"].trim() as string == this.shipping.packingSlip.items[j].sku);
      this.shipping.packages[i].packageItems.push({
        qty: this.shipping.packingSlip.items[j].qty,
        name: dbItem["desc"],
        sku: dbItem["sku"],
        upc: dbItem["ups"],
        weightUnit: dbItem["weightUnit"],
        weight: dbItem["weight"]
      });
    }

    this.getPackageWeight();
    console.log(this.shipping.packages);
    console.log(this.packageWeight);
  }

  printImageLabel() {
    //print_zpl_usb(this.shipping.labels[0].image);
    console.log('this.shipping.labels[0].image', this.shipping.labels[0].image);
    console.log('isPrinted', this.isPrinted);
    if (this.shipping.labels[0].image) {
      var url = 'https://' + this.shipping.labels[0].image;
      this.isPrinted = true;
      //var printWindow = window.open(url);

      //printWindow.window.print();
      //if (url && (window as any).BrowserPrint) {
      //}
      //this.packingService.getLable(url).subscribe(res => {
      //});
      printJS(`${environment.apiUrl}/packing/getLabel?url=${(window as any).encodeURIComponent(url)}`);
      //printLabelsFromFileUrl(`${environment.apiUrl}/packing/getLabel?url=${(window as any).encodeURIComponent(url)}`, () => {
      //  console.log('success');
      //}, () => {

      //});
    }
  }

  onSkuChange(barcode: string, qty: number) {
    console.log(this.shipping.packingSlip.items);
    var needToPut = this.shipping.packingSlip.items.find(a => a["sku"].toLowerCase().trim() == barcode.toLowerCase().trim() || a["upc"].toLowerCase().trim() == barcode.toLowerCase().trim());
    if (needToPut != undefined) {
      // **** Suppose the number of package is one. ****//
      var dbItem = this.itemList.find(a => a["sku"] == needToPut.sku);
      if (this.shipping.packages != null && this.shipping.packages.length > 0) {
        if (this.shipping.packages[0].packageItems != null && this.shipping.packages[0].packageItems.length > 0) {
          //if there are more than one package items.
          if (this.getPackCount(needToPut.sku) >= needToPut.fulfillableQty) {
            alert(`[${dbItem["sku"]}] ${dbItem["desc"]} already packed all.`);
          } else {
            console.log(dbItem["weight"]);
            console.log(~~dbItem["weight"]);
            if (!dbItem["weight"] || +dbItem["weight"] == 0) {
              const message = `Cannot process for this item ([${dbItem["sku"]}] ${dbItem["desc"]}) because weight is not defined yet.\r\n\r\nPlease skip this order to pack.\r\nAlso a notification will be sent to person in charge.`;
              this.userService.sendNotification(this.cus, `[${this.cus}] Found not defined weight item.`, message);
              alert(message);
              return;
            }
            // check if the item exist in the package items
            var index = this.shipping.packages[0].packageItems.findIndex(x => x.sku == needToPut.sku);
            if (index == -1) {
              // if not
              this.shipping.packages[0].packageItems.push({
                qty: 1,
                name: dbItem["desc"],
                upc: dbItem["upc"] ? dbItem["upc"].trim() : null,
                sku: dbItem["sku"],
                weightUnit: dbItem["weightUnit"],
                weight: dbItem["weight"]
              });
            } else {
              // if exist
              this.shipping.packages[0].packageItems[index].qty += 1;
            }
          }
        } else {
          console.log(dbItem["weight"]);
          console.log(~~dbItem["weight"]);
          if (!dbItem["weight"] || +dbItem["weight"] == 0) {
            const message = `Cannot process for this item ([${dbItem["sku"]}] ${dbItem["desc"]}) because weight is not defined yet.\r\n\r\nPlease skip this order to pack.\r\nAlso a notification will be sent to person in charge.`;
            this.userService.sendNotification(this.cus, `[${this.cus}] Found not defined weight item.`, message).subscribe(x => { });
            alert(message);
            return;
          }
          // if there is no package Items.
          this.shipping.packages[0].packageItems.push({
            qty: 1,
            name: dbItem["desc"],
            upc: dbItem["upc"] ? dbItem["upc"].trim() : null,
            sku: dbItem["sku"],
            weightUnit: dbItem["weightUnit"],
            weight: dbItem["weight"]
          });
        }
      }

      var packingslipsum = this.shipping.packingSlip.items.reduce((a, b) => a + b["fulfillableQty"], 0);
      var packagesum = this.shipping.packages[0].packageItems.reduce((a, b) => a + b["qty"], 0);
      console.log(packingslipsum, packagesum);
      if (packingslipsum == packagesum) {
        this.onCompletedPacking();
      }

      this.barcodeValue = "";
      this.getPackageWeight();

    }
    else {
      alert(`We couldn't find ${barcode} for this order.\r\nYou might have wrong item for this order.\r\nPlease review the order items again.`);
      this.barcodeValue = "";
    }
    






    //console.log(barcode);
    //console.log(qty);
    //console.log(this.itemList);
    //console.log(this.shipping);
    //barcode = barcode.trim();
    //var i = this.shipping.packages.length - 1;
    //var isExist: boolean = false;
    //var index = 0;
    
    //for (var i = 0; i < this.shipping.packages.length; i++) {
    //  if (this.shipping.packages[i].packageItems.length > 0) {
    //    var duplication = this.shipping.packages[i].packageItems(x => x.sku.toLowerCase().trim() == barcode.toLowerCase().trim() || x.upc.toLowerCase().trim() == barcode.toLowerCase().trim());
    //    if (duplication.length > 0) {
    //      isExist = true;
    //    }
    //  }
    //}




  //  for (var j = 0; j < this.shipping.packages[i].packageItems.length; j++) {
  //    if ((this.shipping.packages[i].packageItems[j].sku != null && this.shipping.packages[i].packageItems[j].sku.toLowerCase().trim() == barcode.toLowerCase())
  //      || (this.shipping.packages[i].packageItems[j].upc != null && this.shipping.packages[i].packageItems[j].upc.toLowerCase().trim() == barcode.toLowerCase())) {
  //      console.log('item exist');
  //      isExist = true;
  //      index = j;
  //      break;
  //    }


  //    if (isExist) {
  //      console.log('exists');
  //    if (this.shipping.packingSlip.items.some(a => a["sku"].toLowerCase().trim() == barcode.toLowerCase() && a["fulfillableQty"] - this.getPackCount(a["sku"]) > 0)) {
  //      this.shipping.packages[i].packageItems[j].qty += qty;
  //    }
  //    else if (this.shipping.packingSlip.items.some(a => a["upc"] != null && a["upc"].toLowerCase().trim() == barcode.toLowerCase() && a["fulfillableQty"] - this.getPackCount(a["sku"]) > 0)) {
  //      this.shipping.packages[i].packageItems[j].qty += qty;
  //    }
  //    else {
  //      alert('packed enough.');
  //    }
  //  }
  //  else {
  //      console.log('does not exists');
  //    if (this.shipping.packingSlip.items.some(a => a["sku"].toLowerCase().trim() == barcode.toLowerCase())) {
  //      //console.log(a["qty"] - this.getPackCount(barcode));
  //      var dbItem = this.itemList.find(a => a["sku"] != undefined && a["sku"].toLowerCase().trim() == barcode.toLowerCase());

  //      if (dbItem["weight"] == null || dbItem["weight"] == 0) {
  //        alert(`========================================================================r\nWarning: \r\nWe cannot proceed next step. \r\nBecause weight information is required.\r\n\r\nPlease add an item weight information for [${barcode}] ${dbItem["desc"]}\r\n========================================================================`);
  //        return;
  //      }

  //      this.shipping.packages[i].packageItems.push({
  //        qty: 1,
  //        name: dbItem["desc"],
  //        upc: dbItem["upc"] ? dbItem["upc"].trim() : null,
  //        sku: barcode,
  //        weightUnit: dbItem["weightUnit"],
  //        weight: dbItem["weight"]
  //      });
  //      console.log(this.shipping.packages[i]);
  //    }
  //    else if (this.shipping.packingSlip.items.some(a => a["upc"].toLowerCase().trim() == barcode.toLowerCase())) {
  //      var dbItem = this.itemList.find(a => a["upc"] != undefined && a["upc"].trim() == barcode);
  //      if (dbItem["weight"] == null || dbItem["weight"] == 0) {
  //        alert(`========================================================================r\nWarning: \r\nWe cannot proceed next step. \r\nBecause weight information is required.\r\n\r\nPlease add an item weight information for [${barcode}] ${dbItem["desc"]}\r\n========================================================================`);
  //        return;
  //      }
  //      if (dbItem != null && dbItem != undefined) {
  //        this.shipping.packages[i].packageItems.push({
  //          qty: 1,
  //          name: dbItem["desc"],
  //          sku: dbItem["sku"].trim(),
  //          upc: barcode,
  //          weightUnit: dbItem["weightUnit"],
  //          weight: dbItem["weight"]
  //        });
  //        console.log(this.shipping.packages[i]);
  //      } else {
  //        alert('not found item');
  //      }
  //    }
  //    else {
  //      alert('not found item');
  //    }
  //  }

  //  var packingslipsum = this.shipping.packingSlip.items.reduce((a, b) => a + b["fulfillableQty"], 0);
  //  var packagesum = this.shipping.packages[0].packageItems.reduce((a, b) => a + b["qty"], 0);
  //  console.log(packingslipsum, packagesum);
  //  if (packingslipsum == packagesum) {
  //    this.onCompletedPacking();
  //  }

  //  this.barcodeValue = "";
  //  this.getPackageWeight();
  //}
}

  reprintLabel() {
    if (this.shipping.labels[0].image) {
      this.shippingService.addLog(this.cus, this.no, "Shipping Label was re-printed").subscribe(x => { });
      print_zpl_usb(this.shipping.labels[0].image);
    }
    else {
      alert("Not found a shipping label.")
    }
    
  }

  undoCompletedPacking() {
    //console.log(this.shipping);
    //const formData = new FormData();
    //formData.append('shipping', this.shipping);
    //console.log(JSON.stringify(this.shipping));
    this.packingService.unsetPackingSlipEndAt(this.cus, this.no)
      .subscribe(response => {
        if (response["isSuccess"] == true) {
          console.log('unset completed');
          if (typeof this.shipping.packingEndAt === "undefined") {

          }
          else {
            console.log('delete this.shipping.packingEndAt');
            delete this.shipping.packingEndAt;
          }
          //setTimeout(() => this.inputEl2.nativeElement.focus()); // Error 발생하여 확인해야함
          console.log('unset completed2');
        }
      });

  }

}

class Filter {
  sku: string;
  qty: number;
}
