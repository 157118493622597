<h1>Fulfilled List</h1>
<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>
<form [formGroup]="mainForm" (ngSubmit)="onSubmit(mainForm.value)" class="float-left">
  <div class="row" style="padding-bottom:5px; padding-right:10px;">

    <div style="padding-right:15px;">
      <select formControlName="location" class="browser-default custom-select" style="width: 100px;font-size: small;">
        <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
    <div>
      <select formControlName="customer" class="browser-default custom-select" style="width:120px;font-size:small;">
        <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
    <div style="padding-left:20px;padding-right:10px;">
      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'From'" formControlName="startAt" required></mdb-date-picker>
    </div>
    <div>
      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'To'" formControlName="endAt" required></mdb-date-picker>
    </div>
    <div style="padding-left:15px;padding-right:15px;">
      <button mdbBtn class="btn btn-amber" size="sm" type="submit" mdbWavesEffect>Submit</button>
    </div>
    <div style="padding-top:10px;" [hidden]="!total">
      Total: {{total}}
    </div>
  </div>
</form>

<div align="right">
  <button mdbBtn type="button" color="light-blue" rounded="true" class="btn btn-success" size="sm" mdbWavesEffect><mdb-icon fas icon="filter"></mdb-icon>Filter</button>
  <button mdbBtn type="button" color="success" rounded="true" (click)="ExportTOExcel()" class="btn btn-success" size="sm" mdbWavesEffect>Excel</button>
</div>


<table class="table table-bordered table-sm" style="margin-top: 10px;" #TABLE #table>
  <thead>
    <tr align="center">
      <th scope="col">Customer #</th>
      <th scope="col">Order #</th>
      <th scope="col">Location</th>
      <th scope="col">Items</th>
      <th scope="col">Qty</th>
      <th scope="col">P-Qty</th>
      <th scope="col">Box</th>
      <th scope="col">ShippingOption</th>
      <th scope="col">TrackingNo</th>
      <th scope="col">Status</th>
      <th scope="col">En-Route <br /> Days</th>
      <th scope="col">State</th>
      <th scope="col">Packing<br />Slip</th>
      <th scope="col">PaidAt</th>
      <th scope="col">PackingStartAt</th>
      <th scope="col">PackingEndAt</th>
      <th scope="col">PackingEndBy</th>
      <th scope="col">ClosedAt</th>
      <th scope="col">Log</th>
      <th scope="col">Memo</th>
    </tr>
  </thead>
  <tbody *ngFor="let el of elements; let i = index">
    <tr mdbTableCol *ngFor="let item of el.items; let j = index">
      <td *ngIf="j==0">{{el.customerNo}}</td>
      <td *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.customerNo}}</td>
      <td *ngIf="j==0">
        <a [routerLink]="['/individualshipping',el.customer,el.orderNo]">{{el.orderNo}}</a>
      </td>
      <td *ngIf="j>0" style="color:transparent;border-top:hidden">
        {{el.orderNo}}
      </td>
      <td *ngIf="j==0">{{el.location}}</td>
      <td *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.location}}</td>
      <td style="width:10%;">
        {{item.sku}}
      </td>
      <td>
        {{item.qty}}
      </td>
      <td>
        <div *ngFor="let pakage of el.packages;">
          <div *ngFor="let pakageItem of pakage.packageItems;">
            <div *ngIf="item.sku==pakageItem.sku">{{pakageItem.qty}}</div>
            <!--{{pakageItem.sku}} ({{pakageItem.qty}}) ({{pakageItem.fulfillableQty}})-->
          </div>
        </div>
      </td>
      <td style="{{j > 0 ? 'color:transparent;border-top:hidden' : ''}}"><b>{{(j==0 && el.packages && el.packages.length > 0 && el.packages[0] && el.packages[0].box) ? el.packages[0].box.name : ''}}</b></td>
      <td *ngIf="j==0">
        <div *ngFor="let label of el.labels">
          {{label.carrier}} - {{label.shippingOption}}
        </div>
      </td>
      <td *ngIf="j>0" style="color:transparent;border-top:hidden">
        <div *ngFor="let label of el.labels">
          {{label.carrier}} - {{label.shippingOption}}
        </div>
      </td>
      <td *ngIf="j==0">
        <div *ngFor="let label of el.labels;">
          <a *ngIf="label.carrier=='Endicia'" target="_blank" href="https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels={{label.trackingNo}}%2C">&#8203;{{label.trackingNo}}</a>
          <a *ngIf="label.carrier=='FedEx'" target="_blank" href="https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber={{label.trackingNo}}&cntry_code=us&locale=en_US">&#8203;{{label.trackingNo}}</a>
          <a *ngIf="label.carrier=='UPS'" target="_blank" href="https://www.ups.com/track?loc=en_US&tracknum={{label.trackingNo}}&requester=WT/trackdetails">&#8203;{{label.trackingNo}}</a>
        </div>
      </td>
      <td *ngIf="j>0" style="color:transparent;border-top:hidden">
        <div *ngFor="let label of el.labels;">
          &#8203;{{label.trackingNo}}
        </div>
      </td>
      <td *ngIf="j==0">
        <div *ngFor="let label of el.labels">
          {{lastTrackingInfo(label.tracks)}}
        </div>
      </td>
      <td *ngIf="j>0" style="color:transparent;border-top:hidden"> </td>
      <td *ngIf="j==0">
        <div *ngFor="let label of el.labels;">
          {{countingEnRouteDays(label.tracks)}}
        </div>
      </td>
      <td *ngIf="j>0" style="color:transparent;border-top:hidden">
        0
      </td>
      <td *ngIf="j==0">{{el.shipTo.state }}</td>
      <td *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.shipTo.state }}</td>
      <td *ngIf="j==0">{{el.packingSlipPrint}}</td>
      <td *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.packingSlipPrint}}</td>
      <td *ngIf="j==0" style="width:6%;">{{el.paidAt | date:'MM/dd/yy HH:mm'}}</td>
      <td *ngIf="j>0" style="width:6%;color:transparent;border-top:hidden">{{el.paidAt | date:'MM/dd/yy HH:mm'}}</td>
      <td *ngIf="j==0" style="width:6%;">{{el.packingStartAt | date:'MM/dd/yy HH:mm:ss'}}</td>
      <td *ngIf="j>0" style="width:6%;color:transparent;border-top:hidden">{{el.packingStartAt | date:'MM/dd/yy HH:mm:ss'}}</td>
      <td *ngIf="j==0" style="width:6%;">{{el.packingEndAt | date:'MM/dd/yy HH:mm:ss'}}</td>
      <td *ngIf="j>0" style="width:6%;color:transparent;border-top:hidden">{{el.packingEndAt| date:'MM/dd/yy HH:mm:ss' }}</td>
      <td *ngIf="j==0">{{el.packingEndBy}}</td>
      <td *ngIf="j>0" style="color:transparent;border-top:hidden"></td>
      <td *ngIf="j==0" style="width:6%;">{{getShippingLabelPrintedAt(el.logs) | date:'MM/dd/yy HH:mm:ss'}}</td>
      <td *ngIf="j>0" style="width:6%;color:transparent;border-top:hidden">{{getShippingLabelPrintedAt(el.logs) | date:'MM/dd/yy HH:mm:ss'}}</td>
      <td *ngIf="j==0">
        <ul *ngFor="let log of el.logs;">
          <li>
            {{log.content}} ({{log.createdAt | date:'MM/dd/yy HH:mm'}})

          </li>
        </ul>
      </td>
      <td *ngIf="j>0" style="color:transparent;border-top:hidden">
       
      </td>
      <td *ngIf="j==0">
        <ul *ngFor="let memo of el.memoes;">
          <li>
            {{memo.content}} ({{memo.createdAt | date:'MM/dd/yy HH:mm'}})

          </li>
        </ul>
      </td>
      <td *ngIf="j>0" style="color:transparent;border-top:hidden">
        
      </td>
    </tr>
  </tbody>
 
</table>
