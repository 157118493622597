import { Component, HostListener, ViewChild, OnInit, Input, ElementRef, EventEmitter } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormArray, ValidationErrors, AbstractControl, NgModel, NgControl } from '@angular/forms';
import { Shipping, Package, ItemQty, Box, Address, SimpleLabel, Item } from '../../Class';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { delay, switchMap, map, tap } from 'rxjs/operators';
import { Time } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService, AuthenticationService } from '@app/_services';
import { ItemService } from '../../_services/item.service';
import { ShippingService } from '../../_services/shipping.service';
import * as XLSX from 'xlsx';

@Component({
  templateUrl: './excelorder.component.html',
  styleUrls: ['./excelorder.component.scss']
})

export class ExcelOrderComponent {
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'OrderCreationTemplate.xlsx');
  }

  customers: string[] = AuthenticationService.customers;
  mainForm: any;
  fileData: File = null;
  thecustomer: string = 'Krave';

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,
    private authenticationService: AuthenticationService, private shippingService: ShippingService) {
    this.authenticationService.getCustomer().then(x => this.customers = x);
    this.mainForm = fb.group({
      customer: 'Krave',
      formData: new FormData(),
    });

  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    //this.onSubmit();
  }

  onSubmit() {
    const formData = new FormData();
    //   this.mainForm.controls['formData'].setValue(this.fileData);
    formData.append('file', this.fileData);
    //  console.log(this.thecustomer);
    //  console.log(formData);
    this.shippingService.excelordercreation(this.thecustomer, formData)
      .subscribe(res => {
        console.log(res);
        if (res["isSuccess"]) {
          let newShippingDraftList = res['data'].newShippingDraftList;
          alert(`${newShippingDraftList.length} order{${newShippingDraftList.length > 0 ? 's ' : ''} created successfully.`);
        }
        else {
          alert(`${res['errorMsg']}`);
        }
      });
  }

 
}
