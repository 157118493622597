import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';

import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { ChartsModule, ChartSimpleModule, WavesModule } from 'ng-uikit-pro-standard'
import { NgModule, APP_INITIALIZER, Pipe, PipeTransform } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FilterPipe } from './pipe/FilterPipe';



// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { AuthenticationService } from './_services';
import { HomeComponent } from './home';
import { AdminComponent } from './admin';
import { LoginComponent } from './login';

import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { BoxListComponent } from './lists/box-list/box-list.component';
import { ItemListComponent } from './lists/item-list/item-list.component';
import { RestListComponent } from './lists/rest-list/rest-list.component';
import { ReorderListComponent } from './lists/reorder-list/reorder-list.component';
import { FulfilledListComponent } from './lists/fulfilled-list/fulfilled-list.component';
import { TaskListComponent } from './task/task-list/task-list.component';
import { TaskHiddenLinkListComponent } from './task/taskhiddenlink-list/taskhiddenlink-list.component';
import { ReturnListComponent } from './lists/return-list/return-list.component';
import { RestockedListComponent } from './lists/restocked-list/restocked-list.component';
import { ShipmentListComponent } from './lists/shipment-list/shipment-list.component';
import { UndeliveredListComponent } from './lists/undelivered-list/undelivered-list.component';
import { VendorReturnReceiptListComponent } from './lists/vendor-return-receipt-list/vendor-return-receipt-list.component';

import { HoldListComponent } from './lists/hold-list/hold-list.component';
import { PackingSlipListComponent } from './details/packingsliplist/packingsliplist.component';
import { PackingSlipListHiddenComponent } from './details/packingsliplist-hidden/packingsliplist-hidden.component';
import { OrderDetailComponent } from './details/orderdetail.component';
import { OrderDetailOnManuComponent } from './details/orderdetailonmanu/orderdetailonmanu.component';
import { PackingComponent } from './packing/packing.component';
import { PackingHiddenLinkComponent } from './packing/packinghiddenlink/packinghiddenlink.component';
import { ReturnComponent } from './return/return.component';
import { OrderCreationComponent } from './order-creation/order-creation.component';
import { ReorderComponent } from './order-creation/reorder/reorder.component';
import { ReorderinitComponent } from './order-creation/reorderinit/reorderinit.component';
import { ExcelOrderComponent } from './order-creation/excelorder/excelorder.component';
import { ShipmentCreationComponent } from './shipment-creation/shipment-creation.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { InventoryComponent } from './inventory/inventory.component';
import { VendorReturnReceiptComponent } from './return/vendor-return-receipt/vendor-return-receipt.component';

import { from } from 'rxjs';
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,

    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModulesPro.forRoot()
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    AdminComponent,
    LoginComponent,
    BoxListComponent,
    ItemListComponent,
    NavMenuComponent,
    RestListComponent,
    ReorderListComponent,
    FulfilledListComponent,
    TaskListComponent,
    VendorReturnReceiptListComponent,
    TaskHiddenLinkListComponent,
    ReturnListComponent,
    VendorReturnReceiptComponent,
    RestockedListComponent,
    HoldListComponent,
    ShipmentListComponent,
    UndeliveredListComponent,
    OrderDetailComponent,
    OrderDetailOnManuComponent,
    PackingSlipListComponent,
    PackingSlipListHiddenComponent,
    PackingComponent,
    PackingHiddenLinkComponent,
    ReturnComponent,
    OrderCreationComponent,
    ReorderComponent,
    ReorderinitComponent,
    ExcelOrderComponent,
    StatisticsComponent,
    ShipmentCreationComponent,
    InventoryComponent,
    FilterPipe
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    //fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
