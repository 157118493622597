import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe, Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { ItemService } from '../../_services/item.service';
import { ShippingService } from '../../_services/shipping.service';
import { ReturnService } from '../../_services/return.service';
import { TaskService } from '../../_services/task.service';
import * as XLSX from 'xlsx';
import { AuthenticationService } from '../../_services';
import { MdbCheckboxChange } from 'ng-uikit-pro-standard';

@Component({
  templateUrl: './vendor-return-receipt-list.component.html',
  styleUrls: ['./vendor-return-receipt-list.component.scss']
  
})

export class VendorReturnReceiptListComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'VendorReturnReceiptList.xlsx');
  }

  mainForm;
  elements: any[] = [];
  total: number;
  locations: string[] = ["All", "AWISEC", "AWICAS"];
  customers: string[] = [];
  user: User;
  isLoading: boolean = false;

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,
    private itemService: ItemService, private shippingService: ShippingService,
    private location: Location,
    private returnService: ReturnService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute) {
    this.user = this.authenticationService.userValue;
    this.authenticationService.getCustomer().then(x => this.customers = x);
    activatedRoute.paramMap.subscribe(params => {

      this.mainForm = fb.group({
        customer: params.get('customer') ? params.get('customer') : this.user.customer,
        location: params.get('location') ? params.get('location') : this.user.location,
      });
      this.onSubmit();
    });
    
  }

  onSubmit() {
    this.isLoading = true;
    this.location.replaceState(`/vendor-return-receipt-list/${this.mainForm.value.customer}/${this.mainForm.value.location}`);
    this.returnService.getVendorReturnReceiptList(this.mainForm.value.customer, this.mainForm.value.location)
      .subscribe(data => {
        this.isLoading = false;
        console.log(data['data']);
        this.elements = data['data'] as any[];
        this.total = this.elements.length;
      });
  }

}

