  <div *ngIf="isLoading">
    <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
    <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
  </div>
  <div class="container">
    <h3>Order Detail </h3>

    <form [formGroup]="mainForm" (ngSubmit)="onSubmit(mainForm.value)">
      <table class="fixed">
        <tr>
          <td>Customer :</td>
          <td>
            <select [formControl]="customer">
              <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>Order # :</td>
          <td>
            <input type="text"
                   style="width: 94px; border-top-style: hidden;
                    border-right-style: hidden;
                    border-left-style: hidden;
                    border-bottom-style: hidden;
                    background-color: #eee;"
                   [formControl]="orderNo"
                   placeholder="Order #" />
          </td>
        </tr>
        <tr>
          <td>Customer # :</td>
          <td>
            <input type="text"
                   style="width: 94px; border-top-style: hidden;
                    border-right-style: hidden;
                    border-left-style: hidden;
                    border-bottom-style: hidden;
                    background-color: #eee;"
                   [formControl]="customerNo"
                   placeholder="Customer #" />
          </td>
          <td>
            <button class="button" type="submit">Submit</button>
          </td>

        </tr>
      </table>
    </form>
    <p align="right" style="padding-top:15px;">
      <button class="button" style="font-size:small; margin-left:4px;" *ngIf="orderdetail!=null && orderdetail.type != 'Spf' && orderdetail.cancelledAt == null && orderdetail.closedAt == null" (click)="shippingOptionForm()">Edit ShippingOption</button>
      <button class="button" style="font-size:small; margin-left:4px;" *ngIf="orderdetail!=null && orderdetail.type != 'Spf' && orderdetail.cancelledAt == null && orderdetail.closedAt == null" (click)="updateItemsForm()">Edit Items</button>
      <button class="button" style="font-size:small; margin-left:4px;" *ngIf="orderdetail!=null && orderdetail.cancelledAt == null && orderdetail.closedAt == null" (click)="resetPackingSlip()">Reset Packing Slip</button>
      <button class="button"
              style="font-size:small; margin-left:4px;"
              *ngIf="orderdetail!=null && !isTrackingEditable"
              (click)="updateTrackingForm()">
        Tracking # Update
      </button>

      <button class="button" style="font-size:small; margin-left:4px;" *ngIf="orderdetail!=null && !isAddressEditable && orderdetail.type != 'Spf' && orderdetail.cancelledAt == null && orderdetail.closedAt == null" (click)="updateAddressForm()">Address Update</button>
      <button class="button" style="font-size:small; margin-left:4px; color:red;" (click)="trackingUpdate()" [hidden]="!isTrackingEditable">Update</button>
      <button class="button" style="font-size:small; margin-left:4px; color:red;" (click)="addressUpdate()" [hidden]="!isAddressEditable">Update</button>
      <button style="font-size:small; margin-left:4px;" *ngIf="orderdetail!=null" (click)="reprintLabel()">Print Label</button>
      <button style="font-size:small; margin-left:4px;" *ngIf="orderdetail!=null" (click)="printPackingSlip()">Print Packing Slip</button>
      <button class="button" style="font-size:small; margin-left:4px;" *ngIf="orderdetail!=null && orderdetail.cancelledAt == null && orderdetail.closedAt == null" (click)="deleteLabel()">Delete Label</button>
      <button style="font-size:small; margin-left:4px;"
              *ngIf="orderdetail!=null && orderdetail.cancelledAt == null && orderdetail.closedAt == null"
              (click)="closedUpdate()">
        Close Order
      </button>
      <button class="button" style="font-size:small; margin-left:4px;" *ngIf="orderdetail!=null && orderdetail.cancelledAt == null && orderdetail.closedAt == null" (click)="cancelOrder()">Cancel Order</button>
      <button class="button" style="font-size:small; margin-left:4px;" *ngIf="orderdetail!=null && orderdetail.cancelledAt == null && orderdetail.closedAt == null" (click)="deleteOrder()">Delete Order</button>
    </p>

    <table class="table table-striped table-sm">
      <tbody *ngIf="orderdetail">
        <tr>
          <td style="width:20%;">Type</td>
          <td>{{orderdetail.type}}</td>
        </tr>
        <tr>
          <td>Order #</td>
          <td>{{orderdetail.orderNo}}</td>
        </tr>
        <tr>
          <td>Customer</td>
          <td>{{orderdetail.customer}}</td>
        </tr>
        <tr>
          <td>Customer #</td>
          <td>{{orderdetail.customerNo}}</td>
        </tr>
        <tr>
          <td>Location</td>
          <td>{{orderdetail.location}}</td>
        </tr>
        <tr>
          <td>Task #</td>
          <td>
            <a [routerLink]="['/task',orderdetail.customer,orderdetail.packingSlipPrint]">{{orderdetail.packingSlipPrint}}</a>
          </td>
        </tr>
        <tr>
          <td>Packing Slip #</td>
          <td>
            <a [routerLink]="['/packing',orderdetail.customer,orderdetail.orderNo]">Go to packing page [Order#: {{orderdetail.orderNo}}]</a>
          </td>
        </tr>
        <tr>
          <td>Items</td>
          <td>
            <button *ngIf="isItemEditable" (click)="addItem()">Add</button>
            <button *ngIf="isItemEditable" (click)="updateItems()">Update</button>
            <table style="width:100%;">
              <thead>
                <tr>
                  <td>Sku</td>
                  <td>Desc</td>
                  <td style="width:20%;">Qty, FulfillableQty</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of orderdetail.items; let i = index">
                  <td>
                    <span [hidden]="isItemEditable">
                      {{item.sku}}
                    </span>
                    <input type="text" list="skulist" *ngIf="isItemEditable" [(ngModel)]="item.sku" (change)="selectSku(i, item.sku)" />
                    <datalist id="skulist">
                      <select>
                        <option *ngFor="let element of itemlist" [value]="element.sku"></option>
                      </select>
                    </datalist>
                  </td>
                  <td>
                    <span [hidden]="isItemEditable">
                      {{item.desc}}
                    </span>
                    <input type="text" list="desclist" *ngIf="isItemEditable" [(ngModel)]="item.desc" (change)="selectDesc(i,  item.desc)" />
                    <datalist id="desclist">
                      <select>
                        <option *ngFor="let element of itemlist" [value]="element.desc"></option>
                      </select>
                    </datalist>
                  </td>
                  <td>
                    <span [hidden]="isItemEditable">
                      {{item.qty}},{{item.fulfillableQty}}
                    </span>
                    <input type="number" size="3" *ngIf="isItemEditable" [(ngModel)]="item.qty" style="width: 50%;" /><button *ngIf="isItemEditable" (click)="deleteItem(i)">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>Weight</td>
          <td>{{orderdetail.weight}} (lb)</td>
        </tr>
        <tr>
          <td>Total Price</td>
          <td>$ {{orderdetail.totalPrice}}</td>
        </tr>
        <tr>
          <td>Hold?</td>
          <td>{{orderdetail.isHold}}</td>
        </tr>
        <tr>
          <td>Carrier</td>
          <td>
            <span *ngIf="orderdetail.labels" [hidden]="isTrackingEditable">
              {{orderdetail.labels[0].carrier}}
            </span>

            <select *ngIf="isTrackingEditable" [(ngModel)]="label.carrier" id="inputGroupSelect01">
              <option value="Endicia">Endicia</option>
              <option value="FedEx">FedEx</option>
            </select>


          </td>
        </tr>
        <tr>
          <td>Shispping Option</td>
          <td>

            <span [hidden]="isShippingOptionEditable">
              {{orderdetail.shippingOption}}
              <span *ngIf="orderdetail.labels">
                - {{orderdetail.labels[0].shippingOption}}
              </span>
            </span>


            <select *ngIf="isShippingOptionEditable" [(ngModel)]="orderdetail.shippingOption">
              <option value="Standard (Intra)">Standard</option>
              <option value="Ground (Intra)">Ground</option>
              <option value="Priority (Intra)">Priority</option>
              <option value="Express (Intra)">Express</option>
            </select>
            <button *ngIf="isShippingOptionEditable" (click)="updateShippingOption()">Update</button>
          </td>
        </tr>
        <tr>
          <td>Tracking #</td>
          <td>
            <span *ngIf="orderdetail.labels" [hidden]="isTrackingEditable">
              <a *ngIf="orderdetail.labels[0].carrier=='Endicia' || orderdetail.labels[0].carrier=='PitneyBowes'" href="https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels={{orderdetail.labels[0].trackingNo}}%2C">&#8203;{{orderdetail.labels[0].trackingNo}}</a>
              <a *ngIf="orderdetail.labels[0].carrier=='FedEx'" href="https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber={{orderdetail.labels[0].trackingNo}}&cntry_code=us&locale=en_US">&#8203;{{orderdetail.labels[0].trackingNo}}</a>
              <a *ngIf="orderdetail.labels[0].carrier=='UPS'" href="https://www.ups.com/track?loc=en_US&tracknum={{orderdetail.labels[0].trackingNo}}&requester=WT/trackdetails">&#8203;{{orderdetail.labels[0].trackingNo}}</a>
            </span>
            <input *ngIf="isTrackingEditable" type="text" [(ngModel)]="label.trackingNo">
          </td>
        </tr>
        <tr>
          <td>Price</td>
          <td>
            <span *ngIf="orderdetail.labels">
              $ {{orderdetail.labels[0].price}}
            </span>
          </td>
        </tr>
        <tr>
          <td>Signature</td>
          <td><span *ngIf="orderdetail.labels">{{orderdetail.labels[0].isSignature}}</span></td>
        </tr>
        <tr>
          <td>Order At</td>
          <td>{{orderdetail.orderAt | date:'MM/dd/yyyy HH:mm'}}</td>
        </tr>
        <tr>
          <td>Paid At</td>
          <td>{{orderdetail.paidAt | date:'MM/dd/yyyy HH:mm'}}</td>
        </tr>
        <tr>
          <td>Ready At</td>
          <td>{{orderdetail.readyAt | date:'MM/dd/yyyy HH:mm'}}</td>
        </tr>

        <tr>
          <td>Packing Slip Print At</td>
          <td>{{orderdetail.packingSlipPrintAt | date:'MM/dd/yyyy HH:mm'}}</td>
        </tr>
        <tr>
          <td>Packing End At</td>
          <td>{{orderdetail.packingEndAt | date:'MM/dd/yyyy HH:mm'}}</td>
        </tr>
        <tr>
          <td>Packing End By</td>
          <td>{{orderdetail.packingEndBy}}</td>
        </tr>

        <tr>
          <td>Label Created At</td>
          <td><span *ngIf="orderdetail.labels && orderdetail.labels[0]">{{orderdetail.labels[0].createdAt | date:'MM/dd/yyyy HH:mm'}}</span></td>
        </tr>
        <tr>
          <td>Closed At</td>
          <td>{{orderdetail.closedAt | date:'MM/dd/yyyy HH:mm'}}</td>
        </tr>
        <tr>
          <td>Cancelled At</td>
          <td>{{orderdetail.cancelledAt | date:'MM/dd/yyyy HH:mm'}}</td>
        </tr>
        <tr>
          <td>Name</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.name}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.name">
          </td>

        </tr>
        <tr>
          <td>Company</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.company}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.company">
          </td>

        </tr>
        <tr>
          <td>Email</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.email}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.email">
          </td>
        </tr>
        <tr>
          <td>Address 1</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.address1}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.address1">
          </td>
        </tr>
        <tr>
          <td>Address 2</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.address2}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.address2">
          </td>
        </tr>
        <tr>
          <td>City</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.city}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.city">
          </td>
        </tr>
        <tr>
          <td>State</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.state}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.state">
          </td>
        </tr>
        <tr>
          <td>Zip</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.zip}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.zip">
          </td>
        </tr>
        <tr>
          <td>Country</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.country}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.country">
          </td>
        </tr>
        <tr>
          <td>Logs</td>
          <td>
            <div *ngFor="let log of orderdetail.logs;" style="display:block;">
              {{log.content}} ({{log.createdAt | date:'MM/dd/yyyy HH:mm'}})
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

