import { Role } from "./role";

export class User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: Role;
  jwtToken?: string;
  refreshToken: string;
  uspsCarrier?: string;
  location?: string;
  customer?: string;
}
