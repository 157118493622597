
<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>
<h1>Item List</h1>
<form [formGroup]="mainForm" (ngSubmit)="onSubmit(mainForm.value)" class="float-left">
  <div class="row" style="padding-bottom:5px; padding-right:10px;">
    <select formControlName="customer" class="browser-default custom-select" style="width:120px;font-size:small; ">
      <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
    </select>
    <div style="margin-top:2px; padding-left:15px;padding-right:15px;">
      <button mdbBtn class="btn btn-amber" size="sm" type="submit" mdbWavesEffect>Submit</button>
    </div>
  </div>
</form>

<div class="row float-right">
  <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="Search Item by Sku" />
  <button mdbBtn *ngIf="itemlist!=null" type="button" color="deep-orange" rounded="true" (click)="add()" class="btn btn-success" size="sm" mdbWavesEffect>Add Items</button>
  <button mdbBtn type="button" color="success" rounded="true" (click)="ExportTOExcel()" class="btn btn-success" size="sm" mdbWavesEffect>Excel</button>
</div>

<div class="file-upload-container">
  <div><input type="file" (change)="fileProgress($event)" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"></div>
  <div><button (click)="onExcelUpload()">Upload Excel File</button></div>
  <div><a href="/assets/templates/Item_upload_template.xlsx" download>Download Excel Template</a></div>
</div>

<table class="table table-bordered table-sm" style="margin-top: 10px;" #TABLE #table>
  <thead>
    <tr align="center">
      <th scope="col">#</th>
      <th scope="col">Sku</th>
      <th scope="col">Desc</th>
      <th scope="col">UPC</th>
      <th scope="col">UPC 2</th>
      <th scope="col">Weight(g)</th>
      <th scope="col">NickName</th>
      <th scope="col">Createdby</th>
      <th scope="col">Updatedby</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of itemlist | filter: searchText : 'sku'; let i = index">
      <td align="center">
        <span>{{i+1}}</span>
      </td>
      <td>
        <span [hidden]="element.isEditable">{{element.sku}}</span>
        <input type="text" [(ngModel)]="element.sku" [hidden]="!element.isEditable">
      </td>
      <td>
        <span [hidden]="element.isEditable">{{element.desc}}</span>
        <input type="text" [(ngModel)]="element.desc" [hidden]="!element.isEditable">
      </td>
      <td>
        <span [hidden]="element.isEditable">{{element.upc}}</span>
        <input type="text" [(ngModel)]="element.upc" [hidden]="!element.isEditable">
      </td>
      <td>
        <span [hidden]="element.isEditable">{{element.upc2}}</span>
        <input type="text" [(ngModel)]="element.upc2" [hidden]="!element.isEditable">
      </td>

      <td>
        <span [hidden]="element.isEditable">{{element.weight}}</span>
        <input type="number" [(ngModel)]="element.weight" [hidden]="!element.isEditable">
      </td>
      <td>
        <span [hidden]="element.isEditable">{{element.nickName}}</span>
        <input type="text" [(ngModel)]="element.nickName" [hidden]="!element.isEditable">
      </td>
      <td>
        <span>{{element.createdBy}}</span>
      </td>
      <td>
        <span>{{element.updatedBy}}</span>
      </td>
      <td>
        <span style="padding-right: 5px;">
          <button type="button" (click)="edit(i)" [hidden]="element.isEditable">Edit</button>
          <button type="button" (click)="update(i)" [hidden]="!element.isEditable">Update</button>
          <button type="button" (click)="delete(i)" [hidden]="element.isEditable">Delete</button>
        </span>
      </td>
    </tr>
  </tbody>
</table>
