import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { ShippingService } from '../../_services/shipping.service';
import { ItemService } from '../../_services/item.service';
import { Time } from '@angular/common';
import { Shipping, Label, Item } from '../../Class';
import { AuthenticationService } from '../../_services';

declare function print_zpl(zpl: string): void;

@Component({
  templateUrl: './orderdetailonmanu.component.html',
  styleUrls: ['./orderdetailonmanu.component.scss']
})

export class OrderDetailOnManuComponent {

  locations: string[] = ["All", "AWISEC", "AWICAS"];
  customers: string[] = AuthenticationService.customers;
  customerPrefixes: string[] = AuthenticationService.customerPrefixes;
  mainForm;
  customer: string;
  orderNo: number;
  customerNo: string;
  orderdetail: any;
  label: any;
  itemlist: any;
  estimWeight: string;
  isLoading: boolean = false;
  isTrackingEditable: boolean = false;
  isAddressEditable: boolean = false;
  isItemEditable: boolean = false;
  isShippingOptionEditable: boolean = false;

  user: User;

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,
    private itemService: ItemService,
    private shippingService: ShippingService, private authenticationService: AuthenticationService) {
    this.user = this.authenticationService.userValue;
    this.authenticationService.getCustomer().then(x => this.customers = x);

    this.mainForm = fb.group({
      orderNo: null,
      customerNo: '',
      customer: this.user.customer,
      labels: new Label
    });

    this.customer = this.mainForm.controls['customer'];
    this.orderNo = this.mainForm.controls['orderNo'];
    this.customerNo = this.mainForm.controls['customerNo'];
  }


  ngOnInit() {
   
  }

  addressUpdate() {
   // this.orderdetail.weight = this.orderdetail.weight + "";
    this.shippingService.updateAddress(this.orderdetail).subscribe(data => {
      console.log(data);
      alert('successfully updated.');
    })
    this.isAddressEditable = !this.isAddressEditable;
  }

  cancelOrder() {
    if (this.orderdetail.type == 'Spf' || this.orderdetail.type == 'Jet') {
      alert('Cannot cancel: This order type is Spf or Jet.');
    }
    else if (this.orderdetail.labels != null && this.orderdetail.labels.length > 0 && this.orderdetail.labels[0].trackingNo != null) {
      alert('Cannot cancel: The label has been created.');
    }
    else if (this.orderdetail.packingEndAt != null) {
      alert('Cannot cancel: The items have been packed.');
    }
    else {
      this.shippingService.cancelOrder(this.orderdetail).subscribe(data => {
        alert('order canceled.');
      });
    }
  }

  closedUpdate(): void {
    if (confirm(`Do you really want to close this order?`)) {
      if (this.orderdetail.closedAt != null) {
        alert('This order was already closed.')
      } else if (this.orderdetail.type == 'Spf' && this.orderdetail.type == 'netsuite') {
        alert('This order type is not "Manual". Only manual orders can be closed manually.')
      } else if (this.orderdetail.closedAt == null
        && (this.orderdetail.type != 'Spf' && this.orderdetail.type != 'netsuite')) {
        this.isLoading = true;
        this.shippingService.closeOrder(this.orderdetail).subscribe(data => {
          this.isLoading = false;
          console.log(data);
          if (data["isSuccess"] == true) {
            alert('Successfully closed for this order');
          } else {
            alert(data["errorMsg"]);
          }
        });
      } else {
        alert('Failed your request.')
      }
    }
  }

  deleteLabel() {
    if (this.orderdetail.type == 'Spf' || this.orderdetail.type == 'Jet') {
      alert('Cannot delete the label: This order type is Spf or Jet.');
    } else if (this.orderdetail.labels == null || !this.orderdetail.labels) {
      alert('Cannot delete the label: This order has no label.');
    } else if (this.orderdetail.labels.length > 0 && this.orderdetail.labels[0].trackingNo == null) {
      alert('Cannot delete the label: This order has no label.');
    } else {
      this.shippingService.deleteLabel(this.orderdetail).subscribe(data => {
        //console.log(data);
        alert('label deleted');
      });
    }
  }

  deleteOrder() {
  //  console.log(this.mainForm.get('customer').value, this.mainForm.get('orderNo').value);
    this.shippingService.deleteOrder(this.mainForm.get('customer').value, this.mainForm.get('orderNo').value).subscribe(result => {
      if (result["isSuccess"] == true) {
        alert('successfully deleted.');
      } else {
        console.log(result);
        alert(`fail to delete:${result["errorMsg"]}`);
      }
    })
   
  }

  getWeight() {
    this.itemService.getItemList(this.orderdetail.customer)
      .subscribe(data => {
        this.itemlist = data as ItemList[];
        console.log(this.itemlist);

        var result = 0;
        for (let i = 0; i < this.orderdetail.items.length; i++) {
          let n = this.itemlist.findIndex(j => j.sku == this.orderdetail.items[i].sku);
          if (n > -1) {
            result += this.orderdetail.items[i].qty * this.itemlist[n].weight;
          }
        }
        this.estimWeight = (result / 453.592 + 0.2).toFixed(2);
        console.log(this.estimWeight);
      });
  }

  addItem() {
    this.orderdetail.items.push(new Item);
  }

  deleteItem(index) {
    this.orderdetail.items.splice(index,1);
  }

  updateItems() {
    this.isItemEditable = !this.isItemEditable;
    this.shippingService.updateItems(this.orderdetail)
      .subscribe(data => {
        alert('successfully updated');
        this.onSubmit(null);
      });

  }

  onSubmit(formData) {
    
    if (this.mainForm.valid) {
      this.customer = this.mainForm.get('customer').value;
      //if (this.mainForm.get('orderNo').value != null) {

      //}
      let orderNo = this.mainForm.get('orderNo').value ? this.mainForm.get('orderNo').value : '';
      if (orderNo) {
        let orderNoNumberOnly = orderNo.match(/\d+/g).join('');
        let prefix = orderNo.replace(/\d+/g, '');
        console.log(AuthenticationService.customerPrefixes);
        if (prefix) {
          const index = AuthenticationService.customerPrefixes.indexOf(prefix);
          if (index > -1) {
            this.customer = this.customers[index];
            this.mainForm.get('customer').setValue(this.customer);
            orderNo = orderNoNumberOnly;
          }
        }
      }
      //console.log(`orderNoNumberOnly ${orderNoNumberOnly}`)
      //console.log(`prefix ${prefix}`)
      console.log('OrderNo', orderNo);
      this.shippingService.postOrderDetail(
        this.customer as string,
        ~~orderNo,
        this.mainForm.get('customerNo').value
      ).subscribe(data => {
        console.log(data);
        this.orderdetail = data;
      })
    }
  }

  reprintLabel() {
    if (this.orderdetail.labels == null || !this.orderdetail.labels) {
      alert('there is no label for this order');
    } else {
      print_zpl(this.orderdetail.labels[0]["image"]);
    }
  }
  resetPackingSlip() {
    if (confirm('Do you really want to reset packing slip?')) {
      this.shippingService.resetPackingSlip(this.orderdetail)
        .subscribe(response => {
          alert("Successfully packing slip re-generated");
        });
    }

  }
  trackingUpdate() {
    console.log(this.label);
    if (!this.orderdetail.labels || this.orderdetail.labels == null) {
      this.orderdetail.labels = [];
      this.orderdetail.labels.push(this.label);
    } else {
      this.orderdetail.labels[0].trackingNo = this.label.trackingNo;
      this.orderdetail.labels[0].carrier = this.label.carrier;
    }
    console.log(this.orderdetail.labels);

    this.shippingService.updateTrackingNumber(this.orderdetail).subscribe(data => {
      console.log(data);
      if (data == null) {
        alert('The tracking number duplicates.');
      } else {
        alert('successfully updated');
      }
    });
    this.isTrackingEditable = !this.isTrackingEditable;
  }

  updateAddressForm(): void {
    if (this.orderdetail.labels != null) {
      if (this.orderdetail.labels[0].trackingNo != null) {
        alert('fail to update the address because the label was already created.');
        return;
      }
    }
    if (this.orderdetail.type == "Spf") {
      alert('fail to update the address because this type is Spf.');
      return;
    }
    this.isAddressEditable = !this.isAddressEditable;
  }

  updateItemsForm() {
    if (this.orderdetail.type == "Spf") {
      alert('You cannot modify this order because this order type is Shopify Order.');
      return;
    }

    else if (this.orderdetail.labels != null && this.orderdetail.labels[0].trackingNo != null) {
      alert('You cannot modify this order because a label was already created.');
      return;
    }

    else {
      this.isItemEditable = !this.isItemEditable;
      this.itemService.getItemList(this.orderdetail.customer)
        .subscribe(data => {
          this.itemlist = data as ItemList[];
          console.log(this.itemlist);
        });
    }
  }

  updateTrackingForm() {
    if (this.orderdetail.packingEndAt == null) {
      alert('Packing has not been completed.');
      return;
    }

    if (!this.orderdetail.labels ||
      this.orderdetail.labels == null ||
      this.orderdetail.labels[0].trackingNo == null) {

      this.label = new Label;
      this.isTrackingEditable = !this.isTrackingEditable;
      console.log(this.isTrackingEditable);
      console.log(this.label);

    } else {
      alert('tracking # already exist');
    }
  }

  shippingOptionForm() {
    if (this.orderdetail.type == "Spf") {
      alert('Fail to change: The order type is Spf.');
      return;
    }
    else if (this.orderdetail.labels != null && this.orderdetail.labels[0].trackingNo != null) {
      alert('Fail to change: This order has a label.');
      return;
    } else {
      this.isShippingOptionEditable = !this.isShippingOptionEditable;
      this.getWeight();
    }

  }

  updateShippingOption() {
    console.log(this.orderdetail);

    if (this.orderdetail.shippingOption == "Standard (Intra)") {
      if (Number(this.estimWeight) <= 1 && Number(this.estimWeight) > 0) {
        this.orderdetail.labels[0].Carrier = "Endicia";
        this.orderdetail.labels[0].ShippingOption = "First";
      } else if (Number(this.estimWeight) > 1) {
        this.orderdetail.labels[0].Carrier = "FedEx";
        this.orderdetail.labels[0].ShippingOption = "SMART_POST";
      }
    } else if (this.orderdetail.shippingOption == "Ground (Intra)") {
      this.orderdetail.labels[0].Carrier = "FedEx";
      this.orderdetail.labels[0].ShippingOption = "FEDEX_GROUND";
    } else if (this.orderdetail.shippingOption == "Priority (Intra)") {
      this.orderdetail.labels[0].Carrier = "Endicia";
      this.orderdetail.labels[0].ShippingOption = "Priority";
    } else if (this.orderdetail.shippingOption == "Express (Intra)") {
      this.orderdetail.labels[0].Carrier = "FedEx";
      this.orderdetail.labels[0].ShippingOption = "FEDEX_2_DAY_OneRate";
    } else {
      alert('please select shippingoption.');
      return;
    }

    this.shippingService.changeShippingOption(this.orderdetail)
      .subscribe(response => {
        alert(response['errorMsg']);
      });

  }
  printPackingSlip() {
    if (!this.orderdetail.packingSlip) {
      alert('there is no packing slip for this order');
    } else {
      print_zpl(this.orderdetail.packingSlip.dfr + this.orderdetail.packingSlip.xfr);
      //console.log(this.orderdetail.packingSlip.dfr + this.orderdetail.packingSlip.xfr);
    }
  }
  selectSku(index: number, sku) {
    var i = this.itemlist.findIndex(i => i.sku == sku);
    this.orderdetail.items[index].desc = this.itemlist[i].desc;
  }

  selectDesc(index: number, desc) {
    var i = this.itemlist.findIndex(i => i.desc == desc);
    this.orderdetail.items[index].sku = this.itemlist[i].sku;
  }

}

class ItemList {
  id: string;
  sku: string;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
}
