import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { ItemService } from '../../_services/item.service';
import { ShippingService } from '../../_services/shipping.service';
import * as XLSX from 'xlsx';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { AuthenticationService } from '../../_services';


@Component({
  templateUrl: './fulfilled-list.component.html',
  styleUrls: ['./fulfilled-list.component.scss']
})

export class FulfilledListComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'FulfilledList.xlsx');
  }

  public myDatePickerOptions: IMyOptions = {
    firstDayOfWeek: 'su' // 'mo' - Monday, 'tu' - Tuesday, 'we' - Wednesday, 'th' - Thursday, 'fr' - Friday, 'sa' - Saturday, 'su' - Sunday
  };

  mainForm;
  elements: any = [];
  total: number;
  itemlist: ItemList[];
  yesterday = new Date();
  locations: string[] = ["All", "AWISEC", "AWICAS"];
  customers: string[] = AuthenticationService.customers;
  checkedlist: string[] = [];
  mastercheck: boolean = false;
  isLoading: boolean = false;
//  filters: Filter[] = [];
  ifFilter: boolean = false;
  filterred: any[];
  user: User;
  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,
    private location: Location,
    private itemService: ItemService, private shippingService: ShippingService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute) {
    this.mainForm = fb.group({
      
    });
    this.authenticationService.getCustomer().then(x => this.customers = x);
    this.user = this.authenticationService.userValue;
    activatedRoute.paramMap.subscribe(params => {

      this.mainForm = fb.group({
        customer: params.get('customer') ? params.get('customer') : this.user.customer,
        location: params.get('location') ? params.get('location') : this.user.location,
        startAt: new Date(new Date().setHours(0, 0, 0, 0)),
        endAt: new Date(new Date().setHours(0, 0, 0, 0)),
      });
      this.onSubmit(this.mainForm.value);
    });
  }

  ngOnInit() {
    this.yesterday.setDate(this.yesterday.getDate() - 1);
  }

  createCheckedList(value: any, id: string) {
    for (let i = 0; i < this.checkedlist.length; i++) {
      console.log(this.checkedlist[i]);
    }

    if (value.currentTarget.checked == true) {
      this.checkedlist.push(id);
    }
    if (value.currentTarget.checked == false) {
      const index: number = this.checkedlist.indexOf(id);
      if (index !== -1) {
        this.checkedlist.splice(index, 1);
      }
    }
    console.log(this.checkedlist);
  }

  countingEnRouteDays(tracks: any[]): number {
   
    if (tracks) {
      if (tracks.some(x => x.statusDesc == "En Route") && !tracks.some(x => x.statusDesc == "Delivered")) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        var enRoute = tracks.find(x => x.statusDesc == "En Route");
        var activityOn_temp = new Date(enRoute.activityOn).toUTCString();
        var activityOn = new Date(Date.parse(activityOn_temp.substring(0, 16)));
        var result = activityOn.getTime() - today.getTime(); // skip the first day
        return Math.ceil(Math.abs(result) / (1000 * 60 * 60 * 24));

      } else if (tracks.some(x => x.statusDesc == "En Route") && tracks.some(x => x.statusDesc == "Delivered")) {
        var enRoute = tracks.find(x => x.statusDesc == "En Route");
        var activityOn_temp = new Date(enRoute.activityOn).toUTCString();
        var activityOn = new Date(Date.parse(activityOn_temp.substring(0, 16)));
        var deliveredEvent = tracks.find(x => x.statusDesc == "Delivered");
        var deliveredAt_temp = new Date(deliveredEvent.activityOn).toUTCString();
        var deliveredAt = new Date(Date.parse(deliveredAt_temp.substring(0, 16)));
        var result = activityOn.getTime() - deliveredAt.getTime();
        return Math.ceil(Math.abs(result) / (1000 * 60 * 60 * 24));
      } else {
        return 0;
      }
    } else {
      return 0;
    }
   
  }

  lastTrackingInfo(tracks: any[]): string {
    if (tracks) {
      var length = tracks.length;
      if (length > 0) {
        return tracks[length - 1].statusDesc;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  


  isEarlierthanYesterday(index: number): boolean {
    let elpaid = new Date(this.elements[index].paidAt);
    let paidyear = elpaid.getFullYear();
    let paidmonth = elpaid.getMonth();
    let paiddate = elpaid.getDate();
    if ((paidmonth <= this.yesterday.getMonth() && paiddate <= this.yesterday.getDate()) || (paidyear < this.yesterday.getFullYear()) ||
      (paidmonth < this.yesterday.getMonth() && paiddate >= this.yesterday.getDate())) {
      return true;
    } else {
      return false;
    }
  }

  getShippingLabelPrintedAt(logs: any[]) {
    if (logs && logs.length > 0) {
      if (logs.find(x => x.content != null && x.content.includes("Shipping Label was printed by")) != null) {
        return logs.find(x => x.content != null && x.content.includes("Shipping Label was printed by")).createdAt
      }
    }
  }


  onSubmit(formData) {
    this.isLoading = true;
    this.location.replaceState(`/fulfilled-list/${this.mainForm.value.customer}/${this.mainForm.value.location}`);
    this.shippingService.getFulfilledList(formData)
      .subscribe(data => {
        this.isLoading = false;
        this.elements = data;
        this.total = this.elements.length;
      });
  
    this.filterred = [];
  //  this.filters = [];
  //  this.addFilterRows();
  }


}

class ItemList {
  id: string;
  sku: string;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
}


