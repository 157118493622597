import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { User, Role } from '@app/_models';
import { AuthenticationService } from '@app/_services';
import { ShippingService } from '../../_services/shipping.service';
import { TaskService } from '../../_services/task.service';
import * as XLSX from 'xlsx';
import { DatePipe, Location } from '@angular/common';

declare function print_zpl(zpl: string): void;

declare function printLabels(zpl: string, successCallback: () => void, errorCallback: () => void): void;
@Component({
  templateUrl: './taskhiddenlink-list.component.html',
  styleUrls: ['./taskhiddenlink-list.component.scss']
})

export class TaskHiddenLinkListComponent {
  user: User;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'TaskList.xlsx');
  }

  printProgress: number;
  printTotalCount?: number = null;
  printIndex?: number = null;
  mainForm;
  elements: any = [];
  total: number;
  locations: string[] = ["All", "AWISEC", "AWICAS"];
  customers: string[] = AuthenticationService.customers;
  isLoading: boolean;
  constructor(
    private fb: FormBuilder, private http: HttpClient, private router: Router,
    private shippingService: ShippingService,
    private taskService: TaskService,
    private location: Location,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute) {
    this.authenticationService.getCustomer().then(x => this.customers = x);
    this.authenticationService.user.subscribe(x => this.user = x);
    activatedRoute.paramMap.subscribe(params => {
      this.mainForm = fb.group({
        customer: params.get('customer') ? params.get('customer') : 'Krave',
        location: params.get('location') ? params.get('location') : 'All',
        page: 1
      });
      this.onSubmit();
    });
  }

  changeLocation(cus: string, psnumber: number, location: string) {
    this.taskService.changeTasksLocation(cus, psnumber, location).subscribe(data => {
      if (data == 1) {
        alert('task moved.');
      } else {
        alert('fail to move the task: plase check if packed, closed, or cancelled.');
      }
    });
  }

  onSubmit() {
    this.isLoading = true;
    this.location.replaceState(`/taskhiddenlink-list/${this.mainForm.value.customer}/${this.mainForm.value.location}`);
    this.taskService.getTaskList(this.mainForm.value)
      .subscribe(data => {
        this.isLoading = false;
        this.elements = data["list"];
        this.total = data["total"];
        console.log('done search');

      });
  }

  printPackingSlipAll(cus: string, psnumber: number) {
    this.taskService.getPackingSlipList(cus, psnumber).subscribe(response => {
      console.log(response);
      if (response["isSuccess"]) {
        this.printPackingSlips(response["data"] as string[], 0, cus, psnumber);
      } else {
        alert(response["errorMsg"]);
      }
    });
  }

  printPackingSlips(zplCommands: string[], index: number, customer: string, taskId: number) {

    var printPacking = function (commands, printIndex) {
      function next() {
        console.log(`Printing ${printIndex} packing slip.`);
        if (commands.length > printIndex) {
          if (printIndex % 10 == 0) {
            setTimeout(function () {
              printPacking(commands, printIndex + 1);
            }, 1000);
          } else {
            printPacking(commands, printIndex + 1);
          }
        }
        else {
          alert(`[#${taskId}] ${customer}'s packing slips(${printIndex}) were printed all successfully`);
        }
      }
      printLabels(commands[printIndex], next, function () {
        console.log(`Printing ${printIndex} packing slip.`);
        if (confirm(`Do you want to keep printing from last [${printIndex}] succeeded?`)) {
          printPacking(commands, printIndex);
        }
      });
    }
    printPacking(zplCommands, index);
  }

  generateLabelsAll(customer: string, orderMongoIdList: string[]) {
    this.generateLabel(customer, orderMongoIdList, 0);

  }
  generateLabel(customer: string, orderMongoIdList: string[], currentIndex: number) {
    if (orderMongoIdList.length > currentIndex) {
      const mongoShippingId = orderMongoIdList[currentIndex];

      this.taskService.generateLabel(customer, mongoShippingId).subscribe(response => {
        if (response["isSuccess"]) {
          console.log(mongoShippingId);
          console.log(currentIndex);

          printLabels(response["labelImage"], function () {
            printLabels(response["rmaImage"], function () {
              this.generateLabel(customer, orderMongoIdList, currentIndex + 1);
            }, function () { });
          }, function () { })
        } else {
          this.generateLabel(customer, orderMongoIdList, currentIndex + 1);
        }
      });
    }
    else {
      this.mainForm.submit();
    }

  }
}

class ItemList {
  id: string;
  sku: string;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
}


