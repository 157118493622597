<h1>Rest List</h1>

<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>
<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" class="float-left">
  <div class="row" style="padding-bottom:5px; padding-right:10px;">

    <div style="padding-right:15px;">
      <select formControlName="location" class="browser-default custom-select" style="width: 100px;font-size: small;">
        <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
    <div>
      <select formControlName="customer" class="browser-default custom-select" style="width:120px;font-size:small;">
        <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
    <div class="checkbox-container" style="">
      <mdb-checkbox checked='checked' (change)="showAllTasks($event)">Show All</mdb-checkbox>
    </div>
    <div style="padding-left:15px;padding-right:15px;">
      <button mdbBtn class="btn btn-amber" size="sm" type="submit" mdbWavesEffect>Submit</button>
    </div>
    <div style="padding-top:10px;" [hidden]="!total">
      Total: {{total}}
    </div>
  </div>
</form>
<form [formGroup]="memoForm" (ngSubmit)="onMemoSubmit(memoForm.value)" class="row float-right">
  <table>
    <tr>
      <td>
        <input type="text" class="form-control" formControlName="orderNo" placeholder="Order No">
      </td>
      <td>
        <input type="text" class="form-control" formControlName="content" placeholder="Memo">
      </td>
      <td>
        <button mdbBtn class="btn btn-amber" rounded="true" size="sm" type="submit" mdbWavesEffect>Submit</button>
      </td>
      <td>|</td>
      <td>
        <div align="right">
          <!--<button mdbBtn type="button" color="light-blue" rounded="true" class="btn btn-success" size="sm" mdbWavesEffect><mdb-icon fas icon="filter"></mdb-icon>Filter</button>-->
          <button mdbBtn type="button" color="amber" rounded="true" (click)="passthelist()" class="btn btn-success" size="sm" mdbWavesEffect>Create Task</button>
          <button mdbBtn type="button" color="indigo" rounded="true" (click)="holdShipping()" size="sm" mdbWavesEffect>Hold Selected Orders</button>
          <button mdbBtn type="button" color="success" rounded="true" (click)="ExportTOExcel()" class="btn btn-success" size="sm" mdbWavesEffect>Excel</button>
        </div>
      </td>
    </tr>
  </table>

</form>




<table class="table table-bordered table-sm" style="margin-top: 10px;" #TABLE #table>
  <thead>
    <tr align="center">
      <th scope="col">
        All<input type="checkbox" [(ngModel)]="mastercheck" (click)="onCheckboxSelected($event)" />
      </th>
      <th scope="col">Type</th>
      <th scope="col">Customer #</th>
      <th scope="col">Order #</th>
      <th scope="col">Location</th>
      <th scope="col">State</th>
      <th scope="col">IsHold</th>
      <th scope="col">Risk</th>
      <th scope="col">Name / Email</th>
      <th scope="col">
        Items
      </th>
      <th scope="col">
        Qty
      </th>
      <th scope="col">ShippingOption</th>
      <th scope="col">ReadyAt</th>
      <th scope="col">Task</th>
      <th scope="col">PackingSlip<br />PrintAt</th>

      <th scope="col">Log</th>
      <th scope="col">Memo</th>
    </tr>
  </thead>

  <tbody *ngFor="let el of elements; let i = index">
    <tr mdbTableCol *ngFor="let item of el.items; let j = index">



      <td style="text-align:center;" [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
        <input type="checkbox" [checked]="el.checked" class="checkbox-input" value={{el.id}} (click)="createCheckedList($event, el)" />
      </td>
      <td *ngIf="j>0" style="color:transparent;border-top:hidden"></td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.type}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.type}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.customerNo}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.customerNo}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
        <a [routerLink]="['/individualshipping',el.customer,el.orderNo]">{{el.orderNo}}</a>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">
        {{el.orderNo}}
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.location}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.location}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH' || el.shipTo.country !='US'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0"> <span *ngIf="el.shipTo">{{el.shipTo.state }}</span></td>
      <td [class.bg-danger]="el.riskLevel=='HIGH' || el.shipTo.country !='US'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden"> <span *ngIf="el.shipTo">{{el.shipTo.state }}</span></td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
        <span *ngIf="el.isHold" class="red-text">{{el.isHold}}</span>
        <span *ngIf="!el.isHold">{{el.isHold}}</span>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">
        <span *ngIf="el.isHold">{{el.isHold}}</span>
        <span *ngIf="!el.isHold">{{el.isHold}}</span>
      </td>
     
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.riskLevel}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.riskLevel}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
        <span *ngIf="el.shipTo">{{el.shipTo.name}}</span> /
        <span *ngIf="el.shipTo">{{el.shipTo.email}}</span>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">
        <span *ngIf="el.shipTo">{{el.shipTo.name}}</span> /
        <span *ngIf="el.shipTo">{{el.shipTo.email}}</span>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" style="width:12%;">
        {{item.sku}}
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'">
        {{item.qty}}
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0" style="width:12%;">
      {{el.shippingOption}}
        <span *ngIf="el.labels && el.labels.length > 0 && el.labels[0].shippingOption"> - {{el.labels[0].shippingOption}}</span>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="width:12%; color:transparent;border-top:hidden">{{el.shippingOption}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM' || isEarlierthanYesterday(i) " *ngIf="j==0" style="width:5%;">{{el.readyAt| date:'MM/dd/yy HH:mm'}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM' || isEarlierthanYesterday(i) " *ngIf="j>0" style="width:5%;color:transparent;border-top:hidden">{{el.readyAt| date:'MM/dd/yy HH:mm'}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
        <span *ngIf="el.packingSlipPrint">
          <a [routerLink]="['/task',el.customer,el.packingSlipPrint]">{{el.packingSlipPrint}}</a>
        </span>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">
        <span *ngIf="el.packingSlipPrint">
          {{el.packingSlipPrint}}
        </span>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0" style="width:5%;">{{el.packingSlipPrintAt | date:'MM/dd/yy HH:mm'}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="width:5%;color:transparent;border-top:hidden">{{el.packingSlipPrintAt | date:'MM/dd/yy HH:mm'}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0" style="width:16%;">
        <ul *ngFor="let log of el.logs;">
          <li>
            {{log.content}} ({{log.createdAt | date:'MM/dd/yy HH:mm'}})
          </li>
        </ul>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="width:16%;color:transparent;border-top:hidden">
        <div *ngFor="let log of el.logs;">
        </div>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0" style="width:12%;">
        <ul *ngFor="let memo of el.memoes;">
          <li>
            {{memo.content}} ({{memo.from}} {{memo.createdAt | date:'MM/dd/yy HH:mm'}})
          </li>
        </ul>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="width:12%;color:transparent;border-top:hidden">
      </td>
    </tr>
  </tbody>


</table>
