import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { User } from '@app/_models';
import { Shipping } from '../Class';


@Injectable({ providedIn: 'root' })

export class ShippingService {
    constructor(private http: HttpClient) { }

  cancelOrder(shipping) {
    var url: string = `${environment.apiUrl}/shipping/CanceledUpdate`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(url, shipping, httpOptions);
  }

  closeOrder(shipping) {
    var url: string = `${environment.apiUrl}/shipping/ClosedUpdate`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<Shipping>(url, shipping, httpOptions);
  }

  changeShippingOption(shipping) {
    var url: string = `${environment.apiUrl}/shipping/ShippingOptionChange`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<Shipping>(url, shipping, httpOptions);
  }

  resetPackingSlip(shipping) {
    var url: string = `${environment.apiUrl}/shipping/ResetPackingSlip`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<Shipping>(url, shipping, httpOptions);
  }

  deleteLabel(shipping) {
    var url: string = `${environment.apiUrl}/shipping/DeleteLabel`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<Shipping>(url, shipping, httpOptions);
  }

  excelordercreation(customer, formData) {
    return this.http.post(`${environment.apiUrl}/shipping/filetest?customer=${customer}`, formData);
  }

  deleteOrder(cus: string, no: number) {
   
    var url: string = `${environment.apiUrl}/shipping/DeleteOrder`;
    return this.http.post<object>(url, { customer: cus, orderNo: +no })
  }

  getFulfilledList(form) {
    var url: string = `${environment.apiUrl}/shipping/fulfilledlist`;
    return this.http.post(url, form);
  }

  getHoldList(form) {
    var url: string = `${environment.apiUrl}/shipping/holdlist`;
    return this.http.post(url, form);
  }

 
  getMaxOrderNo(shippingForm) {
    var url: string = `${environment.apiUrl}/shipping/getMaxOrderNo`;
    return this.http.post(url, shippingForm);
  }

  getMaxOrderNo2(cus) {
    return this.http.get(`${environment.apiUrl}/shipping/GetMaxOrderNoFromQuery?customer=` + cus);
  }

  getReorderList(formData) {
    //const currentUser = this.userValue;
    return this.http.post(`${environment.apiUrl}/shipping/ReorderList`, formData);
  }
 
  getRestList(model) {
    console.log(model);
    var url: string = `${environment.apiUrl}/shipping/restlist`;
    return this.http.post(url,model);
  }

  getOrderDetail(cus: string, no: number) {
    var url: string = `${environment.apiUrl}/shipping/orderdetail3/?cus=${cus}&no=${no}`;
    return this.http.get<Shipping>(url);
  }

  getUndeliveredOrders(cus: string, location: string) {
    var url: string = `${environment.apiUrl}/shipping/UndeliveredOrderList/?customer=${cus}&location=${location}`;
    return this.http.get<Shipping>(url);
  }

  getshippingListbyTaskNo(cus, psno, showAll) {
    var url: string = `${environment.apiUrl}/shipping/shippinglistbytasknumber/?cus=${cus}&no=${psno}&showAll=${showAll}`;
    return this.http.get<Shipping[]>(url);
  }

  getShippinginOrderCreation(cus, orderno) {
    var url: string = `${environment.apiUrl}/shipping/GetShippingInOrderCreation`;
    return this.http.post<Shipping>(url, { customer: cus, orderNo: orderno });
  }

  holdMultipleOrders(cus, list) {
    var url: string = `${environment.apiUrl}/shipping/HoldMultipleOrders`;
    return this.http.post(url, {idList: list, Customer: cus });
  }

  memo(form) {
    return this.http.post(`${environment.apiUrl}/shipping/memo`, form);
  }

  reOrderCreation(formdata: object) {
    var url: string = `${environment.apiUrl}/shipping/CreateReOrder`;
    return this.http.post(url, formdata);
  }

  orderCreation(formdata: object) {
    var url: string = `${environment.apiUrl}/shipping/CreateManualOrder`;
    return this.http.post(url, formdata);
  }


  returnMemo(form) {
    return this.http.post(`${environment.apiUrl}/return/memo`, form);
  }

  removeOrderFromTask(cus, orderno) {
    var url: string = `${environment.apiUrl}/shipping/RemoveOrderFromTask`;
    return this.http.post<Shipping>(url, {customer:cus, orderNo: orderno});
  }

  //unhold(model) {
  //  var url: string = `${environment.apiUrl}/shipping/unhold`;
  //  return this.http.post(url, model);
  //}

  unholdMultipleOrders(list) {
    var url: string = `${environment.apiUrl}/shipping/UnHoldMultipleOrdersinIntra3`;
    return this.http.post(url, {cusAndIdList: list});
  }

  updateAddress(shipping) {
    var url: string = `${environment.apiUrl}/shipping/AddressUpdate`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<Shipping>(url, shipping, httpOptions);
  }

  updateItems(shipping) {
    var url: string = `${environment.apiUrl}/shipping/ItemsUpdate`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<Shipping>(url, shipping, httpOptions);

  }

  updateTrackingNumber(shipping) {
    var url: string = `${environment.apiUrl}/shipping/TrackingNumberUpdate`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<Shipping>(url, shipping, httpOptions);
  }

  postOrderDetail(cus: string, no: number, cusno: string) {
    console.log('cus', cus, 'orderNo', no, 'customerno', cusno);
    var url: string = `${environment.apiUrl}/shipping/orderdetail`;
    return this.http.post<Shipping>(url, { customer: cus, orderNo: no, customerNo: cusno });
  }

  changeUspsCarrierService(isEndicia: boolean) {
    var url: string = `${environment.apiUrl}/shipping/ChangeUspsCarrierService`;
    return this.http.post(url, { isEndicia });
  }

  addLog(customer: string, orderno: string, logContent: string) {
    var url: string = `${environment.apiUrl}/shipping/AddLog`;
    return this.http.post(url, { customer, orderno, logContent });
  }

  importGoogleOrder(lineNumber: number) {
    var url: string = `${environment.apiUrl}/shipping/ImportGoogleSheetOrder`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    };
    return this.http.post<any>(url, { lineNumber }, httpOptions).pipe();
  }

  getGoogleSheetOrderLastNumber() {
    var url: string = `${environment.apiUrl}/shipping/GetGoogleSheetOrderLastNumber`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', 
      })
    };
    return this.http.get<{ isSuccess: Boolean, data: { line_number: number, last_updated: Date } }> (url, httpOptions);
  }

  rollbackOrder(model) {
    var url: string = `${environment.apiUrl}/shipping/RollbackOrder`;
    return this.http.post(url, model);
  }
}

class ItemList {
  id: string;
  sku: string;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
}

