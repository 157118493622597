<h1>Re-Order List</h1>

<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>

<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" class="float-left">
  <div class="row" style="padding-bottom:5px; padding-right:10px;">

    <div style="padding-right:15px;">
     
      <select formControlName="customer" class="browser-default custom-select" style="width:120px;font-size:small;">
        <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
    <div style="padding-left:20px;padding-right:20px;">
      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'From'" formControlName="startAt" required></mdb-date-picker>
    </div>
    <div>
      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'To'" formControlName="endAt" required></mdb-date-picker>
    </div>
    <div style="padding-left:15px;padding-right:15px;">
      <button mdbBtn class="btn btn-amber" size="sm" type="submit" mdbWavesEffect>Submit</button>
    </div>
   
  </div>
</form>
<form [formGroup]="memoForm" (ngSubmit)="onMemoSubmit(memoForm.value)" class="row float-right">
  <table>
    <tr>
      <td>
        <input type="text" class="form-control" formControlName="orderNo" placeholder="orderNo">
      </td>
      <td>
        <input type="text" class="form-control" formControlName="content" placeholder="memo">
      </td>
      <td>
        <button mdbBtn class="btn btn-amber" rounded="true" size="sm" type="submit" mdbWavesEffect>Submit</button>
      </td>
      <td>|</td>
      <td>
        <div align="right">
          <button mdbBtn type="button" color="success" rounded="true" (click)="ExportTOExcel()" class="btn btn-success" size="sm" mdbWavesEffect>Excel</button>
        </div>
      </td>
    </tr>
  </table>
</form>


<table class="table table-bordered table-sm table-hover" #TABLE #table>
  <thead>
    <tr align="center">
      <!--<th><input type="checkbox" [(ngModel)]="mastercheck" (click)="masterCheck()" /></th>-->
      <th scope="col">Customer #</th>
      <th scope="col">Order #</th>
      <th scope="col">Location</th>
      <th scope="col">State</th>
      <th scope="col">IsHold</th>
      <th scope="col">Name / Email</th>
      <th scope="col">Items</th>
      <th scope="col">Qty</th>
      <th scope="col">CreatedAt</th>
      <th scope="col">Tracking #</th>
      <th scope="col">Memo</th>
    </tr>
  </thead>
  <tbody *ngFor="let el of elements ; let i = index">
    <tr mdbTableCol *ngFor="let item of el.items; let j = index">
      <!--<td style="text-align:center;">
        <input type="checkbox" [checked]="el.checked" value="el.id" (change)="createCheckedList($event, el.id)" />
      </td>-->
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.customerNo}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:white;border-top:hidden"></td>

      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
        <a [routerLink]="['/individualshipping',el.customer,el.orderNo]">{{el.orderNo}}</a>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:white;border-top:hidden">
        <a></a>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.location}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:white;border-top:hidden"></td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0"> <span *ngIf="el.shipTo">{{el.shipTo.state}}</span></td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:white;border-top:hidden"> <span *ngIf="el.shipTo"></span></td>

      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
        <span *ngIf="el.isHold" class="red-text">{{el.isHold}}</span>
        <span *ngIf="!el.isHold">{{el.isHold}}</span>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:white;border-top:hidden">
        <span *ngIf="el.isHold" class="red-text"></span>
        <span *ngIf="!el.isHold"></span>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
        <span *ngIf="el.shipTo">{{el.shipTo.name}}</span> /
        <span *ngIf="el.shipTo">{{el.shipTo.email}}</span>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:white;border-top:hidden">
        <span *ngIf="el.shipTo"></span><br />
        <span *ngIf="el.shipTo"></span>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'">
        {{item.sku}}
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'">
        {{item.qty}}
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.createdAt| date:'MM/dd/yyyy HH:mm'}}</td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:white;border-top:hidden"></td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
        <div *ngFor="let label of el.labels;">
          &#8203;{{label.trackingNo}}
        </div>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:white;border-top:hidden">
        <div *ngFor="let label of el.labels;">

        </div>
      </td>

      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
        <ul *ngFor="let memo of el.memoes;">
          <li>
            {{memo.content}}
          </li>
        </ul>
      </td>
      <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:white;border-top:hidden">
        <div *ngFor="let memo of el.memoes;">

        </div>
      </td>
    </tr>
  </tbody>
</table>

