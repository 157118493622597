import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
    if(window){
      window.console.log = function () { };
    }
}

if (window) {
  if (window.location.host == 'intra3.awi-usa.com') {
    //window.location.href = `https://intra.awi-usa.com`;
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
