<h1>Vendor Return Receipt List</h1>

<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>
<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" class="float-left">
  <div class="row" style="padding-bottom:5px; padding-right:10px;">

    <div style="padding-right:15px;">
      <select formControlName="location" class="browser-default custom-select" style="width: 100px;font-size: small;">
        <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
    <div>
      <select formControlName="customer" class="browser-default custom-select" style="width:120px;font-size:small;">
        <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>

    <div style="padding-left:15px;padding-right:15px;">
      <button mdbBtn class="btn btn-amber" size="sm" type="submit" mdbWavesEffect>Submit</button>
    </div>
    <div style="padding-top:10px;" [hidden]="!total">
      Total: {{total}}
    </div>
  </div>
  
</form>
<div align="right">
  <button mdbBtn type="button" color="success" rounded="true" (click)="ExportTOExcel()" class="btn btn-success" size="sm" mdbWavesEffect>Excel</button>
</div>
<table class="table table-bordered table-sm" style="margin-top: 10px;" #TABLE #table>
  <thead>
    <tr align="center">
      <th scope="col">Location</th>
      <th scope="col">RMA #</th>
      <th>Tracking #</th>
      <th>SKU</th>
      <th>Receipt Qty</th>
      <th>Shelf Name</th>
      <th>Note</th>
      <th>Expriation Date</th>
      <th>Created By</th>
      <th scope="col">Created At</th>
    </tr>
  </thead>

  <tbody *ngFor="let el of elements; let i = index">
    <tr mdbTableCol *ngFor="let item of el.items; let j = index">
      <td *ngIf="j==0">{{el.location}}</td>
      <td *ngIf="j>0" style="color:white;border-top:hidden">{{el.location}}</td>
      <td *ngIf="j==0">
        <a [routerLink]="['/return', el.location, el.receivedNo]">{{el.receivedNo}}</a>
      </td>
      <td *ngIf="j>0" style="color:white;border-top:hidden">{{el.receivedNo}}</td>
      <td *ngIf="j==0">{{el.trackingNo}}</td>
      <td *ngIf="j>0" style="color:white;border-top:hidden">{{el.trackingNo}}</td>
      <td>{{item.sku}}</td>
      <td>{{item.receiptQty}}</td>
      <td>{{item.shelfName}}</td>
      <td>{{item.note}}</td>
      <td>{{item.expirationDate | date:'MM/dd/yyyy'}}</td>
      <td *ngIf="j==0">{{el.createdBy}}</td>
      <td *ngIf="j>0" style="color:white;border-top:hidden">{{el.createdBy}}></td>
      <td *ngIf="j==0">{{el.createdAt | date:'MM/dd/yyyy HH:mm'}}</td>
      <td *ngIf="j>0" style="color:white;border-top:hidden">{{el.createdAt | date:'MM/dd/yyyy HH:mm'}}</td>
    </tr>
  </tbody>


</table>
