<h1>Undelivered-Orders</h1>
<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>
<form [formGroup]="mainForm" (ngSubmit)="onSubmit(mainForm.value)" class="float-left">
  <div class="row" style="padding-bottom:5px; padding-right:10px;">

    <div style="padding-right:15px;">
      <select formControlName="location" class="browser-default custom-select" style="width: 100px;font-size: small;">
        <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
    <div>
      <select formControlName="customer" class="browser-default custom-select" style="width:120px;font-size:small;">
        <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
    <!--<div style="padding-left:20px;padding-right:10px;">
      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'From'" formControlName="startAt" required></mdb-date-picker>
    </div>
    <div>
      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'To'" formControlName="endAt" required></mdb-date-picker>
    </div>-->
    <div style="padding-left:15px;padding-right:15px;">
      <button mdbBtn class="btn btn-amber" size="sm" type="submit" mdbWavesEffect>Submit</button>
    </div>
    <div style="padding-top:10px;" [hidden]="!total">
      Total: {{total}}
    </div>
  </div>
</form>

<div align="right">
  <button mdbBtn type="button" color="light-blue" rounded="true" class="btn btn-success" size="sm" mdbWavesEffect><mdb-icon fas icon="filter"></mdb-icon>Filter</button>
  <button mdbBtn type="button" color="success" rounded="true" (click)="ExportTOExcel()" class="btn btn-success" size="sm" mdbWavesEffect>Excel</button>
</div>


<table class="table table-bordered table-sm" style="margin-top: 10px;" #TABLE #table>
  <thead>
    <tr align="center">
      <th scope="col">Customer #</th>
      <th scope="col">Order #</th>
      <th scope="col">Location</th>
      <th scope="col">ShippingOption</th>
      <th scope="col">State</th>
      <th scope="col">TrackingNo</th>
      <th scope="col">Status</th>
      <!--<th scope="col">ActivityOn</th>-->
      
      <th scope="col">En-route Days</th>
    </tr>
  </thead>
  <tbody *ngFor="let el of elements; let i = index">
    <tr mdbTableCol>
      <td>{{el.customerNo}}</td>
      <td>
        <a [routerLink]="['/individualshipping',el.customer,el.orderNo]">{{el.orderNo}}</a>
      </td>
      <td>{{el.location}}</td>
      <td>
        <div *ngFor="let label of el.labels">
          {{label.carrier}} - {{label.shippingOption}}
        </div>
      </td>
      <td>{{el.shipTo.state }}</td>
      <td>
        <div *ngFor="let label of el.labels; ">
          <a *ngIf="label.carrier=='Endicia'" target="_blank" href="https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels={{label.trackingNo}}%2C">&#8203;{{label.trackingNo}}</a>
          <a *ngIf="label.carrier=='FedEx'" target="_blank" href="https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber={{label.trackingNo}}&cntry_code=us&locale=en_US">&#8203;{{label.trackingNo}}</a>
          <a *ngIf="label.carrier=='UPS'" target="_blank" href="https://www.ups.com/track?loc=en_US&tracknum={{label.trackingNo}}&requester=WT/trackdetails">&#8203;{{label.trackingNo}}</a>
        </div>
      </td>
      <td>
        <div *ngFor="let label of el.labels">
          {{lastTrackingInfo(label.tracks)}}
        </div>
      </td>
      <!--<td>
        <div *ngFor="let label of el.labels">
          {{activityOn(label.tracks)}}
        </div>
      </td>-->
      <td>
        <div *ngFor="let label of el.labels">
          {{countingEnRouteDays(label.tracks)}}
        </div>
      </td>

    </tr>
  </tbody>
 
</table>
