import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import { User, Role } from '../_models';
import { ShippingService } from '../_services/shipping.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})


export class NavMenuComponent {
  // @ViewChild('sidenav', { static: true }) sidenav: SidenavComponent
  user: User;
  uspsCarrier: boolean;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private shippingService: ShippingService,
  ) {
    //this.authenticationService.user.subscribe(x => this.currentUser = x);
    this.authenticationService.ping();
    
  }

  ping() {
    this.authenticationService.ping();
  }

  ngOnInit() {
    this.authenticationService.user.subscribe(x => {
      this.user = x;
    });
  }
  get isAdmin() {
    if (this.user.username == 'min@awi-usa.com'
      || this.user.username == 'jkoo@awi-usa.com'
      || this.user.username == 'dev@awi-usa.com') {
      return true;
    }
    return this.user && this.user.role === Role.Admin;
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login'], { queryParams: { redirectUrl: location.pathname } });
  }

  onCarrierChange(event) {
    //const carrier = event.target.checked ? "Endicia" : "Pitney Bowes";
    //if (confirm(`Do you really want to change the carrier service to ${carrier}?`)) {
      
    //  return true;
    //} else {
    //  return false;
    //}
    this.shippingService.changeUspsCarrierService(event.target.checked).subscribe(data => {
    });;
  }
}
