import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { ItemService } from '../../_services/item.service';
import { ShippingService } from '../../_services/shipping.service';
import { TaskService } from '../../_services/task.service';
import * as XLSX from 'xlsx';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { AuthenticationService } from '../../_services';

@Component({
  templateUrl: './reorder-list.component.html',
  styleUrls: ['./reorder-list.component.scss']
})

export class ReorderListComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ReorderList.xlsx');
  }

  public myDatePickerOptions: IMyOptions = {
    firstDayOfWeek: 'su' // 'mo' - Monday, 'tu' - Tuesday, 'we' - Wednesday, 'th' - Thursday, 'fr' - Friday, 'sa' - Saturday, 'su' - Sunday
  };

  mainForm;
  memoForm;
  elements: any = [];
  customers: string[] = AuthenticationService.customers;
  isLoading: boolean = false;
  user: User;
  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,
    private itemService: ItemService, private shippingService: ShippingService,
    private taskService: TaskService, private activatedRoute: ActivatedRoute, private authenticationService: AuthenticationService,
    private location: Location) {
    this.user = this.authenticationService.userValue;
    this.authenticationService.getCustomer().then(x => this.customers = x);
    activatedRoute.paramMap.subscribe(params => {
      this.mainForm = fb.group({
        customer: params.get('customer') ? params.get('customer') : this.user.customer,
        location: params.get('location') ? params.get('location') : this.user.location,
        startAt: new Date(new Date().setHours(0, 0, 0, 0)),
        endAt: new Date(new Date().setHours(0, 0, 0, 0)),
      });
      this.onSubmit();
    });
    this.memoInit();
  }

 
  memoInit() {
    this.memoForm = this.fb.group({
      customer: this.mainForm.get('customer').value ,
      orderNo: '',
      content: ''
    });
  }


  onMemoSubmit(customerData) {
    this.memoForm.get('customer').setValue(this.mainForm.get('customer').value);

    if (this.memoForm.valid) {
      this.shippingService.memo(this.memoForm.value).subscribe(data => {
        //console.log(data);

        if (data["isSuccess"]) {
          this.memoInit();
          this.onSubmit();
        }
        else {
          alert(data["errorMsg"]);
        }
      });
    }
    else {
      alert(this.memoForm.status);
    }
    this.memoInit();
    //this.checkoutForm.reset();
  }


  onSubmit() {
    this.isLoading = true;
    this.shippingService.getReorderList(this.mainForm.value)
      .subscribe(data => {
        this.isLoading = false;
        this.elements = data;
        console.log('done search');
      });
  }

}
