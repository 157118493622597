<h1>Shipment List</h1>

<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>

<form [formGroup]="mainForm" (ngSubmit)="onSubmit(mainForm.value)">
  <div class="row">
    <div class="col-xl-1 col-lg-4 col-md-12" style="padding-top:5px;">
      <select formControlName="customer" class="browser-default custom-select" style="width:120px;font-size:small; ">
        <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
    <div class="col-xl-1 col-lg-4 col-md-12">
      <button mdbBtn class="btn btn-amber" size="sm" type="submit" mdbWavesEffect>Submit</button>
    </div>
  </div>
</form>


<table class="table table-bordered table-sm" style="margin-top: 10px;" #TABLE #table>
  <tr align="center">
    <th></th>
    <th>Reference #</th>
    <th>Carrier</th>
    <th style="width: 13%;">Shipping Option</th>
    <th style="width: 3%;">ShipFrom</th>
    <th>ShipTo</th>
    <th>Tracking #</th>
    <th style="display:none;">Transaction Id</th>
    <th>Price</th>
    <th style="width: 10%;">Box dimensions (lb/L/W/H)</th>
    <th>Created By</th>
    <th>Created At</th>
    <th>Actions</th>

  </tr>
  <tr mdbTableCol *ngFor="let lb of labels ; let i = index">
    <td>{{i+1}}</td>
    <td>{{lb.referenceNo}}</td>
    <td>
      {{lb.carrier}}
    </td>
    <td>
      {{lb.shippingOption}}
    </td>
    <td>{{lb.shipFrom.state}}</td>
    <td>
      {{lb.shipTo.address1}}
      <span *ngIf="lb.shipTo.address2">, {{lb.shipTo.address2}}</span>
      ,{{lb.shipTo.city}}, {{lb.shipTo.state}} {{lb.shipTo.zip}}
    </td>
    <td>
      <div *ngFor="let l of lb.labels">
        <div *ngIf="l.price != 0">
          &#8203;{{l.trackingNo}}
        </div>
      </div>
    </td>
    <td style="display:none;">
      <div *ngFor="let l of lb.labels">
        <div *ngIf="l.price != 0">
          {{l.transactionID}}
        </div>
      </div>
    </td>
    <td>
      <div *ngFor="let l of lb.labels">
        <div *ngIf="l.price != 0">
          {{l.price}}
        </div>
      </div>
    </td>
    <td>
      <div *ngFor="let package of lb.boxDimensions;">
        {{package.weight}} / {{package.length}} / {{package.width}} / {{package.height}}
      </div>
    </td>
    <td>
      <div *ngFor="let l of lb.labels; let j = index">
        {{l.createdBy}}
      </div>
    </td>
    <td>
      <div *ngFor="let l of lb.labels; let j = index">
        {{l.createdAt | date:'MM/dd/yyyy HH:mm'}}
      </div>
    </td>

    <td>
      <button *ngIf="lb.labels" (click)="rePrintZpl(lb.referenceNo)">Zpl</button>
      <button *ngIf="lb.labels" (click)="rePrintPdf(lb.referenceNo)">PDF</button>

    </td>

  </tr>
</table>
