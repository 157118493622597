<h1>Hold Orders</h1>

<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>
<div align="right">
  <button mdbBtn type="button" color="blue-grey" rounded="true" class="btn btn-success" size="sm" (click)="unHoldMultipleOrders()" mdbWavesEffect>Unhold Selected Items</button>
</div>


  <table class="table table-bordered table-sm table-hover">
    <thead>
      <tr align="center">
        <th>
          <input type="checkbox" [(ngModel)]="mastercheck" (click)="masterCheck()" />
        </th>
        <th scope="col">OrderNo</th>
        <th scope="col">Customer</th>
        <th scope="col">Paid</th>
        <th scope="col">Fulfilled</th>
        <th scope="col">Risk Level</th>
        <th scope="col">IsHold</th>
        <th scope="col">Name / E-mail</th>
        <th scope="col">Items</th>
        <th scope="col">Price</th>
        <th scope="col">State</th>
        <th scope="col">OrderAt</th>
        <th scope="col">PaidAt</th>
        <!--<th scope="col">button</th>-->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let el of elements; let i = index">
        <td style="text-align:center;">
          <input type="checkbox" [checked]="el.checked" value="el.id" (change)="createCheckedList($event, el.customer, el.id)" />
        </td>
        <td>{{el.orderNo}}</td>
        <td>{{el.customer}}</td>
        <td>{{el.paid}}</td>
        <td>{{el.fulfilled}}</td>
        <td>{{el.riskLevel}}</td>
        <td>
          <span *ngIf="el.isHold" class="red-text">{{el.isHold}}</span>
          <span *ngIf="!el.isHold">{{el.isHold}}</span>
        </td>
        <td>
          <span *ngIf="el.shipTo">{{el.shipTo.name}}</span><br />
          <span *ngIf="el.shipTo">{{el.shipTo.email}}</span>
        </td>
        <td>
          <div *ngFor="let item of el.items;">
            {{item.sku}} ({{item.qty}})
          </div>
        </td>
        <td>{{el.totalPrice }}</td>
        <td>{{el.shipTo.state }}</td>
        <td>{{el.orderAt | date:'MM/dd/yyyy HH:mm'}}</td>
        <td>{{el.paidAt | date:'MM/dd/yyyy HH:mm'}}</td>
        <!--<td>
        <button mdbBtn type="button" class="btn-sm" color="mdb-color" mdbWavesEffect (click)="unHold(el.customer, el.orderNo)">UnHold</button>
      </td>-->
      </tr>
    </tbody>
  </table>

  <div mdbModal #centralRegular="mdb-modal" class="modal fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: false}">
    <div class="modal-dialog " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title w-100" id="myModalLabel">Modal title</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="centralRegular.hide()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          Do you want to unhold?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-sm waves-light" data-dismiss="modal" (click)="centralRegular.hide()" mdbWavesEffect>Close</button>
          <button type="button" class="btn btn-primary btn-sm waves-light" mdbWavesEffect>UnHold</button>
        </div>
      </div>
    </div>
  </div>

  



