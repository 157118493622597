<div *ngIf="isLoading">
  <div style="z-index:10000;position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="z-index:10000;position:absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>

<h1 align="center">Smart Return</h1>
<div class="row">
  <div class="col"></div>
  <div class="col">
    <div *ngIf="!shipping && !receiving">
      <div class="form-group">
        <h5>RMATicket Available</h5>
        <label>Location</label>
        <select class="browser-default custom-select" [(ngModel)]="location" style="margin-bottom:.8rem;">
          <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
        </select>
        <label>RMA# or Received #</label>
        <input mdbInput class="form-control" type="text" style="width:100%;" (keyup.enter)="onBarcodeChange($event.target.value)" placeholder="Scan....." #input3 />
      </div>
      <hr />
      <div class="form-group">
        <h5>RMATicket Unavailable</h5>
        <label>Customer</label>
        <select class="browser-default custom-select" [(ngModel)]="customer" style="margin-bottom:.8rem;">
          <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
        </select>
        <label>Location</label>
        <select class="browser-default custom-select" [(ngModel)]="location">
          <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
        </select>
        <button mdbBtn class="btn btn-amber" size="sm" style="margin-top: 20px;" (click)="onSubmit()" mdbWavesEffect>Submit</button>
      </div>
    </div>
  </div>
  <div class="col"></div>
</div>


<div *ngIf="shipping">
  <div class="row">
    <div class="col-lg-12">
      <h5>Customer: {{shipping.customer}}, OrderNo: {{shipping.originalNo}}, CustomerNo:{{shipping.originalCustomerNo}}</h5>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <h5>
        ORIGINAL ORDER INFO ({{shipping.originalNo}})
      </h5>
    </div>
    <div class="col-lg-2">
      <input type="text" class="form-control" [(ngModel)]="barcodeValue" (keyup.enter)="onSkuChangewithNothing($event.target.value)" placeholder="Item barcorde" #input1>
    </div>
  </div>


  <div class="row" style="padding-top:25px;">
    <div class="col-lg-12">
      <table class="table table-bordered table-condensed">
        <thead>
          <tr>
            <th></th>
            <th>SKU</th>
            <th>DESCRIPTION</th>
            <th>ORIGINAL ORDER QUANTITY</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of shipping.oriItems; let i = index">
            <td align="center">{{i+1}}</td>
            <td>{{item.sku}}</td>
            <td>{{item.desc}}</td>
            <td>{{item.qty}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!--<div *ngIf="shipping.returnRequest">
    <h5>
      RETURN REQUEST FROM CUSTOMER
    </h5>

    <div class="row" style="padding-top:25px;">
      <div class="col-lg-12">
        <table class="table table-bordered table-condensed">
          <thead>
            <tr>
              <th></th>
              <th>SKU</th>
              <th>DESCRIPTION</th>
              <th>RECEIPT QUANTITY</th>
              <th>RETURN CODE</th>
              <th>NOTES</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of iteminputs; let i = index">
              <td align="center">{{i+1}}</td>
              <td>{{item.sku}}</td>
              <td>{{item.desc}}</td>
              <td><input type="number" [(ngModel)]="item.receiptqty" /></td>
              <td>
                <select [(ngModel)]="item.grade">
                  <option *ngFor="let opt of retruncodes" [ngValue]="opt">{{opt}}</option>
                </select>
              </td>
              <td><input type="text" [(ngModel)]="item.note" /></td>
              <td><button (click)="deleteRow(i)">Delete</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>-->

  <div *ngIf="shipping.trackingNo">
    Tracking #: {{shipping.trackingNo}}
  </div>

  <div class="row" style="padding-top:25px;">
    <div class="col-lg-12">
      <table class="table table-bordered table-condensed">
        <thead>
          <tr>
            <th></th>
            <th>SKU</th>
            <th>DESCRIPTION</th>
            <th>REQUESTED  <br />RETURN QTY</th>
            <th>RECEIPT QTY</th>
            <th>RETURN CODE</th>
            <th>NOTES</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of shipping.items; let i = index">
            <td align="center">{{i+1}}</td>
            <td>{{item.sku}}</td>
            <td>{{item.desc}}</td>
            <td>{{item.qty}}</td>
            <td><input type="number" [(ngModel)]="item.receiptQty" /></td>
            <td>
              <select [(ngModel)]="item.grade">
                <option *ngFor="let opt of retruncodes" [ngValue]="opt">{{opt}}</option>
              </select>
            </td>
            <td><input type="text" [(ngModel)]="item.note" /></td>
            <td><button (click)="deleteRow(i)">Delete</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


  <!--<h5>
    ITEMS TO BE RECEIVED
  </h5>

  <div class="row" style="padding-top:25px;">
    <div class="col-lg-12">
      <table class="table table-bordered table-condensed">
        <thead>
          <tr>
            <th></th>
            <th>SKU</th>
            <th>DESCRIPTION</th>
            <th>RECEIPT QUANTITY</th>
            <th>RETURN CODE</th>
            <th>NOTES</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of iteminputs; let i = index">
            <td align="center">{{i+1}}</td>
            <td>{{item.sku}}</td>
            <td>{{item.desc}}</td>
            <td><input type="number" [(ngModel)]="item.receiptqty" /></td>
            <td>
              <select [(ngModel)]="item.grade">
                <option *ngFor="let opt of retruncodes" [ngValue]="opt">{{opt}}</option>
              </select>
            </td>
            <td><input type="text" [(ngModel)]="item.note" /></td>
            <td><button (click)="deleteRow(i)">Delete</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>-->
  

  iv


  <div class="row">
    <div class="col-lg-12">

      <span>
        <button mdbBtn class="btn btn-default btn-sm" type="button" color="mdb-color" (click)="onExit()" mdbWavesEffect>Exit</button>
      </span>
      <!--<span [hidden]="shipping.completedAt">
        <button mdbBtn class="btn btn-default btn-sm" type="button" color="mdb-color" (click)="onCompletedwithRMA()" mdbWavesEffect>Create</button>
      </span>-->
      <!--<span [hidden]="!shipping.items">
        <button mdbBtn class="btn btn-default btn-sm" type="button" color="mdb-color" (click)="onUpdate()" mdbWavesEffect>Update</button>
      </span>-->
      <span [hidden]="shipping.completedAt">
        <button mdbBtn class="btn btn-default btn-sm" type="button" color="mdb-color" (click)="confirm(shipping.receivedNo)" mdbWavesEffect>Confirm</button>
      </span>
      <span [hidden]="shipping.completedAt || shipping.reassignedAttentionAt">
        <button mdbBtn class="btn btn-default btn-sm" type="button" color="mdb-color" (click)="attention(shipping.receivedNo)" mdbWavesEffect>Attention</button>
      </span>
    </div>
  </div>

</div>

<div *ngIf="receiving">
  <div class="row">
    <div class="col-lg-12">
      <h5>Customer: {{receiving.customer}}, OrderNo: Not available</h5>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <h5>
        ITEMS TO BE RECEIVED ({{receiving.originalNo}})
      </h5>
    </div>
    <div class="col-lg-2">
      <input type="text" class="form-control" [(ngModel)]="barcodeValue" (keyup.enter)="onSkuChangewithoutRMA($event.target.value)" placeholder="Item barcorde" #input2>
    </div>
  </div>

  <div class="row" style="padding-top:25px;">
    <div class="col-lg-12">
      <table class="table table-bordered table-condensed">
        <thead>
          <tr>
            <th></th>
            <th>SKU</th>
            <th>DESCRIPTION</th>
            <th>RECEIPT QUANTITY</th>
            <th>RETURN CODE</th>
            <th>NOTES</th>
            <th style="width: 10%;">EXPIRATION DATE</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of iteminputs; let i = index">
            <td>{{i+1}}</td>
            <td>{{item.sku}}</td>
            <td>{{item.desc}}</td>
            <td><input type="number" [(ngModel)]="item.receiptQty" /></td>
            <td>
              <select [(ngModel)]="item.grade">
                <option *ngFor="let opt of retruncodes" [ngValue]="opt">{{opt}}</option>
              </select>
            </td>
            <td><input type="text" [(ngModel)]="item.note" /></td>
            <td>
              <div>
                <mdb-date-picker [inline]="true" [options]="myDatePickerOptions" [placeholder]="'Expiration Date'" [(ngModel)]="item.expirationDate" required></mdb-date-picker>
              </div>
            </td>
            <td><button (click)="deleteRow(i)">Delete</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-12">

      <span>
        <button mdbBtn class="btn btn-default btn-sm" type="button" color="mdb-color" (click)="onExit()" mdbWavesEffect>Exit</button>
      </span>
      <span [hidden]="receiving.completedAt">
        <button mdbBtn class="btn btn-default btn-sm" type="button" color="mdb-color" (click)="confirmwithReceiving(receiving.receivedNo)" mdbWavesEffect>Confirm & Exit</button>
      </span>
      <span [hidden]="receiving.completedAt">
        <button mdbBtn class="btn btn-default btn-sm" type="button" color="mdb-color" (click)="confirmAndKeepScanning(receiving.receivedNo)" mdbWavesEffect>Confirm & Keep Scanning</button>
      </span>
      <span [hidden]="receiving.completedAt || receiving.reassignedAttentionAt">
        <button mdbBtn class="btn btn-default btn-sm" type="button" color="mdb-color" (click)="attentionwithReceiving(receiving.receivedNo)" mdbWavesEffect>Attention</button>
      </span>


    </div>
  </div>
</div>


