import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { User } from '@app/_models';


@Injectable({ providedIn: 'root' })

export class InventoryService {
  constructor(private http: HttpClient) { }

  getInventoryByCustomer(form) {
    return this.http.post(`${environment.apiUrl}/inventory/GetInventoryBySku`, form);
  }

  getBoxInventory(form) {
    return this.http.post(`${environment.apiUrl}/Box/GetBoxInventory`, form);
  }
  
}

