import { Component, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '@app/_models';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { UserService, AuthenticationService, InventoryService } from '@app/_services';
import { StatisticsService } from '../_services/statistics.service';
import { ChartsModule, ChartSimpleModule, WavesModule } from 'ng-uikit-pro-standard';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import * as moment from 'moment';



@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss']})
export class HomeComponent {
  @ViewChild('content', { static: true }) content: ElementRef; 


  loading = false;
  user: User;
  timeout: string;
  mainForm;
  customers: string[] = AuthenticationService.customers;
  statInSEC: [any] = [{}];
  statInCAS: any;
  inventoryInSEC;
  inventoryInCAS;
  boxInventory;
  public myDatePickerOptions: IMyOptions = {
    firstDayOfWeek: 'su' // 'mo' - Monday, 'tu' - Tuesday, 'we' - Wednesday, 'th' - Thursday, 'fr' - Friday, 'sa' - Saturday, 'su' - Sunday
  };

  constructor(
    //private userService: UserService,
    private fb: FormBuilder, private activatedRoute: ActivatedRoute,
    private statisticsService: StatisticsService,
    private authenticationService: AuthenticationService,
    private inventoryService: InventoryService
  ) {
    this.user = this.authenticationService.userValue;
    this.authenticationService.getCustomer().then(x => this.customers = x);
    this.getTimeoutOnClick();
    activatedRoute.paramMap.subscribe(params => {
      this.mainForm = fb.group({
        customer: params.get('customer') ? params.get('customer') : 'Krave',
        endAt: new Date(),
        startAt: this.setDate(),
      });
    });
    this.onSubmit();
    this.getInventory();
    console.log(this.mainForm.value);

  }

  exportPDF() {
    let DATA = this.content.nativeElement;
    let doc = new jsPDF("l", "mm", "a4");
   
    html2canvas(DATA).then(canvas => {
      var image = canvas.toDataURL('image/png');
      var imgProps = doc.getImageProperties(image);
      var width = doc.internal.pageSize.getWidth();
      var height = (imgProps.height * width) / imgProps.width;

      doc.addImage(image, 'png', 0, 0, width, height);
      doc.save('dashboard.pdf');
    });
    
  }

 
  onSubmit() {
    var startAt = moment(this.mainForm.get('startAt').value).format('YYYYMMDD');
    var endAt = moment(this.mainForm.get('endAt').value).format('YYYYMMDD');
    this.statisticsService.getFulfillmentReport(this.mainForm.get('customer').value, startAt, endAt)
      .subscribe(res => {
        this.statInSEC = res['secStat'];
        this.statInCAS = res['casStat'];
        console.log(res);
        this.chartDatasetsSEC = [{ data: this.statInSEC.map(x => parseInt(x.fulfilledRate)), label: 'Fulfillment Rate (%)' },
          { data: this.statInSEC.map(x => parseInt(x.turnAroundTime)), label: 'Avg Hours' },
        ];
        this.chartLabelsSEC = this.statInSEC.map(x => this.dateFormater(x.date));
        this.chartDatasetsCAS = [{ data: this.statInCAS.map(x => parseInt(x.fulfilledRate)), label: 'Fulfillment Rate (%)' },
          { data: this.statInCAS.map(x => parseInt(x.turnAroundTime)), label: 'Avg Hours' },
        ];
        this.chartLabelsCAS = this.statInCAS.map(x => this.dateFormater(x.date));
        this.getInventory();
        this.getBoxInventory();
    });
  }

  getInventory() {
    this.inventoryInSEC = [];
    this.inventoryInCAS = [];
    this.inventoryService.getInventoryByCustomer(this.mainForm.value).subscribe(res => {
      let result = res["result"];
      this.inventoryInSEC = result.inventoryInSEC;
      this.inventoryInCAS = result.inventoryInCAS;
    })
  }

  getBoxInventory() {
    this.inventoryService.getBoxInventory(this.mainForm.value).subscribe(data => {
      this.boxInventory = data as any[];
    });
  }

  dateFormater(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  public chartType: string = 'line';
  public chartDatasetsSEC: Array<any> = [  ];
  public chartDatasetsCAS: Array<any> = [  ];
  public chartLabelsSEC: Array<any> = [];
  public chartLabelsCAS: Array<any> = [];

  public chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255,99,132,1)',
      borderWidth: 2,
    }
    ,{
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 2,
    }
  ];
  public chartOptions: any = {
    responsive: true
  };
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }


  setDate(): Date {
    var now = new Date();
    var startAt = new Date(new Date().setDate(new Date().getDate() - 4));
    var result = new Date(new Date().setDate(new Date().getDate() - 4));

    for (var d = startAt; d <= now; d.setDate(d.getDate() + 1)) {
      if (d.getDay() == 6 || d.getDay() == 0) {
        result.setDate(result.getDate() - 1);
      }
    }
    return result;
  }

 

  ngOnInit() {
    this.loading = true;
  }

  

  getTimeoutOnClick() {
    console.log('this.authenticationService.tokenTimeOut', this.authenticationService.tokenTimeOut);
    this.timeout = this.authenticationService.tokenTimeOut;
  }

  refreshToken() {
    this.authenticationService.refreshTokenTimer();
  }
}
