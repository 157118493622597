import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { Location } from '@angular/common';
import { StatisticsService } from '../_services/statistics.service';
import * as XLSX from 'xlsx';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { AuthenticationService } from '../_services';
import * as moment from 'moment';


@Component({
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})

export class StatisticsComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ReturnList.xlsx');
  }

  public myDatePickerOptions: IMyOptions = {
    firstDayOfWeek: 'su' // 'mo' - Monday, 'tu' - Tuesday, 'we' - Wednesday, 'th' - Thursday, 'fr' - Friday, 'sa' - Saturday, 'su' - Sunday
  };

  mainForm;
  elements: any = [];
  total: number;
  locations: string[] = ["All", "AWISEC", "AWICAS"];
  customers: string[] = AuthenticationService.customers;
  
  statInSEC: any;
  statInCAS: any;
  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,
    private statisticsService: StatisticsService, private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService, private location: Location) {
    this.authenticationService.getCustomer().then(x => this.customers = x);
  }

  ngOnInit() {
    var startAt = new Date(new Date().setHours(0, 0, 0, 0))
    startAt.setMonth(startAt.getMonth() - 1);

    this.activatedRoute.paramMap.subscribe(params => {
      this.mainForm = this.fb.group({
        customer: params.get('customer') ? params.get('customer') : 'Krave',
        endAt: new Date(),
        startAt: this.setDate(),
      });
    });

    this.onSubmit();
  }

  setDate(): Date {
    var now = new Date();
    var startAt = new Date(new Date().setDate(new Date().getDate() - 4));
    var result = new Date(new Date().setDate(new Date().getDate() - 4));

    for (var d = startAt; d <= now; d.setDate(d.getDate() + 1)) {
      if (d.getDay() == 6 || d.getDay() == 0) {
        result.setDate(result.getDate() - 1);
      }
    }
    return result;
  }

  avgOneDayTaskHours() {
    var startAt = moment(this.mainForm.get('startAt').value).format('YYYYMMDD');
    var endAt = moment(this.mainForm.get('endAt').value).format('YYYYMMDD');
    this.statisticsService.getFulfillmentReport(this.mainForm.get('customer').value, startAt, endAt).subscribe(data => {
      this.statInSEC = data['secStat'];
      this.statInCAS = data['casStat'];
      //this.total = this.statInCAS.length + this.statInSEC.length;
      console.log(data);
      console.log(this.statInSEC);
      console.log(this.statInCAS);
    });
  }

  onSubmit() {
    this.avgOneDayTaskHours();
  }

  dateFormater(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }
 

}



