import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { BoxService } from '../../_services/box.service';
import { AuthenticationService } from '../../_services';

@Component({ templateUrl: './box-list.component.html' })

export class BoxListComponent {
  mainForm;
  customers: string[] = AuthenticationService.customers;
  boxlist: Box[];
  isLoading: boolean = false;
  user: User;

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,
    private authenticationService: AuthenticationService,
    private boxService: BoxService) {
    this.user = this.authenticationService.userValue;
    this.authenticationService.getCustomer().then(x => this.customers = x);
    this.mainForm = fb.group({
      customer: this.user.customer
    });
    this.onSubmit(this.mainForm);
  }

  onSubmit(formData) {
    this.isLoading = true;
    this.boxService.getBoxList(this.mainForm.get('customer').value)
      .subscribe(data => {
        this.isLoading = false;
        console.log(data);
        this.boxlist = data;
      });
  }

  edit(index): void {
    this.boxlist[index].isEditable = !this.boxlist[index].isEditable;
  }

  add(): void {
    var temp = new Box;
    temp.isEditable = true;
    this.boxlist.unshift(temp);
  }

  delete(index) {
    this.boxService.deleteBox(this.boxlist[index]).subscribe(data => {
      alert('successfully deleted.');
    });
    this.onSubmit(this.mainForm);
  }

  update(index): void {
    this.isLoading = true;
    this.boxlist[index].isEditable = !this.boxlist[index].isEditable;
    this.boxlist[index].name = this.boxlist[index].name.trim();

    if (this.boxlist[index].customer == null) { //add
      console.log(this.boxlist[index].name);
      this.boxlist[index].customer = this.mainForm.get('customer').value;
      this.boxService.addBox(this.boxlist[index]).subscribe(data => {
        this.isLoading = false;
        alert('successfully added');
        this.onSubmit(this.mainForm);
      });
    } else { //update
      console.log(this.boxlist[index].name);
      this.boxService.updateBox(this.boxlist[index]).subscribe(data => {
        this.isLoading = false;
        alert('successfully updated.');
        this.onSubmit(this.mainForm);
      });
    }

  }

}

class Box {
  id: string;
  type: string;
  name: string;
  width: number;
  height: number;
  length: number;
  weight: number;
  customer: string;
  isEditable: boolean;
  createdby: string;
  updatedby: string;
}
