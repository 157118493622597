
<div class="loader-container" *ngIf="isLoading">
  <div class="bg"></div>
  <div class="loader-img"><img src="/assets/images/loader.svg" /></div>
</div>
<div class="container" #container>
  
  <h3 style="font-weight:700">Shipping Label Generator</h3>
  <div align="right"><button (click)="gotoList()">Label Print History</button></div>
  <div><h5 style="font-weight:500">1. Internal Reference</h5></div>

  <!--<button (click)="printzpltest()">print test</button>-->
  <table class="table table-bordered table-sm fixed detail">
    <tr>
      <td scope="col">Billing Account *</td>
      <td>
        <input type="text" list="accountlist" name="opt" [(ngModel)]="labelForm.billingaccount" id="billing_account" />
        <datalist id="accountlist">
          <select>
            <option *ngFor="let opt of billingaccounts" [value]="opt">{{opt}}</option>
          </select>
        </datalist>
      </td>
    </tr>
    <tr>
      <td scope="col">Customer *</td>
      <td>
        <input type="text" list="customerlist" name="customer" [(ngModel)]="labelForm.customer" id="billing_customer" />
        <datalist id="customerlist">
          <select>
            <option *ngFor="let customer of customers" [value]="customer">{{customer}}</option>
          </select>
        </datalist>

        <!--<select [(ngModel)]="labelForm.customer" id="billing_customer">
          <option *ngFor="let customer of customers" [value]="customer">{{customer}}</option>
        </select>-->
        <!--<input type="text" list="customers" [(ngModel)]="labelForm.customer">
  <datalist id="customers">
    <select>
      <option *ngFor="let customer of customers" [value]="customer"></option>
    </select>
  </datalist>-->
      </td>
    </tr>
    <tr>
      <td scope="col">Reference # *</td>
      <td>
        <input type="text" [(ngModel)]="labelForm.reference" id="label_reference">
      </td>
    </tr>
  </table>


  <div class="row">
    <div class="col-sm">

      <div class="custom-control-inline"><h5 style="font-weight:500">2. Ship From:</h5></div>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="customRadioInline1" name="customRadioInline1"
               class="custom-control-input"
               (change)="changelocation1()">
        <label class="custom-control-label" for="customRadioInline1">AWISEC</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="customRadioInline2" name="customRadioInline1"
               class="custom-control-input"
               (change)="changelocation2()">
        <label class="custom-control-label" for="customRadioInline2">AWICAS</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" id="customRadioInline3" name="customRadioInline1"
               [checked]="true" class="custom-control-input"
               (change)="changelocation3()">
        <label class="custom-control-label" for="customRadioInline3">MANUAL</label>
      </div>


      <table class="table table-bordered table-sm">
        <tr>
          <td scope="col">Name *</td>
          <td>
            <input type="text" [(ngModel)]="shipFrom.name" id="shipFrom_name">
          </td>
        </tr>
        <tr>
          <td scope="col">Company</td>
          <td>
            <input type="text" [(ngModel)]="shipFrom.company">
          </td>
        </tr>
        <tr>
          <td scope="col">Address 1 *</td>
          <td>

            <input type="text" [(ngModel)]="shipFrom.address1" id="shipFrom_address1">
          </td>
        </tr>
        <tr>
          <td scope="col">Address 2</td>
          <td>

            <input type="text" [(ngModel)]="shipFrom.address2">
          </td>
        </tr>
        <tr>
          <td scope="col">City *</td>
          <td>

            <input type="text"  id="shipFrom_city" [(ngModel)]="shipFrom.city">
          </td>
        </tr>
        <tr>
          <td scope="col">
            State *
          </td>
          <td>
            <input type="text" list="statelist" name="state" [(ngModel)]="shipFrom.state" id="shipFrom_state" />
            <datalist id="statelist">
              <select>
                <option *ngFor="let state of stateList" [value]="state.statecode">{{state.name}}</option>
              </select>
            </datalist>
          </td>
        </tr>
        <tr>
          <td scope="col">ZipCode *</td>
          <td>

            <input type="text" [(ngModel)]="shipFrom.zip" id="shipFrom_zip">
          </td>

        </tr>
        <tr>
          <td scope="col">
            Phone # *
            <br /> <div style="font-size:x-small;">(Required for International Shippings /<br /> Please input only 10-digit-numbers)</div>
          </td>
          <td>
            <input type="text" [(ngModel)]="shipFrom.phone" id="shipFrom_phone">
          </td>

        </tr>
        <tr>
          <td scope="col">Country *</td>
          <td>
            <input type="text" [(ngModel)]="shipFrom.country" id="shipFrom_country">
          </td>
        </tr>
      </table>
    </div>


    <div class="col-sm">
      <h5 style="font-weight:500">3. Ship To:</h5>

      <table class="table table-bordered table-sm">

        <tr>
          <td scope="col">Name *</td>
          <td>
            <!--<input type="text" [(ngModel)]="shipTo.name">-->
            <!--<input type="text" list="addressList" name="address" [(ngModel)]="shipTo" />
            <datalist id="addressList">
              <select>
                <option *ngFor="let address of addressList" [value]="address">
                  {{address.name}}
                </option>
              </select>
            </datalist>-->
            <input type="text" list="addressList" id="shipTo_name" name="name" #name="ngModel" [(ngModel)]="shipTo.name" (change)="addressAutofill(shipTo.name)">
            <datalist id="addressList">
              >

              <select>
                <option *ngFor="let add of addressList; let i = index" [value]="add.name">{{add.address1}} <br />{{add.city}}, {{add.state}} {{add.zip}} </option>
              </select>
            </datalist>
          </td>
        </tr>
        <tr>
          <td scope="col">Company</td>
          <td>
            <input type="text" [(ngModel)]="shipTo.company" />
          </td>
        </tr>
        <tr>
          <td scope="col">Address 1 *</td>
          <td>
            <input type="text" [(ngModel)]="shipTo.address1" id="shipTo_address1" (change)="changeAddress()" />
          </td>
        </tr>
        <tr>
          <td scope="col">Address 2</td>
          <td>
            <input type="text" [(ngModel)]="shipTo.address2" (change)="changeAddress()" />
          </td>
        </tr>
        <tr>
          <td scope="col">City *</td>
          <td>
            <input type="text" [(ngModel)]="shipTo.city" id="shipTo_city" (change)="changeAddress()" />
          </td>
        </tr>
        <tr>
          <td scope="col">
            State *
            <br /> <div style="font-size:x-small;">(Required for domestic shippings)</div>
          </td>
          <td>
            <input type="text" id="shipTo_state" list="statelist" name="state" [(ngModel)]="shipTo.state" (change)="changeAddress()" />
            <datalist id="statelist">
              <select>
                <option *ngFor="let state of stateList" [value]="state.statecode">{{state.name}}</option>
              </select>
            </datalist>
          </td>
        </tr>
        <tr>
          <td scope="col">
            ZipCode *
            <br /> <div style="font-size:x-small;">(Required when the country is Canada or US)</div>
          </td>
          <td>
            <input type="text" id="shipTo_zip" [(ngModel)]="shipTo.zip" (change)="changeAddress()" />
          </td>
        </tr>
        <tr>
          <td scope="col">
            Phone
          </td>
          <td>
            <input type="text" [(ngModel)]="shipTo.phone" />
          </td>
        </tr>
        <tr>
          <td scope="col">
            E-mail
          </td>
          <td>
            <input type="text" [(ngModel)]="shipTo.email" />
          </td>
        </tr>
        <tr>
          <td scope="col">Country *</td>
          <td>
            <input type="text" id="shipTo_country" list="countryCodes" [(ngModel)]="shipTo.country" (change)="changeAddress()" />
            <datalist id="countryCodes">
              <select>
                <option *ngFor="let opt of countryCodes" [value]="opt.countryCode">{{opt.countryName}}</option>
              </select>
            </datalist>
          </td>
        </tr>
      </table>
      <div style="text-align:right">
        <button (click)="saveAddress()">Save to Address Book</button>
      </div>
    </div>
  </div>

  <h5 style="font-weight:500">4. Shipping Option</h5>
  <div class="row">
    <div class="col-sm">
      <table class="table table-bordered table-sm">
        <tr>
          <td scope="col">Mail Provider *</td>
          <td *ngIf="shipTo.country=='US'">
            <select [(ngModel)]="labelForm.carrier" id="label_carrier" (change)="fedexValidateAddress()">
              <option *ngFor="let opt of mailproviders" [ngValue]="opt.providerValue">
              {{opt.providerName}}
              </option>
            </select>
          </td>
          <td *ngIf="shipTo.country!='US'">
            <select [(ngModel)]="labelForm.carrier" id="label_carrier">
              <option *ngIf="shipTo.country!='US'" [value]="'Endicia'">
                USPS
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td scope="col">Service Type *</td>
          <td>
            <select [(ngModel)]="labelForm.shippingoption" id="label_shippingoption">
              <option [hidden]="labelForm.carrier=='FedEx' || shipTo.country!='US'" *ngFor="let opt of endishippingopt" [ngValue]="opt.serviceCode">{{opt.serviceName}}</option>
              <option [hidden]="labelForm.carrier=='FedEx' || shipTo.country=='US'" *ngFor="let opt of endiInternationalShippingOpt" [ngValue]="opt.serviceCode">{{opt.serviceName}}</option>
              <option [hidden]="labelForm.carrier=='Endicia'|| shipTo.country!='US'" *ngFor="let opt of fedshippingopt" [ngValue]="opt">{{opt}}</option>
            </select>
          </td>
        </tr>
        <tr *ngIf="labelForm.shippingoption == 'FEDEX_MPS'">
          <td scope="col">MPS Service Type *</td>
          <td>
            <select [(ngModel)]="labelForm.fedexMpsShippingOption" id="label_shippingoption">
              <option *ngFor="let opt of fedMpsOptions" [ngValue]="opt">{{opt}}</option>
            </select>
          </td>
        </tr>

      </table>
      <div *ngIf="labelForm.carrier=='FedEx' && addressValidationMsg">
        <div style="color:crimson; font-size: medium; font-weight:bold;">
          <p *ngIf="isValidAddress" style="color:green">{{addressValidationMsg}}</p>
          <p *ngIf="!isValidAddress">{{addressValidationMsg}}</p>
        </div>
      </div>
      <div *ngIf="labelForm.carrier=='FedEx'">
        Residential Check:
        <mdb-checkbox *ngIf="!residentialCheckBox" (change)="residentialCheck()">Ship To address is residential</mdb-checkbox>
        <mdb-checkbox *ngIf="residentialCheckBox" [checked]="true" (change)="residentialCheck()">Ship To address is residential</mdb-checkbox>
      </div>
    </div>
    <div class="col-sm">
      <table class="table table-bordered table-sm">
        <tr>
          <td scope="col">Certified</td>
          <td>
            <select [(ngModel)]="labelForm.isCertified">
              <option [ngValue]="false">No</option>
              <option [ngValue]="true">Yes</option>
            </select>
          </td>
        </tr>
        <tr>
          <td scope="col">Signature</td>
          <td>
            <select [(ngModel)]="labelForm.isSignature">
              <option [ngValue]="false">No</option>
              <option [ngValue]="true">Yes</option>
            </select>
          </td>
        </tr>
        <tr>
          <td scope="col">Insured Value</td>
          <td>

            <input type="number" [(ngModel)]="labelForm.insuredValue">
            <div style="color:red;">
              * FedEx liability for each package is limited to $100 USD unless a higher
              value is declared and paid for. Use this element if you want to declare a
              higher value.
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>



  <h5 style="font-weight:500">5. Package Information *</h5>
  <table class="table table-bordered table-sm">
    <thead>
      <tr align="center">
        <th scope="col">Qty</th>
        <th scope="col">Weight (lbs.)</th>
        <th scope="col">Length (in)</th>
        <th scope="col">Width (in)</th>
        <th scope="col">Height (in)</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let p of labelForm.labelpackages; let i = index">
        <td>
          <input type="number" [(ngModel)]="p.qty" id="labelpackages_qty_{{i}}" />
        </td>
        <td>
          <input type="number" [(ngModel)]="p.weight" id="labelpackages_weight_{{i}}" />
        </td>
        <td>
          <input type="number" [(ngModel)]="p.length" id="labelpackages_length_{{i}}" />
        </td>
        <td>
          <input type="number" [(ngModel)]="p.width" id="labelpackages_width_{{i}}" />
        </td>
        <td>
          <input type="number" [(ngModel)]="p.height" id="labelpackages_height_{{i}}" />
        </td>
        <td>
          <button type="button" (click)="addarow()">Add</button>
          <button type="button" (click)="deletelabelpackage(i)">delete</button>
        </td>

      </tr>
    </tbody>
  </table>
  <div *ngIf="labelForm.shippingoption=='PriorityMailInternational'
              || labelForm.shippingoption=='PriorityMailExpressInternational'
              || labelForm.shippingoption=='FirstClassMailInternational'
              || labelForm.shippingoption=='FirstClassPackageInternationalService'" style="padding-bottom:15px;">

    <h5 style="font-weight:500">6. Customs Form *</h5>
    <table class="table table-bordered table-sm">
      <thead>
        <tr align="center">
          <th scope="col">Item Description</th>
          <th scope="col">Country of Origin</th>
          <th scope="col">Value (US$)</th>
          <th scope="col">Quantity</th>
          <th scope="col">Weight (lbs.)</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody *ngFor="let customsItem of customsItems; let i = index">
        <tr>
          <td><input type="text" [(ngModel)]="customsItem.description" /></td>
          <td>
            <select [(ngModel)]="customsItem.countryOfOrigin">
              <option *ngFor="let opt of countryCodes" [ngValue]="opt.countryCode">{{opt.countryName}}</option>
            </select>
          </td>
          <td><input type="number" [(ngModel)]="customsItem.value" /></td>
          <td><input type="number" [(ngModel)]="customsItem.quantity" /></td>
          <td><input type="number" [(ngModel)]="customsItem.weight" /></td>
          <td>
            <button (click)="addCustomItemInfo()">Add</button>
            <button (click)="deleteCustomItemInfo(i)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col-sm">
        <table>
          <tr>
            <td>Non-Delivery Instructions: </td>
            <td>
              <mdb-checkbox (change)="nonDeliveryOption($event)">Return to sender</mdb-checkbox>
            </td>
          </tr>
        </table>
        <div style="color:red;">* If you prefer that the package be abandoned, leave this option unchecked.</div>
      </div>
      <div class="col-sm">
        <table>
          <tr>
            <td>Category Of Items * </td>
            <td>
              <select [(ngModel)]="labelForm.categoryOfItems">
                <option *ngFor="let opt of categoriesOfItems" [ngValue]="opt">{{opt}}</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Sender's Signature * </td>
            <td>
              <input type="text" [(ngModel)]="labelForm.senderSignature.name" />
            </td>
          </tr>

        </table>
      </div>
    </div>
  </div>
  <div style="padding-left:15px; font-size:large; text-align:right; padding-right: 5px;" *ngIf="estLabelRate"><b>Estimated Rate: $ {{estLabelRate}}</b></div>


  <div style="text-align:right; margin-top: 10px;">
    <button type="button" mdbBtn color="primary" size="sm" class="waves-light" data-toggle="modal" data-target="#basicExample" (click)="getLabelRate(frame)"
            mdbWavesEffect>
      Est.Label Rate
    </button>
    <button type="button" mdbBtn color="primary" size="sm" class="waves-light" (click)="passmodel()">Print ZPL Label</button>
    <button type="button" mdbBtn color="primary" size="sm" class="waves-light" (click)="printImageLabel()">Print PDF Label</button>


  </div>
</div>

  <!--==================================Rate Modal=========================================================-->


  <div mdbModal #frame="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <!--Header-->
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">Label Rate(s)</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <!--Body-->
        <div class="modal-body">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Shipping Option</th>
                <th>Est. Rate</th>

              </tr>
            </thead>
            <tbody *ngIf="labelForm.carrier=='Endicia'">
              <tr>
                <th scope="row">1</th>
                <!--<td *ngFor="let el2 of el.postage">
                  {{el2.mailService}}
                </td>
                <td *ngFor="let el2 of el.postage">
                  {{el2.rate}}
                </td>-->
              <td>
                {{serviceName}}
              </td>
                <td>
                  {{estLabelRate}}
                </td>
              </tr>
              <!--<tr class="total">
                <th scope="row">5</th>
                <td>Total</td>
                <td>400$</td>
                <td></td>
              </tr>-->
            </tbody>
            <tbody *ngIf="labelForm.carrier=='FedEx'">
              <tr *ngFor="let el of postage; let i = index">
                <th scope="row">{{i+1}}</th>
                <td>{{el.serviceType}}</td>
                <td *ngFor="let rate of el.ratedShipmentDetails">
                  <div>$ {{rate.shipmentRateDetail.totalNetCharge.amount}}</div>
                </td>

              </tr>

              <!--<tr class="total">
                <th scope="row">5</th>
                <td>Total</td>
                <td>400$</td>
                <td></td>
              </tr>-->
            </tbody>
          </table>

        </div>
        <!--Footer-->
        <div class="modal-footer">
          <button type="button" mdbBtn color="primary" size="sm" class="waves-light" mdbWavesEffect data-dismiss="modal" (click)="frame.hide()">Close</button>
          <!--<button mdbBtn color="primary" class="waves-light" mdbWavesEffect>Checkout</button>-->
        </div>
      </div>
    </div>
  </div>
