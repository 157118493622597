import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { LabelService } from '../../_services/label.service';
import { AuthenticationService } from '../../_services';
import * as printJS from 'print-js';
import { environment } from '../../../environments/environment';

declare function print_zpl(zpl: string): void;

@Component({ templateUrl: './shipment-list.component.html' })

export class ShipmentListComponent {
  mainForm;
  labels;
  labelRequest: any[];
  labelResponse: any;
  customers: string[] = AuthenticationService.customers;
  isLoading: boolean = false;

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,
    private labelService: LabelService, private authenticationService: AuthenticationService) {
    this.authenticationService.getCustomer().then(x => this.customers = x);
    this.mainForm = fb.group({
      customer: 'Krave'
    });
    
  }

  onSubmit(formdata) {
    this.isLoading = true;
    console.log(this.mainForm.value);
    this.labelService.getList(this.mainForm.value).subscribe(data => {
      console.log(data);
      this.labels = data["data"];
      this.isLoading = false;
    });
  }

  rePrintZpl(referenceNo) {
    this.labelService.retrieveLabelsByRefNo(this.mainForm.get('customer').value, encodeURIComponent(referenceNo)).subscribe(data => {
      if (data["isSuccess"]) {
        console.log(data["data"]);
        var labels = data["data"].labelResponse.labels;
        for (let i = 0; i < labels.length; i++) {
          print_zpl(labels[i].image);
        }
      } else {
        alert(data["errorMsg"]);
      }
      console.log(data);
    });
  }

  rePrintPdf(referenceNo) {
    this.labelService.retrieveLabelsByRefNo(this.mainForm.get('customer').value, encodeURIComponent(referenceNo))
      .subscribe(data => {
        if (data["isSuccess"]) {
          console.log(data);
          var labels = data["data"].labelResponse.labels;
          if (labels.length == 1) {
            var url = 'https://' + labels[0].imageUrl;
            printJS(`${environment.apiUrl}/packing/getLabel?url=${(window as any).encodeURIComponent(url)}`);
          } else {
            const lastIndex = labels.length;
            const moveNext = (files: any[], nextIndex: number) => {
              var url = 'https://' + labels[nextIndex].imageUrl;
              const reqeustUrl = `${environment.apiUrl}/packing/getLabel?url=${(window as any).encodeURIComponent(url)}`
              var pwindow = window.open(reqeustUrl, '_blank');
              pwindow.onload = () => {
                pwindow.document.title = `test ${nextIndex}`;
                pwindow.print();
              }
              if (nextIndex + 1 < lastIndex) {
                moveNext(files, nextIndex + 1);
              }
            }
            moveNext([], 0);
          }
        }
      });
  }
}




