<h1 align="center">Vendor Return Receipt</h1>
<div class="row">
  <div class="col"></div>
  <div class="col">
    <div *ngIf="!receiving">
      <div class="form-group">
        <label>Customer</label>
        <select class="browser-default custom-select" [(ngModel)]="customer" style="margin-bottom:.8rem;">
          <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
        </select>
        <label>Location</label>
        <select class="browser-default custom-select" [(ngModel)]="location">
          <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
        </select>
        <button mdbBtn class="btn btn-amber" size="sm" style="margin-top: 20px;" (click)="onSubmit()" mdbWavesEffect>Submit</button>
      </div>
    </div>
  </div>
  <div class="col"></div>
</div>

<div *ngIf="receiving">
  <div *ngIf="isLoading">
    <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
    <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <h5>Customer: {{receiving.customer}}</h5>
    </div>
    <div class="col-lg-3">
      <h5 style="float: left;">Trancking No:</h5>
    </div>
    <div class="col-lg-2">
      <input type="text" class="form-control" [(ngModel)]="trackingNo" (keyup.enter)="focusBarcodeText()" placeholder="Tracking No.."  #input2>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <h5>
        ITEMS TO BE RECEIVED 
      </h5>
    </div>
    <div class="col-lg-2">
      <input type="text" class="form-control" id="txtItemBarcode" [(ngModel)]="barcodeValue" (keyup.enter)="onSkuChange($event.target.value)" placeholder="Item barcorde">
    </div>
  </div>

  
  <div class="row" style="padding-top:25px;">
    <div class="col-lg-12">
      <table class="table table-bordered table-condensed" id="tblRetrunList">
        <thead>
          <tr>
            <th></th>
            <th>SKU</th>
            <th>RECEIPT QUANTITY</th>
            <th>LOCATION</th>
            <th>NOTES</th>
            <th style="width: 10%;">EXPIRATION DATE</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of iteminputs; let i = index">
            <td>{{i+1}}</td>
            <td>{{item.sku}}</td>
            <td><input type="number" [(ngModel)]="item.receiptQty" /></td>
            <td><input type="text" class="location-textbox" (keyup.enter)="focusNextElement($event.target)" [(ngModel)]="item.shelfName" /></td>
            <td><input type="text" (keyup.enter)="focusNextElement($event.target)" [(ngModel)]="item.note" /></td>
            <td>
              <div>
                <mdb-date-picker [inline]="true" (keyup.enter)="focusBarcodeText()" [options]="myDatePickerOptions" [placeholder]="'Expiration Date'" [(ngModel)]="item.expirationDate" required></mdb-date-picker>
              </div>
            </td>
            <td><button (click)="deleteRow(i)">Delete</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-12">

      <span>
        <button mdbBtn class="btn btn-default btn-sm" type="button" color="mdb-color" (click)="onExit()" mdbWavesEffect>Exit</button>
      </span>
      <span [hidden]="receiving.completedAt">
        <button mdbBtn class="btn btn-default btn-sm" type="button" color="mdb-color" (click)="confirmation(receiving.receivedNo)" mdbWavesEffect>Confirm & Exit</button>
      </span>
      <span [hidden]="receiving.completedAt">
        <button mdbBtn class="btn btn-default btn-sm" type="button" color="mdb-color" (click)="confirmAndKeepScanning(receiving.receivedNo)" mdbWavesEffect>Confirm & Keep Scanning</button>
      </span>
    </div>
  </div>
</div>


