import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Shipping, Item } from '../Class';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { ReturnService } from '../_services/return.service';
import { ItemService } from '../_services/item.service';
import { DatePipe, Location } from '@angular/common';
import { AuthenticationService } from '../_services';
import { IMyOptions } from 'ng-uikit-pro-standard';



declare function print_zpl(zpl: string): void;

@Component({
  selector: 'app-return',
  templateUrl: './return.component.html',
  styleUrls: ['./return.component.scss']
})

export class ReturnComponent {
  shipping: any;
  receiving: any;
  RMANo: string;
  retruncodes: string[] = ["A - Item Damaged",
    "B - Wrong Item Shipped", "C - Not as Described",
    "D - Not as Expected", "E - Do not Want",
    "INSUFFICIENT ADDRESS", "ATTEMPTED - NOT KNOWN", "REFUSED", "UNCLAIMED", "OTHER"];
  returncodeinputs: string[];
  returncode: string;
  iteminputs: RMAList[] = [];
  customers: string[] = AuthenticationService.customers;
  itemList: any[] = [];
  barcodeValue: string;
  locations: string[] = ["AWISEC", "AWICAS"];
  customer: string;
  location: string;
  isLoading: boolean = false;

  @ViewChild('input1') inputEl1: ElementRef;
  @ViewChild('input2') inputEl2: ElementRef;
  @ViewChild('input3') inputEl3: ElementRef;

  public myDatePickerOptions: IMyOptions = {
    firstDayOfWeek: 'su' // 'mo' - Monday, 'tu' - Tuesday, 'we' - Wednesday, 'th' - Thursday, 'fr' - Friday, 'sa' - Saturday, 'su' - Sunday
  };

  constructor(private fb: FormBuilder, private http: HttpClient,
    private returnService: ReturnService,
    private itemService: ItemService,
    private plocation: Location,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute) {
    this.authenticationService.getCustomer().then(x => this.customers = x);
    activatedRoute.paramMap.subscribe(params => {
      this.location = params.get('loc') ? params.get('loc') : '';
      this.RMANo = params.get('rmano') ? params.get('rmano') : '';
    });
    if (this.location != '' && this.RMANo != '') {
      this.onBarcodeChange(this.RMANo);
    }
  }

  ngOnInit() {
    setTimeout(() => this.inputEl3.nativeElement.focus());
  }

  attention(receivedNo) {
    console.log(this.iteminputs);
    if (this.shipping.items.length < 1) {
      alert('You need to scan at least one item.')
      return;
    }
    if (this.shipping.items.find(x => x.receiptQty == null) != null) {
      alert('Receipt Qty is a required field.')
      return;
    }
    if (this.shipping.items.find(x => x.note.length == 0) != null) {
      alert('Please put reason for Attention.')
      return;
    }
    if (this.shipping.items.find(x => x.grade.length == 0) != null) {
      alert('Return code is a required field.')
      return;
    }
    this.returnService.putAttention(this.shipping).subscribe(response => {
      if (response['isSuccess'] == true) {
        alert('attention done.');
        location.reload();
      } else {
        alert(response['errorMsg']);
        location.reload();
      }
    });
  }

  confirm(receivedNo) {
    console.log(this.shipping.items.some(a => a.grade == "A - Item Damaged"));
    //var totalqty = this.shipping.items.reduce((a, b) => a + (b['qty'] || 0), 0);
    //var currqty = this.shipping.items.reduce((a, b) => a + (b['receiptQty'] || 0), 0);

    //if (totalqty != currqty) {
    //  alert('ReceptQty does not match with Requested return qty.');
    //  return;
    //}
    if (this.shipping.items.find(x => x.receiptQty == null) != null) {
      alert('Receipt Qty is a required field.')
      return;
    }
    if (this.shipping.items.find(x => x.grade.length == 0) != null) {
      alert('Return code is a required field.')
      return;
    }
    if (this.shipping.items.some(a => a.grade == "A - Item Damaged")) {
      alert('cannot confirm due to the damaged item(s).');
      return;
    }

    this.returnService.confirmReceived(this.shipping).subscribe(response => {
      if (response['isSuccess'] == true) {
        alert('confirmed.');
        location.reload();
        
      } else {
        alert(response['errorMsg']);
        location.reload();
      }
    });
    
  }

  onSubmit() {
    if (this.customer == null || this.location == null) {
      alert('please select customer and/or location.');
      return;
    }

    this.returnService.getReturnReceivingwithloc(this.customer, this.location)
    .subscribe(response => {
      if (response["isSuccess"]) {
        console.log(response["data"]);
        this.receiving = response["data"];
        this.iteminputs = response["data"].items;
        setTimeout(() => this.inputEl2.nativeElement.focus());
      }
    });

    this.itemService.getItemList(this.customer).subscribe(data => {
      this.itemList = data;
      console.log(this.itemList);
    });
      
  }

  onExit() {
    this.shipping = null;
    this.receiving = null;
    this.iteminputs = [];
    this.plocation.replaceState(`/return`);
    setTimeout(() => this.inputEl3.nativeElement.focus());
  }


  onBarcodeChange(event: string): void {
    if (this.location == "" || this.location == null) {
      alert('please choolse location.');
      return;
    }

    console.log(event.trim());
    this.RMANo = event.trim();
    this.plocation.replaceState(`/return/${this.location}/${this.RMANo}`);
    //not support for CrossPoint, Arencia, Lynden, and PeopleLook
    if ((this.RMANo.substring(0, 2).toUpperCase()) == "KB") { //Krave

      this.returnService.getReturnReceivingwithrma("Krave", this.RMANo).subscribe(response => {
        if (response["isSuccess"]) {
          this.shipping = response["data"];
          this.shipping.location = this.location;
          console.log('before printing shipping');
          console.log(this.shipping);
          setTimeout(() => this.inputEl1.nativeElement.focus());
        }
      });

      this.itemService.getItemList("Krave").subscribe(data => {
        this.itemList = data;
        console.log(data);
      });
    }

    if ((this.RMANo.substring(0, 2).toUpperCase()) == "JJ") { //Jayjun
      this.returnService.getReturnReceivingwithrma("Jayjun", this.RMANo).subscribe(response => {
        if (response["isSuccess"]) {
          console.log(response["data"]);
          this.shipping = response["data"];
          this.shipping.location = this.location;
          setTimeout(() => this.inputEl1.nativeElement.focus());
        }
      });

      this.itemService.getItemList("Jayjun").subscribe(data => {
        this.itemList = data;
        console.log(data);
      });

    }
    if ((this.RMANo.substring(0, 2).toUpperCase()) == "HJ") { //HoneyJarret
      this.returnService.getReturnReceivingwithrma("HoneyJarret", this.RMANo).subscribe(response => {
        if (response["isSuccess"]) {
          console.log(response["data"]);
          this.shipping = response["data"];
          this.shipping.location = this.location;
          setTimeout(() => this.inputEl1.nativeElement.focus());
        }
      });

      this.itemService.getItemList("HoneyJarret").subscribe(data => {
        this.itemList = data;
        console.log(data);
      });

    }
    if ((this.RMANo.substring(0, 2).toUpperCase()) == "BT") { //Beauteers
      this.returnService.getReturnReceivingwithrma("Beauteers",  this.RMANo).subscribe(response => {
        if (response["isSuccess"]) {
          console.log(response["data"]);
          this.shipping = response["data"];
          this.shipping.location = this.location;
          setTimeout(() => this.inputEl1.nativeElement.focus());
        }
      });

      this.itemService.getItemList("Beauteers").subscribe(data => {
        this.itemList = data;
        console.log(data);
      });

      }
      if ((this.RMANo.substring(0, 2).toUpperCase()) == "B1") { //Beauteers1
        this.returnService.getReturnReceivingwithrma("Beauteers1",  this.RMANo).subscribe(response => {
          if (response["isSuccess"]) {
            console.log(response["data"]);
            this.shipping = response["data"];
            this.shipping.location = this.location;
            setTimeout(() => this.inputEl1.nativeElement.focus());
          }
        });

        this.itemService.getItemList("Beauteers1").subscribe(data => {
          this.itemList = data;
          console.log(data);
        });

      }
    if ((this.RMANo.substring(0, 2).toUpperCase()) == "SW") { //SGLife
      this.returnService.getReturnReceivingwithrma("SGLife", this.RMANo).subscribe(response => {
        if (response["isSuccess"]) {
          console.log(response["data"]);
          this.shipping = response["data"];
          this.shipping.location = this.location;
          setTimeout(() => this.inputEl1.nativeElement.focus());
        }
      });

      this.itemService.getItemList("SGLife").subscribe(data => {
        this.itemList = data;
        console.log(data);
      });
    }
    if ((this.RMANo.substring(0, 2).toUpperCase()) == "SP") { //Spigen
      this.returnService.getReturnReceivingwithrma("Spigen",  this.RMANo).subscribe(response => {
        if (response["isSuccess"]) {
          console.log(response["data"]);
          this.shipping = response["data"];
          this.shipping.location = this.location;
          setTimeout(() => this.inputEl1.nativeElement.focus());
        }
      });

      this.itemService.getItemList("Spigen").subscribe(data => {
        this.itemList = data;
        console.log(data);
      });
    }
    if ((this.RMANo.substring(0, 2).toUpperCase()) == "TY") { //ToyoLife
      this.returnService.getReturnReceivingwithrma("ToyoLife",  this.RMANo).subscribe(response => {
        if (response["isSuccess"]) {
          console.log(response["data"]);
          this.shipping = response["data"];
          this.shipping.location = this.location;
          setTimeout(() => this.inputEl1.nativeElement.focus());
        }
      });

      this.itemService.getItemList("ToyoLife").subscribe(data => {
        this.itemList = data;
        console.log(data);
      });
    }
    
  }

  checkQty() {
    var totalqty = this.shipping.items.reduce((a, b) => a + (b['qty'] || 0), 0);
    var currqty = this.shipping.items.reduce((a, b) => a + (b['receiptQty'] || 0), 0);

    if (totalqty < currqty) {
      alert('ReceptQty is greater than the Requested return qty.');
      return;
    }
  }


  onSkuChangewithNothing(barcode: string) {
    console.log(barcode);
    //var totalqty = this.shipping.items.reduce((a, b) => a + (b['qty'] || 0), 0);
    //var currqty = this.shipping.items.reduce((a, b) => a + (b['receiptQty'] || 0), 0);

    //if (totalqty <= currqty) {
    //  alert('ReceptQty is greater than the Requested return qty.');
    //  return;
    //}

    for (let i = 0; i < this.itemList.length; i++) {
      
      if (this.itemList[i].sku == barcode || this.itemList[i].upc == barcode) {

        //if (this.shipping.items.some(a => a.sku == this.itemList[i].sku)) {
        //  console.log('here1');
        //  this.shipping.items.find(x => x.sku == this.itemList[i].sku).receiptQty += 1;
        //  console.log(this.shipping.items);
        //} else {

          let temp: RMAList = {
            receiptQty: 1,
            sku: this.itemList[i].sku,
            upc: this.itemList[i].upc,
            desc: this.itemList[i].desc,
            customer: this.itemList[i].customer,
            grade: '',
            note: '',
          };

          this.shipping.items.push(temp);
      //  }
      }
    }
   // console.log(this.iteminputs);
    this.barcodeValue = "";
  }

  onSkuChangewithoutRMA(barcode: string) {
    console.log(barcode);

    for (let i = 0; i < this.itemList.length; i++) {

      if (this.itemList[i].sku == barcode || this.itemList[i].upc == barcode) {

        //if (this.shipping.items.some(a => a.sku == this.itemList[i].sku)) {
        //  console.log('here1');
        //  this.shipping.items.find(x => x.sku == this.itemList[i].sku).receiptQty += 1;
        //  console.log(this.shipping.items);
        //} else {

        let temp: RMAList = {
          receiptQty: 1,
          sku: this.itemList[i].sku,
          upc: this.itemList[i].upc,
          desc: this.itemList[i].desc,
          customer: this.itemList[i].customer,
          grade: '',
          note: ''
        };

        this.iteminputs.push(temp);
        console.log(this.iteminputs);
        //  }
      }
    }
    // console.log(this.iteminputs);
    this.barcodeValue = "";
  }

  deleteRow(index: number) {
  //  var index = this.iteminputs.map(function (e) { return e.sku }).indexOf(sku);
  //  this.iteminputs.splice(index, 1);
    this.shipping.items.splice(index, 1);
  //  console.log(this.iteminputs);
  }


  onCompletedwithRMA() {
  //  this.shipping.items = this.iteminputs;
    this.returnService.postReturnReceiving(this.shipping).subscribe(response => {
      if (response["isSuccess"]) {
        console.log(response["data"]);
        alert('returned successfully.');
        this.onExit();
      } else {
        alert(response["errorMsg"]);
      }
    });
  }

  onUpdate() {
    console.log(this.shipping);
    this.returnService.updateReturnReceiving(this.shipping).subscribe(response => {
      if (response["isSuccess"]) {
        console.log(response["data"]);
        alert('returned successfully.');
        this.onExit();
      } else {
        alert(response["errorMsg"]);
      }
    });
  }

  onCompletedwithReceiving() {
    
    //this.receiving.items = this.iteminputs;
    //this.returnService.postReturnReceiving(this.receiving).subscribe(response => {
    //  if (response["isSuccess"]) {
    //    console.log(response["data"]);
    //    alert('returned successfully.');

    //    this.returnService.getReturnReceivingwithloc(this.customer, this.location).subscribe(response => {
    //      if (response["isSuccess"]) {
    //        console.log(response["data"]);
    //        this.receiving = response["data"];
    //        setTimeout(() => this.inputEl2.nativeElement.focus());
    //      }
    //    });
    //    this.iteminputs = [];

    //  } else {
    //    alert(response["errorMsg"]);
    //  }
    //});
    
  }
  attentionwithReceiving(receivedNo) {
    console.log(this.iteminputs);
    console.log(this.receiving.items);
    if (this.iteminputs.find(x => x.receiptQty == null) != null) {
      alert('Receipt Qty is a required field.')
      return;
    }
    if (this.iteminputs.length < 1) {
      alert('You need to scan at least one item.')
      return;
    }
    if (this.iteminputs.find(x => x.note.length == 0) != null) {
      alert('Please put reason for Attention.')
      return;
    }
    if (this.iteminputs.find(x => x.grade.length == 0) != null) {
      alert('Return code is a required field.')
      return;
    }

    this.receiving.items = this.iteminputs;
    this.returnService.putAttention(this.receiving).subscribe(response => {
      if (response['isSuccess'] == true) {
        alert('attention done.');
        //this.returnService.getReturnReceivingwithloc(this.customer, this.location).subscribe(response => {
        //  if (response["isSuccess"]) {
        //    console.log(response["data"]);
        //    this.receiving = response["data"];
        //    setTimeout(() => this.inputEl2.nativeElement.focus());
        //  }
        //});
        this.iteminputs = [];

        location.reload();

      } else {
        alert(response['errorMsg']);
        location.reload();
      }
    });
  }


  confirmAndKeepScanning(receivedNo) {
    this.isLoading = true;
    if (this.iteminputs.some(a => a.grade == "A - Item Damaged")) {
      alert('cannot confirm due to the damaged item(s).');
      this.isLoading = false;
      return;
    }
    if (this.iteminputs.find(x => x.grade.length == 0) != null) {
      alert('Return code is a required field.');
      this.isLoading = false;
      return;
    }
    this.receiving.items = this.iteminputs;
    console.log(this.receiving);
    this.returnService.confirmReceived(this.receiving).subscribe(response => {
      if (response['isSuccess'] == true) {
        alert('Your return request has been received successfully.');
        this.onSubmit();
        this.isLoading = false;
      } else {
        alert(`Found some error(s). Please check erorr(s) below: \r\n\r\n${response['errorMsg']}`);
        this.isLoading = false;
      }
    });
  }

  confirmwithReceiving(receivedNo) {
    console.log(this.iteminputs.some(a => a.grade == "A - Item Damaged"));
    //var totalqty = this.shipping.items.reduce((a, b) => a + (b['qty'] || 0), 0);
    //var currqty = this.shipping.items.reduce((a, b) => a + (b['receiptQty'] || 0), 0);

    //if (totalqty != currqty) {
    //  alert('ReceptQty does not match with Requested return qty.');
    //  return;
    //}

    if (this.iteminputs.some(a => a.grade == "A - Item Damaged")) {
      alert('cannot confirm due to the damaged item(s).');
      return;
    }
    if (this.iteminputs.find(x => x.grade.length == 0) != null) {
      alert('Return code is a required field.')
      return;
    }
    this.receiving.items = this.iteminputs;
    console.log(this.receiving);
    this.returnService.confirmReceived(this.receiving).subscribe(response => {
      if (response['isSuccess'] == true) {
        alert('Your return request has been received successfully.');
        location.reload();

      } else {
        alert(`Found some error(s). Please check erorr(s) below: \r\n\r\n${response['errorMsg']}`);
        //location.reload();
      }
    });

  }

  


}


class ItemList {
  id: string;
  sku: string;
  qty: number;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
//  receiptqty: number;
//  grade: string;
}

class RMAList {
  //  id: string;
  receiptQty: number;
  sku: string;
  //  qty: number;
  upc: string;
  //  upc2: string;
  desc: string;
  //  weight: number;
  customer: string;
  //  isEditable: boolean = false;
  grade: string;
  note: string;
  expirationDate?: Date;
}
