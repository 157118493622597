<div class="card mt-4">
  <h4 class="card-header">Admin</h4>
  <div class="card-body">
    <h4>Order Rollback</h4>
    <label>Customer</label>
    <select class="browser-default custom-select" style="width:120px;font-size:small;margin: 17px;top:0px;" [(ngModel)]="rollbackInputs.customer">
      <option *ngFor="let opt of customers" [value]="opt">{{opt}}</option>
    </select>
    <label>Order #</label>
    <input type="number" style="width:120px;margin: 17px;" [(ngModel)]="rollbackInputs.orderno" />
    <button (click)="rollbackOrder()">Order Rollback</button>
    <p>This page can be accessed <u>only by administrators</u>.</p>

    <ul>
      <li>
        Timeout: <strong>{{timeout}}</strong> <button (click)="getTimeoutOnClick()">getTimeOut</button>
      </li>
      <li>
        Refreshtoken: <button (click)="refreshToken()">RefreshToken</button>
      </li>
      <li>
        <label class="switch">
          <input type="checkbox" id="chkUspsCarrier" [(ngModel)]="uspsCarrier" (change)="onCarrierChange($event)" />
          <div class="slider"></div>
          <div class="slider-name">{{uspsCarrier ? "Endicia" : "Pitney Bowes" }}</div>
        </label>

      </li>
    </ul>

    <p class="mb-1">All users from secure (admin only) api end point:</p>

    <div *ngIf="loading" class="spinner-border spinner-border-sm"></div>
    <ul *ngIf="users">
      <li *ngFor="let user of users">{{user.firstName}} {{user.lastName}}</li>
    </ul>
  </div>
</div>
