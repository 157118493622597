import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { User, Role } from '@app/_models';
import { AuthenticationService } from '@app/_services';
import { ShippingService } from '../../_services/shipping.service';
import { TaskService } from '../../_services/task.service';
import * as XLSX from 'xlsx';
import { DatePipe, Location } from '@angular/common';
import { MdbCheckboxChange } from 'ng-uikit-pro-standard';


declare function print_zpl(zpl: string): void;

declare function printLabels(zpl: string, successCallback: () => void, errorCallback: () => void): void;

@Component({
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})

export class TaskListComponent {
  user: User;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  @ViewChild('holdframe') holdframe: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'TaskList.xlsx');
  }
  showAll: boolean = false;
  printProgress: number;
  customerNos: string;
  printTotalCount?: number = null;
  printIndex?: number = null;
  mainForm;
  elements: any = [];
  total: number;
  locations: string[] = ["All", "AWISEC", "AWICAS"];
  customers: string[] = AuthenticationService.customers;
  isLoading: boolean;

  constructor(
    private fb: FormBuilder, private http: HttpClient, private router: Router,
    private shippingService: ShippingService,
    private taskService: TaskService,
    private location: Location,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute) {
    this.authenticationService.getCustomer().then(x => this.customers = x);
    this.authenticationService.user.subscribe(x => this.user = x);

    activatedRoute.paramMap.subscribe(params => {
      this.mainForm = fb.group({
        customer: params.get('customer') ? params.get('customer') : this.user.customer,
        location: params.get('location') ? params.get('location') : this.user.location,
        customerNos: '',
        showAllTask: false,
        page: 1
      });
      this.onSubmit();
    });
    this.customerNos = this.mainForm.controls['customerNos'];
  }
  get isAdmin() {
    if (this.user.username == 'min@awi-usa.com'
      || this.user.username == 'jkoo@awi-usa.com'
      || this.user.username == 'dev@awi-usa.com') {
      return true;
    }
    return this.user && this.user.role === Role.Admin;
  }
  changeLocation(cus: string, psnumber: number, location: string) {
    this.taskService.changeTasksLocation(cus, psnumber, location).subscribe(data => {
      if (data == 1) {
        alert('task moved.');
      } else {
        alert('fail to move the task: plase check if packed, closed, or cancelled.');
      }
    });
  }

  

  createTasksLambda() {
    if (confirm(`Do you want to create ${this.mainForm.value.customer}'s task for ${this.mainForm.value.location}?`)) {
      if (this.isLoading) {
        return;
      }
      if (this.mainForm.value.customer == "Krave") {
        if (this.mainForm.value.location == "All") {
          alert('Please Select Location');
          return;
        }
      }
      this.isLoading = true;
      this.taskService.lambdaTaskFunctionHandler(this.mainForm.value.customer, this.mainForm.value.location)
        .subscribe(response => {
          this.isLoading = false;
          this.onSubmit();
          alert(`Successfully ${this.mainForm.value.customer}'s task created.`);
        }, error => {
            this.isLoading = false;
            this.onSubmit();

            alert(error);
        }, () => {
            this.isLoading = false;
            this.onSubmit();
        });
    }
  }
  regenerateTasks() {
    const customerNoList = this.mainForm.get('customerNos').value.split(',').map(x => x.trim()).filter(x => x.length > 0);
    if (customerNoList && customerNoList.length == 0) {
      alert('Please enter the CustomerNo List. ');
      return;
    }
    
    if (confirm(`Do you want to re-generate ${this.mainForm.value.customer}'s tasks?\r\nTasks will be created by locations if it has different location on it.`)) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.taskService.regenerateTasks(this.mainForm.value.customer, customerNoList)
        .subscribe(response => {
          this.isLoading = false;
          if (response["isSuccess"]) {
            alert(`Successfully ${this.mainForm.value.customer}'s task created.\r\n${response["message"]}`);
          }
          this.onSubmit();
        });
    }
  }

  onSubmit() {
    this.isLoading = true;
    if (this.user.location != "All") {
      if (this.mainForm.value.location != this.user.location) {
        this.isLoading = false;
        alert(`${this.user.firstName} has no access to tasks assigned to the location '${this.mainForm.value.location}'. `);
        return;
      }
    }

    this.location.replaceState(`/task-list/${this.mainForm.value.customer}/${this.user.location}`);
    
    this.taskService.getTaskList(this.mainForm.value)
      .subscribe(data => {
        this.isLoading = false;
        this.elements = data["list"];
        this.total = data["total"];
        console.log(data);
        
      });
  }

  printPackingSlipAll(cus: string, psnumber: number) {
    this.taskService.getPackingSlipList(cus, psnumber).subscribe(response => {
      console.log(response);
      if (response["isSuccess"]) {
        this.printPackingSlips(response["data"] as string[], 0, cus, psnumber);
      } else {
        alert(response["errorMsg"]);
      }
    });
  }

  printPackingSlips(zplCommands: string[], index: number, customer: string, taskId: number) {
    
    var printPacking = function (commands, printIndex) {
      function next() {
        console.log(`Printing ${printIndex} packing slip.`);
        if (commands.length > printIndex) {
          if (printIndex % 10 == 0) {
            setTimeout(function () {
              printPacking(commands, printIndex + 1);
            }, 1000);
          } else {
            printPacking(commands, printIndex + 1);
          }
        }
        else {
          alert(`[#${taskId}] ${customer}'s packing slips(${printIndex}) were printed all successfully`);
        }
      }
      printLabels(commands[printIndex], next, function () {
        console.log(`Printing ${printIndex} packing slip.`);
        if (confirm(`Do you want to keep printing from last [${printIndex}] succeeded?`)) {
          printPacking(commands, printIndex);
        }
      });
    }
    printPacking(zplCommands, index);
  }

  currentElement: any = null;
 

  showConfirmModal(holdframe: any, element: any) {
    console.log(element);
    const isAlreadyPrinted = this.checkIfAtLeastOnePrinted(element.packingSlipPrint);
    if (isAlreadyPrinted) {
      this.currentElement = element;
      holdframe.show();
    }
    else {
      if (confirm('Do you want to print out all the shipping label?')) {
        console.log('label generated');
        this.generateLabelsAll(element.customer, element.shippingIdList, element.packingSlipPrint)
      }
    }
  }

  thirdComfirmation(holdframe1:any, holdframe2: any, holdframe3: any) {
    if (this.currentElement != null) {
      console.log('label generated');
      this.generateLabelsAll(this.currentElement.customer, this.currentElement.shippingIdList, this.currentElement.packingSlipPrint)
    } else {
      alert('not available.');
    }
    holdframe1.hide();
    holdframe2.hide();
    holdframe3.hide();
  }
  checkIfAtLeastOnePrinted(taskNo: number) {
    var task = this.elements.find(x => x.packingSlipPrint == taskNo);
    console.log(task);
    return task.closedCount > 0;
   
  }

  generateLabelsAll(customer: string, orderMongoIdList: string[], taskNo: number) {
    if (this.isLoading) {
      return;
    }
    console.log('label generated', customer, orderMongoIdList, taskNo);
   // if (confirm("Do you really want to print out all of shipping labels?")) {
      this.checkIfAtLeastOnePrinted(taskNo);
      this.printTotalCount = orderMongoIdList.length;
      this.isLoading = true;
      var refresh = () => {
        this.printTotalCount = null;
        this.isLoading = false;
        this.onSubmit();
      }

      var generateLabelTask = (cust: string, shippingId: string, index: number) => {
        this.printIndex = index + 1;
        this.printProgress = ~~((this.printIndex / this.printTotalCount) * 100);
        this.taskService.generateLabel(customer, shippingId).subscribe(response => {
          if (response["isSuccess"]) {
            try {
              printLabels(response["labelImage"], function () {

              }, function () { });
              //console.log(response["labelImage"])
            } catch (e) {

            }
            try {
              printLabels(response["rmaImage"], function () {
                if ((index + 1) % 10 == 0) {
                  setTimeout(() => generateLabel(customer, orderMongoIdList, index + 1), 1000);
                }
                else {
                  setTimeout(() => generateLabel(customer, orderMongoIdList, index + 1), 300);
                }
              }, function () { });
              //console.log(response["rmaImage"])
              //if ((index + 1) % 10 == 0) {
              //  setTimeout(() => generateLabel(customer, orderMongoIdList, index + 1), 1000);
              //}
              //else {
              //  setTimeout(() => generateLabel(customer, orderMongoIdList, index + 1), 300);
              //}
            } catch (e) {

            }
          } else {
            generateLabel(customer, orderMongoIdList, index + 1);
          }
        });
        //setTimeout(function () { generateLabel(customer, orderMongoIdList, index + 1) }, 300);
      }
      function generateLabel(customer: string, orderMongoIdList: string[], currentIndex: number) {
        if (orderMongoIdList.length > currentIndex) {

          const mongoShippingId = orderMongoIdList[currentIndex];
          generateLabelTask(customer, mongoShippingId, currentIndex);
        }
        else {

          refresh();
          alert(`[${taskNo}] All the shipping labels were printed successfully.`)
        }

      }

      generateLabel(customer, orderMongoIdList, 0);
   // }
   
  }

  showAllTasks(event: MdbCheckboxChange) {
    this.showAll = event.checked;
    this.mainForm.value.showAllTask = this.showAll;
    
    this.onSubmit();
  }
  
}

class ItemList {
  id: string;
  sku: string;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
}


