import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { ShippingService } from '../_services/shipping.service';
import { ItemService } from '../_services/item.service';
import { Time } from '@angular/common';
import { Shipping, Label, Item } from '../Class';
import { AuthenticationService } from '../_services';

declare function print_zpl(zpl: string): void;

@Component({
  templateUrl: './orderdetail.component.html',
  styleUrls: ['./orderdetail.component.scss']
})

export class OrderDetailComponent {

  locations: string[] = ["All", "AWISEC", "AWICAS"];
  customers: string[] = AuthenticationService.customers;
  customerPrefixes: string[] = AuthenticationService.customerPrefixes;
  mainForm;
  customer: string;
  orderNo: number;
  customerNo: string;
  orderdetail: any;
  label: any;
  itemlist: any;
  estimWeight: string;
  isLoading: boolean = false;
  isTrackingEditable: boolean = false;
  isAddressEditable: boolean = false;
  isItemEditable: boolean = false;
  isShippingOptionEditable: boolean = false;

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,
    private shippingService: ShippingService,
    private itemService: ItemService,
    private _ActivatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService) {
    this.authenticationService.getCustomer().then(x => this.customers = x);

    this.mainForm = fb.group({
      orderNo: null,
      customerNo: '',
      customer: "Krave",
      labels: new Label
    });

    this.customer = this.mainForm.controls['customer'];
    this.orderNo = this.mainForm.controls['orderNo'];
    this.customerNo = this.mainForm.controls['customerNo'];
  }

  sub;
  cus;
  no;

  ngOnInit() {
    this.sub = this._ActivatedRoute.paramMap.subscribe(params => {
      this.cus = params.get('cus');
      this.no = params.get('no');
      this.isLoading = true;
      this.shippingService.getOrderDetail(this.cus, this.no)
        .subscribe(data => {
          this.isLoading = false;
          console.log(data);
          this.orderdetail = data;
        })
    })
  }

  addressUpdate() {
    if (confirm(`Do you want to save changed address?`)) {
      //this.orderdetail.weight = this.orderdetail.weight + "";
      this.isLoading = true;
      this.shippingService.updateAddress(this.orderdetail).subscribe(data => {
        this.isLoading = false;
        console.log(data);
        alert('Successfully shipping address updated.');
      })
      this.isAddressEditable = !this.isAddressEditable;
    }
  }

  cancelOrder() {
    if (confirm(`Do you really want to cancel this order?`)) {
      if (this.orderdetail.type == 'Spf' || this.orderdetail.type == 'Jet') {
        alert(`Failed your reuqest. Because Shopify order type wouldn't be able to be cancelled.`);
      }
      else if (this.orderdetail.labels != null && this.orderdetail.labels.length > 0 && this.orderdetail.labels[0].trackingNo != null) {
        alert('Cannot cancel: The label has been created.');
        alert(`Failed your reuqest. Because a shipping label was already created. \r\nIf you really want to cancel this order, please remove a shipping label first and try again.`);
      }
      else if (this.orderdetail.packingEndAt != null) {
        alert('Cannot cancel: The items have been packed.');
        alert(`Failed your reuqest. Because a shipping items were already packed.`);
      }
      else {
        this.isLoading = true;
        this.shippingService.cancelOrder(this.orderdetail).subscribe(data => {
          this.isLoading = false;
          if (data["isSuccess"] == true) {
            alert('Successfully cancelled for this order.');
          } else {
            alert(data["errorMsg"]);
          }

        });
      }
    }
  }

  closedUpdate(): void {
    if (confirm(`Do you really want to close this order?`)) {
      if (this.orderdetail.closedAt != null) {
        alert('This order was already closed.')
      } else if (this.orderdetail.type == 'Spf' && this.orderdetail.type == 'netsuite') {
        alert('This order type is not "Manual". Only manual orders can be closed manually.')
      } else if (this.orderdetail.closedAt == null
        && (this.orderdetail.type != 'Spf' && this.orderdetail.type != 'netsuite')) {
        this.isLoading = true;
        this.shippingService.closeOrder(this.orderdetail).subscribe(data => {
          this.isLoading = false;
          console.log(data);
          if (data["isSuccess"] == true) {
            alert('Successfully closed for this order');
          } else {
            alert(data["errorMsg"]);
          }
        });
      } else {
        alert('Failed your request.')
      }
    }
  }

  deleteLabel() {
    if (confirm(`Do you really want to remove a created label for this order?`)) {
      if (this.orderdetail.type == 'Spf' || this.orderdetail.type == 'Jet') {
        alert('Cannot delete the label: This order type is Spf or Jet.');
      } else if (this.orderdetail.labels == null || !this.orderdetail.labels) {
        alert('Cannot delete the label: This order has no label.');
      } else if (this.orderdetail.labels.length > 0 && this.orderdetail.labels[0].trackingNo == null) {
        alert('Cannot delete the label: This order has no label.');
      } else {
        this.isLoading = true;
        this.shippingService.deleteLabel(this.orderdetail).subscribe(data => {
          this.isLoading = false;
          alert('Successfully deleted a label for this order');
        });
      }
    }
  }

  deleteOrder() {
    this.shippingService.deleteOrder(this.cus, this.no).subscribe(result => {
      if (result["isSuccess"] == true) {
        alert('Successfully deleted for this order.');
      } else {
        console.log(result);
        alert(`Failed to delete:${result["errorMsg"]}`);
      }
    })
   
  }

  addItem() {
    this.orderdetail.items.push(new Item);
  }

  deleteItem(index) {
    this.orderdetail.items.splice(index, 1);
  }

  getWeight() {
    this.itemService.getItemList(this.orderdetail.customer)
      .subscribe(data => {
        this.itemlist = data as ItemList[];
        console.log(this.itemlist);

        var result = 0;
        for (let i = 0; i < this.orderdetail.items.length; i++) {
          let n = this.itemlist.findIndex(j => j.sku == this.orderdetail.items[i].sku);
          if (n > -1) {
            result += this.orderdetail.items[i].qty * this.itemlist[n].weight;
          }
        }
        this.estimWeight = (result / 453.592 + 0.2).toFixed(2);

        console.log(this.estimWeight);

      });

    
  }

  updateItems() {
    this.isItemEditable = !this.isItemEditable;
    console.log(this.orderdetail);
    this.shippingService.updateItems(this.orderdetail)
      .subscribe(data => {
        alert('Successfully items were updated.');
      });
  }

  onSubmit(formData) {

    if (this.mainForm.valid) {
      this.customer = this.mainForm.get('customer').value;
      let orderNo = this.mainForm.get('orderNo').value ? this.mainForm.get('orderNo').value : '';
      console.log('OrderNo', orderNo);
      this.shippingService.postOrderDetail(
        this.customer as string,
        ~~orderNo,
        this.mainForm.get('customerNo').value
      ).subscribe(data => {
        console.log(data);
        this.orderdetail = data;
      })
    }
  }

  reprintLabel() {
    if (this.orderdetail.labels == null || !this.orderdetail.labels) {
      alert('Not found any shipping label for this order. Please make sure whether a shipping is created or not.');
    } else {
      print_zpl(this.orderdetail.labels[0]["image"]);
    }
  }

  trackingUpdate() {
    console.log(this.label);
    if (!this.orderdetail.labels || this.orderdetail.labels == null) {
      this.orderdetail.labels = [];
      this.orderdetail.labels.push(this.label);
    } else {
      this.orderdetail.labels[0].trackingNo = this.label.trackingNo;
      this.orderdetail.labels[0].carrier = this.label.carrier;
    }
    console.log(this.orderdetail.labels);

    this.shippingService.updateTrackingNumber(this.orderdetail).subscribe(data => {
      console.log(data);
      if (data["isSuccess"] == true) {
        alert('Successfully a tracking number updated');
      } else {
        alert('The tracking number is already existed.');
      }
    });
    this.isTrackingEditable = !this.isTrackingEditable;
  }

  updateAddressForm(): void {
    if (this.orderdetail.labels != null) {
      if (this.orderdetail.labels[0].trackingNo != null) {
        alert('Failed to update the address because the label was already created.');
        return;
      }
    }
    if (this.orderdetail.type == "Spf") {
      alert('Failed to update the address because this type is Spf.');
      return;
    }
    this.isAddressEditable = !this.isAddressEditable;
  }

  updateItemsForm() {
    if (this.orderdetail.type == "Spf") {
      alert('You cannot modify this order because this order type is Shopify Order.');
      return;
    }

    else if(this.orderdetail.labels != null && this.orderdetail.labels[0].trackingNo != null) {
      alert('You cannot modify this order because a label was already created.');
      return;
    }

    else {
      this.isItemEditable = !this.isItemEditable;
      this.itemService.getItemList(this.orderdetail.customer)
        .subscribe(data => {
          this.itemlist = data as ItemList[];
          console.log(this.itemlist);
        });
    }
  }

  resetPackingSlip() {
    if (confirm('Do you really want to reset packing slip?')) {
      this.shippingService.resetPackingSlip(this.orderdetail)
        .subscribe(response => {
          if (response['IsSuccess'] == false) {
            alert(`Your request was failed. Please see an error message below. \n\n ${response['errorMsg']}`);
          }
          else {
            alert("Successfully packing slip re-generated");
          }
          
        });
    }

  }
  updateTrackingForm() {
    if (this.orderdetail.packingEndAt == null) {
      alert('Packing is still in progress.');
      return;
    }

    if (!this.orderdetail.labels ||
      this.orderdetail.labels == null ||
      this.orderdetail.labels[0].trackingNo == null) {

      this.label = new Label;
      this.isTrackingEditable = !this.isTrackingEditable;
      console.log(this.isTrackingEditable);
      console.log(this.label);

    } else {
      alert('A tracking number is already exist');
    }
  }

  shippingOptionForm() {
    if (this.orderdetail.type == "Spf") {
      alert("Failed your request to change because Shopify order type wouldn't be able to change a shipping option.");
      return;
    }
    else if (this.orderdetail.labels != null && this.orderdetail.labels[0].trackingNo != null) {
      alert("Failed your request to change because a shipping label is alreay existed.");
      return;
    } else {
      this.isShippingOptionEditable = !this.isShippingOptionEditable;
      this.getWeight();
    }
    
  }

  updateShippingOption() {
    console.log(this.orderdetail);

    if (this.orderdetail.shippingOption == "Standard (Intra)") {
      if (Number(this.estimWeight) <= 1 && Number(this.estimWeight) > 0) {
        this.orderdetail.labels[0].Carrier = "Endicia";
        this.orderdetail.labels[0].ShippingOption = "First";
      } else if (Number(this.estimWeight) > 1) {
        this.orderdetail.labels[0].Carrier = "FedEx";
        this.orderdetail.labels[0].ShippingOption = "SMART_POST";
      }
    } else if (this.orderdetail.shippingOption == "Ground (Intra)") {
      this.orderdetail.labels[0].Carrier = "FedEx";
      this.orderdetail.labels[0].ShippingOption = "FEDEX_GROUND";
    } else if (this.orderdetail.shippingOption == "Priority (Intra)") {
      this.orderdetail.labels[0].Carrier = "Endicia";
      this.orderdetail.labels[0].ShippingOption = "Priority";
    } else if (this.orderdetail.shippingOption == "Express (Intra)") {
      this.orderdetail.labels[0].Carrier = "FedEx";
      this.orderdetail.labels[0].ShippingOption = "FEDEX_2_DAY_OneRate";
    } else {
      alert('Please select a shipping option for this order.');
      return;
    }

    this.shippingService.changeShippingOption(this.orderdetail)
      .subscribe(response => {
        alert(response['errorMsg']);
      });

  }

  printLabel() {

  }

  printPackingSlip() {
    if (!this.orderdetail.packingSlip) {
      alert('Not found any packing slip for this order. Please make sure whether a packing slip is existed or not.');
    } else {
      print_zpl(this.orderdetail.packingSlip.dfr + this.orderdetail.packingSlip.xfr);
    }
  }

  selectSku(index: number, sku) {
    var i = this.itemlist.findIndex(i => i.sku == sku);
    this.orderdetail.items[index].desc = this.itemlist[i].desc;
  }

  selectDesc(index: number, desc) {
    var i = this.itemlist.findIndex(i => i.desc == desc);
    this.orderdetail.items[index].sku = this.itemlist[i].sku;
  }

}

class ItemList {
  id: string;
  sku: string;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
}
