import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { User } from '@app/_models';
import { Shipping } from '../Class';


@Injectable({ providedIn: 'root' })

export class StatisticsService {
  constructor(private http: HttpClient) { }

  getFulfillmentReport(customer: string, startAt, endAt) {
    return this.http.get(`${environment.apiUrl}/home/GetFulfillmentReport/${customer}/${startAt}-${endAt}`);
  }
  
}

