
  <div class="container">
    <h4>Order Upload</h4>
    <table>
      <tr>
        <td>
          Customer:  <select [(ngModel)]="thecustomer">
            <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>
          <input type="file" (change)="fileProgress($event)">
        </td>
        <td>
          <button (click)="onSubmit()">Submit</button>
        </td>
      </tr>
    </table>
   
    <br />
    
    <div>
      Template download ->
      <button mdbBtn type="button" color="success" rounded="true" (click)="ExportTOExcel()" class="btn btn-success" size="sm" mdbWavesEffect>Excel</button>
    </div>



    <table class="table table-bordered table-sm table-hover" #TABLE #table>
      <thead>
        <tr>
          <th scope="col">Location</th>
          <th scope="col">Order</th>
          <th scope="col">Name</th>
          <th scope="col">Address1</th>
          <th scope="col">Address2</th>
          <th scope="col">City</th>
          <th scope="col">State</th>
          <th scope="col">Zip</th>
          <th scope="col">Email</th>
          <th scope="col">Phone</th>
          <th scope="col">Qty</th>
          <th scope="col">Sku</th>
          <th scope="col">Country</th>
          <th scope="col">Carrier</th>
          <th scope="col">Shipping</th>
          <th scope="col">Memo</th>
          <th scope="col">Signature</th>
          <th scope="col">Residential</th>
          <th scope="col">Insurance</th>
          <th scope="col">Price</th>
        </tr>
      </thead>
    </table>

  </div>
