import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpUrlEncodingCodec } from '@angular/common/http';
import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })

export class PackingService {
  constructor(private http: HttpClient) { }

  completePacking(cus, strShippingInfo) {
    var url: string = `${environment.apiUrl}/packing/CompletedPacking`;
    return this.http.post<object[]>(url, { customer: cus, strShippingInfo: strShippingInfo });
  }

  getBoxUpdated(cus, strShippingInfo) {
    var url: string = `${environment.apiUrl}/packing/getBoxUpdated`;
    return this.http.post<object[]>(url, { customer: cus, strShippingInfo: strShippingInfo });
  }

  generateLabel(cus, orderno) {
    var ordernoNumber = orderno.match(/\d+/g);
    var url: string = `${environment.apiUrl}/packing/generateLabel`;
    return this.http.post<object[]>(url, { customer: cus, orderNo: Number(ordernoNumber[0]) });
  }

  getLabelUrl(cus, CustomerNo ) {
    var url: string = `${environment.apiUrl}/packing/getLabelUrl`;
    return this.http.post<object[]>(url, {customer:cus, customerNo: CustomerNo});
  }

  //generateLabel2(cus, shipping) {
  //  var url: string = `${environment.apiUrl}/packing/generateLabel2`;
  //  return this.http.post<object[]>(url, { customer: cus, strShippingInfo: shipping });
  //}

  getRMATicket(cus, orderno) {
    var url: string = `${environment.apiUrl}/packing/getRMATicket`;
    return this.http.post<object[]>(url, { customer: cus, orderNo: Number(orderno) });
  }

  getShipping(cus: string, orderno: string) {
    var url: string = `${environment.apiUrl}/packing/GetShipping`;
    return this.http.post<object[]>(url, { customer: cus, orderNo: orderno});
  }

  getShippingWithSetting(cus: string, orderno: string) {
    var url: string = `${environment.apiUrl}/packing/GetShippingWithSetting`;
    return this.http.post<object[]>(url, { customer: cus, orderNo: orderno });
  }

  unsetPackingSlipEndAt(cus, orderno) {
    var url: string = `${environment.apiUrl}/packing/UnsetPackingSlipEndAt`;
    return this.http.post<object[]>(url, { customer: cus, orderNo: Number(orderno) });
  }
  

}

