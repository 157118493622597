
<h1>Task List</h1>
<div *ngIf="isLoading">
  <div style="position:fixed;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: fixed;
    width: 100vw;
    text-align: center;
    top: 40%;">
    <img src="/assets/images/loader.svg" />
    <br />
    <h3 *ngIf="printTotalCount != null"><b>[{{printIndex}} of {{printTotalCount}}] Printing {{printProgress}}%...</b></h3>
  </div>
</div>

<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" class="float-left">
  <div class="row" style="padding-bottom:5px; padding-right:10px;">

    <div style="padding-right:15px;">
      <select formControlName="location" class="browser-default custom-select" style="width: 100px;font-size: small;">
        <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
    <div>
      <select formControlName="customer" class="browser-default custom-select" style="width:120px;font-size:small;">
        <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
    <div class="checkbox-container" style="">
      <mdb-checkbox [(ngModel)]="showAll" (change)="showAllTasks($event)">Show All Last 2 weeks</mdb-checkbox>
    </div>
    <div style="padding-left:15px;padding-right:15px;">
      <button mdbBtn class="btn btn-amber" rounded="true" size="sm" type="submit" mdbWavesEffect>Submit</button>

    </div>
    <div style="padding-top:10px;" [hidden]="!total">
      Total: {{total}}
    </div>
  </div>
</form>
<div align="right">
  <button mdbBtn type="button" color="blue-grey" rounded="true" (click)="createTasksLambda()" class="btn btn-success" size="sm" mdbWavesEffect>Create Tasks (Lambda)</button>
  <button mdbBtn type="button" color="success" rounded="true" (click)="ExportTOExcel()" class="btn btn-success" size="sm" mdbWavesEffect>Excel</button>
</div>

<table class="table table-bordered table-sm table-hover" #TABLE #table>
  <thead>
    <tr>
      <th scope="col">task#</th>
      <th scope="col">location</th>
      <th scope="col">memo</th>
      <th scope="col">count</th>
      <th scope="col">closedCount</th>
      <th scope="col">cancelledCount</th>
      <th scope="col">createdBy</th>
      <th scope="col">createdAt</th>
      <th scope="col">startAt</th>
      <th scope="col">endAt</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <!--headElements = ['customerId', 'orderNo', 'tplId', 'name', 'address', 'Item Count', 'weight', 'orderAt'];-->
    <tr *ngFor="let el of elements; let i = index">
      <td>
        <a [routerLink]="['/task',el.customer,el.packingSlipPrint]">{{el.packingSlipPrint}}</a>
      </td>
      <td>
        {{el.location}}
      </td>
      <td>{{el.memo}}</td>
      <td>{{el.shippingIdList.length}}</td>
      <td [class.bg-danger]="(el.closedCount + el.cancelledCount)!=el.shippingIdList.length">{{el.closedCount}}</td>
      <td [class.bg-warning]="el.cancelledCount!=0">{{el.cancelledCount}}</td>
      <td>{{el.createdBy}}</td>
      <td>{{el.createdAt | date:'MM/dd/yyyy HH:mm'}}</td>
      <td>{{el.startAt | date:'MM/dd/yyyy HH:mm'}}</td>
      <td>{{el.endAt | date:'MM/dd/yyyy HH:mm'}}</td>
      <td>
        <button *ngIf="isAdmin" data-toggle="modal" data-target="#basicExample" (click)="showConfirmModal(holdframe, el)"  mdbWavesEffect>Gen. Shipping Labels</button>
        <!--(click)="generateLabelsAll(el.customer,el.shippingIdList,el.packingSlipPrint)"-->
        <button (click)="printPackingSlipAll(el.customer,el.packingSlipPrint)">Print</button>
        <button (click)="changeLocation(el.customer,el.packingSlipPrint,el.location)">Change Location</button>
      </td>
    </tr>
  </tbody>
</table>


<div mdbModal #holdframe="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Generate All Labels</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="holdframe.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
          <p>
            Do you really want to print out all of shipping labels?
          </p>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="danger" class="waves-effect" (click)="holdframe2nd.show()" mdbWavesEffect>
          Yes
        </a>
        <a type="button" mdbBtn color="danger" outline="true" class="waves-effect" mdbWavesEffect (click)="holdframe.hide()">Cancel</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<!--2nd alert to confirm generating labels-->

<div mdbModal #holdframe2nd="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Generate All Labels 2nd</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="holdframe.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
          <p>
            Shipping labels have already been printed. Do you really want to print all labels again?
          </p>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="danger" class="waves-effect" (click)="holdframe3rd.show()"  mdbWavesEffect>
          Yes
        </a>
        <a type="button" mdbBtn color="danger" outline="true" class="waves-effect" mdbWavesEffect (click)="holdframe.hide()" (click)="holdframe2nd.hide()">Cancel</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<!--3rd alert to confirm generating labels-->

<div mdbModal #holdframe3rd="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Generate All Labels 3rd</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="holdframe.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
          <p>
            Please confirm 1 more time that you want to print all labels again!
          </p>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="danger" class="waves-effect" (click)="thirdComfirmation(holdframe, holdframe2nd, holdframe3rd)" mdbWavesEffect>
          Yes
        </a>
        <a type="button" mdbBtn color="danger" outline="true" class="waves-effect" mdbWavesEffect (click)="holdframe.hide()" (click)="holdframe3rd.hide()" (click)="holdframe2nd.hide()">Cancel</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<div class="regen-container">
  <div>Re-generate by CustomerNo List</div>
  <div>
    <input type="text"
           class="input-regen-text"
           [formControl]="customerNos"
           placeholder="CustomerNo List (e.g. 90012332,90012333,90012335,90012335)" />
    <button mdbBtn type="button" color="blue-grey" rounded="true" (click)="regenerateTasks()" class="btn btn-success" size="sm" mdbWavesEffect>Re-generate Tasks</button>
  </div>
</div>
