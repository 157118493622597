import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { ItemService } from '../../_services/item.service';
import { ShippingService } from '../../_services/shipping.service';
import { ReturnService } from '../../_services/return.service';
import * as XLSX from 'xlsx';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { AuthenticationService } from '../../_services';


@Component({
  templateUrl: './return-list.component.html',
  styleUrls: ['./return-list.component.scss']
})

export class ReturnListComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ReturnList.xlsx');
  }

  public myDatePickerOptions: IMyOptions = {
    firstDayOfWeek: 'su' // 'mo' - Monday, 'tu' - Tuesday, 'we' - Wednesday, 'th' - Thursday, 'fr' - Friday, 'sa' - Saturday, 'su' - Sunday
  };

  mainForm;
  memoForm;
  elements: any = [];
  total: number;
  locations: string[] = ["All", "AWISEC", "AWICAS"];
  customers: string[] = AuthenticationService.customers;
  isLoading: boolean = false;

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,
    private returnService: ReturnService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute, private location: Location) {
    this.authenticationService.getCustomer().then(x => this.customers = x);
  }

  ngOnInit() {
    var startAt = new Date(new Date().setHours(0, 0, 0, 0))
    startAt.setMonth(startAt.getMonth() - 1);
    this.activatedRoute.paramMap.subscribe(params => {
      this.mainForm = this.fb.group({
        customer: params.get('customer') ? params.get('customer') : 'Krave',
        location: params.get('location') ? params.get('location') : 'All',
        startAt: startAt,
        endAt: new Date(new Date().setHours(0, 0, 0, 0))
      });
      this.onSubmit();
    });
    this.memoInit();
  }

  onMemoSubmit(customerData) {
    this.memoForm.get('customer').setValue(this.mainForm.get('customer').value);
    console.warn(customerData);

    if (this.memoForm.valid) {
      this.returnService.returnMemo(this.memoForm.value).subscribe(data => {

        if (data["isSuccess"]) {
          this.memoInit();
          this.onSubmit();
        }
        else {
          alert(data["errorMsg"]);
        }
      });
    }
    else {
      alert(this.memoForm.status);
    }
  }

  onSubmit() {
    this.isLoading = true;
    this.location.replaceState(`/return-list/${this.mainForm.value.customer}/${this.mainForm.value.location}`);
    this.returnService.getRRList(this.mainForm.value).subscribe(data => {
      console.log(data);
      this.isLoading = false;
      this.elements = data;
    });
  }

  delete(rNo: string) {
    console.log(this.mainForm.get('customer').value, rNo);
    this.returnService.deleteRR(this.mainForm.get('customer').value, rNo).subscribe(response => {
      if (response["isSuccess"] == true) {
        alert(response["errorMsg"]);
      } else {
        alert(response["errorMsg"]);
      }
    });
  }

  memoInit() {
    this.memoForm = this.fb.group({
      customer: this.mainForm.get('customer').value,
      receivedNo: '',
      content: ''
    });
  }

  gotoRRCreation() {
    this.router.navigate(['/return']);
  }

  gotoReorder(rrno, no) {
    this.router.navigate(['/reorderinit', this.mainForm.get('customer').value, rrno, no]);
  }

  unholdRR(rNo: string) {
    this.returnService.unholdRR(this.mainForm.get('customer').value, rNo).subscribe(response => {
      alert('successfully restock.');

      //  this.onSubmit();
    });
  }

  unholdRR2(rNo: string, sku: string, qty: number, grade: string) {
    console.log(rNo, sku, qty, grade);

    if (qty == 0) {
      alert('Qty is 0.');
    }
    else if (qty > 0) {
      this.returnService.unholdRR2(this.mainForm.get('customer').value, rNo, sku, qty, grade).subscribe(response => {
        if (response['isSuccess'] == true) {
          alert('item restocked');
        } else {
          alert('could not restock because ' + response['errorMsg']);
        }

        //  this.onSubmit();
      });
    }
    
   
  }

}



