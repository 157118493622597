import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe, Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { ItemService } from '../../_services/item.service';
import { ShippingService } from '../../_services/shipping.service';
import { TaskService } from '../../_services/task.service';
import * as XLSX from 'xlsx';
import { AuthenticationService } from '../../_services';
import { MdbCheckboxChange } from 'ng-uikit-pro-standard';

@Component({
  templateUrl: './rest-list.component.html',
  styleUrls: ['./rest-list.component.scss']
  
})

export class RestListComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'RestList.xlsx');
  }

  mainForm;
  memoForm;
  elements: any[] = [];
  total: number;
  showAll: boolean = true;
  itemlist: ItemList[];
  yesterday = new Date();
  locations: string[] = ["All", "AWISEC", "AWICAS"];
  customers: string[] = [];
  checkedlist: string[] = [];
  mastercheck: boolean = false;
  atask: any;
  user: User;
  isLoading: boolean = false;
  lastChecked: any = null;

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,
    private itemService: ItemService, private shippingService: ShippingService,
    private location: Location,
    private taskService: TaskService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute) {
    console.log(router.routerState);
    this.user = this.authenticationService.userValue;
    this.authenticationService.getCustomer().then(x => this.customers = x);
    activatedRoute.paramMap.subscribe(params => {

      this.mainForm = fb.group({
        customer: params.get('customer') ? params.get('customer') : this.user.customer,
        location: params.get('location') ? params.get('location') : this.user.location,
      });
      this.onSubmit();
    });
    
    this.memoInit();
  }

  ngOnInit() {
    this.yesterday.setDate(this.yesterday.getDate() - 1);
   
  }

  createCheckedList(event: any, elem: any) {
    if (!this.lastChecked) {
      this.lastChecked = event.currentTarget;
      return;
    }

    var domList = Array.prototype.slice.call(document.querySelectorAll('.checkbox-input'));
    if (event.shiftKey) {
      const from = domList.indexOf(this.lastChecked);
      const to = domList.indexOf(event.currentTarget);
      const start = Math.min(from, to);
      const end = Math.max(from, to) + 1;
      domList.slice(start, end).forEach(x => {
        x.checked = this.lastChecked.checked;
      });
      this.lastChecked = null;
    }
  }

  holdShipping() {
    var domList = Array.prototype.slice.call(document.querySelectorAll('.checkbox-input'));
    var checkedList = domList.filter(x => x.checked).map(x => x.value);

    if (confirm(`Do you want to make a hold for selected orders?`)) {
      //  console.log(this.checkedlist);
      
      this.shippingService.holdMultipleOrders(this.mainForm.get('customer').value, checkedList).subscribe(data => {
        alert('Successfully marked as hold.');
        this.onSubmit();

      })
      this.checkedlist = [];
    }
  }

  memoInit() {
    this.memoForm = this.fb.group({
      customer: this.mainForm.get('customer').value ,
      orderNo: '',
      content: ''
    });
  }

  isEarlierthanYesterday(index: number): boolean {
    //console.log(this.elements[index].paidAt);
    let elpaid = new Date(this.elements[index].paidAt);
    let paidyear = elpaid.getFullYear();
    let paidmonth = elpaid.getMonth();
    let paiddate = elpaid.getDate();
    if ((paidmonth <= this.yesterday.getMonth() && paiddate <= this.yesterday.getDate()) || (paidyear < this.yesterday.getFullYear()) ||
      (paidmonth < this.yesterday.getMonth() && paiddate >= this.yesterday.getDate())) {
      return true;
    } else {
      return false;
    }
  }

  onMemoSubmit(customerData) {
    this.memoForm.get('customer').setValue(this.mainForm.get('customer').value);

    if (this.memoForm.valid) {
      this.shippingService.memo(this.memoForm.value).subscribe(data => {
        //console.log(data);

        if (data["isSuccess"]) {
          this.memoInit();
          this.onSubmit();
        }
        else {
          alert(data["errorMsg"]);
        }
      });
    }
    else {
      alert(this.memoForm.status);
    }

    //this.checkoutForm.reset();
  }
  showAllTasks(event: MdbCheckboxChange) {
    if (!event.checked) {
      this.elements = this.elements.filter(x => !x.packingSlipPrint);
      this.total = this.elements.length;
    }
    else {
      this.onSubmit();
    }
  }

  onSubmit() {
    this.isLoading = true;
    this.location.replaceState(`/rest-list/${this.mainForm.value.customer}/${this.mainForm.value.location}`);
    this.shippingService.getRestList(this.mainForm.value)
      .subscribe(data => {
        this.isLoading = false;
        this.elements = data as any[];
        this.total = (data as object[]).length;
      });
  }

 

  passthelist() {
    var domList = Array.prototype.slice.call(document.querySelectorAll('.checkbox-input'));
    var checkedList = domList.filter(x => x.checked).map(x => x.value);

    for (let i = 0; i < checkedList.length; i++) {
      for (let j = 0; j < this.elements.length; j++) {
        if (checkedList[i] == this.elements[j].id && this.elements[j].packingSlipPrint != null) {
          alert('At least one of the checked items has a task number.');
          return;
        }
      }
    }

    if (this.mainForm.get('location').value == "All") {
      alert('The location should either AWISEC or AWICAS. Please select orders.');
      return;
    } else if (checkedList.length == 0) {
      alert('Not found any selected orders. Please select orders and try again.');
      return;
    } else {
      this.taskService.createTasks(checkedList, this.mainForm.get('customer').value,this.mainForm.get('location').value)
        .subscribe(data => {
          this.atask = data;
          if (this.atask != null) {
            alert('task created.');
          }
          this.onSubmit();
      }, data => {
        alert('Failed to generate a task.');
      }, () => {
      });
    }
  }

  onCheckboxSelected(e) {
    this.mastercheck = !this.mastercheck;
    var domList = Array.prototype.slice.call(document.querySelectorAll('.checkbox-input'));
    domList.forEach(x => {
      x.checked = this.mastercheck;
    });
  }
   
 

}

class ItemList {
  id: string;
  sku: string;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
}


