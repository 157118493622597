import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { ItemService } from '../../_services/item.service';
import { ShippingService } from '../../_services/shipping.service';
import * as XLSX from 'xlsx';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { AuthenticationService } from '../../_services';
import * as moment from 'moment';


@Component({
  templateUrl: './undelivered-list.component.html',
  styleUrls: ['./undelivered-list.component.scss']
})

export class UndeliveredListComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'UndeliveredOrderList.xlsx');
  }


  mainForm;
  elements: any = [];
  total: number;
  itemlist: ItemList[];
  locations: string[] = ["All", "AWISEC", "AWICAS"];
  customers: string[] = AuthenticationService.customers;
  isLoading: boolean = false;
  user: User;
  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,
    private location: Location,
    private itemService: ItemService, private shippingService: ShippingService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute) {
    this.mainForm = fb.group({
      
    });
    this.user = this.authenticationService.userValue;
    this.authenticationService.getCustomer().then(x => this.customers = x);
    activatedRoute.paramMap.subscribe(params => {
      this.mainForm = fb.group({
        customer: params.get('customer') ? params.get('customer') : this.user.customer,
        location: params.get('location') ? params.get('location') : this.user.location,
      });
      this.onSubmit(this.mainForm.value);
    });
  }

  lastTrackingInfo(tracks: any[]) : string {
    var length = tracks.length;
    if (length > 0) return tracks[length - 1].statusDesc;
    return '';
  }

 
  countingEnRouteDays(tracks: any[]): number {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    if (tracks.some(x => x.statusDesc == "En Route") && !tracks.some(x => x.statusDesc == "Delivered")) {
      var enRoute = tracks.find(x => x.statusDesc == "En Route");
      var activityOn_temp = new Date(enRoute.activityOn).toUTCString();
      var activityOn = new Date(Date.parse(activityOn_temp.substring(0, 16)));
      var result = activityOn.getTime() - today.getTime(); // skip the first day
      return Math.ceil(Math.abs(result) / (1000 * 60 * 60 * 24));
    } else {
      return 0;
    }
  }

  activityOn(tracks) {
    if (tracks.some(x => x.statusDesc == "En Route") && !tracks.some(x => x.statusDesc == "Delivered")) {
      var enRoute = tracks.find(x => x.statusDesc == "En Route");
      var enrouteDays = new Date(enRoute.activityOn).toUTCString();
      //enrouteDays;
      //moment(enRoute.activityOn).diff(new Date())
      //var enrouteDays = new Date(moment(enRoute.activityOn).format('MM/DD/YYYY'));
      return new Date(Date.parse(enrouteDays.substring(0, 16))).toDateString();
    } else {
      return 0;
    }
  }

  today() {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }


  onSubmit(formData) {
    this.isLoading = true;
    this.location.replaceState(`/undelivered-list/${this.mainForm.value.customer}/${this.mainForm.value.location}`);
    this.shippingService.getUndeliveredOrders(this.mainForm.value.customer, this.mainForm.value.location)
      .subscribe(data => {
        this.isLoading = false;
        this.elements = data;
        this.total = this.elements.length;
        console.log(this.elements);
      });
  
  }


}

class ItemList {
  id: string;
  sku: string;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
}


