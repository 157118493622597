import { Component, HostListener, ViewChild, OnInit, Input, ElementRef, EventEmitter } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormArray, ValidationErrors, AbstractControl, NgModel, NgControl } from '@angular/forms';
import { Shipping, Package, ItemQty, Box, Address, SimpleLabel, Item } from '../../Class';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { delay, switchMap, map, tap } from 'rxjs/operators';
import { Time } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService, AuthenticationService } from '@app/_services';
import { ItemService } from '../../_services/item.service';
import { ShippingService } from '../../_services/shipping.service';
import { User } from '../../_models';

@Component({
  templateUrl: './reorder.component.html',
  styleUrls: ['./reorder.component.scss']
})

export class ReorderComponent {
  isLoading: boolean = false;
  customers: string[] = AuthenticationService.customers;
  locations: string[] = ["AWISEC", "AWICAS"]
  shippingOptions: string[] = ["Standard", "Ground", "Priority", "Express"]
  shippingOption: string;
  reasons: string[] = ["Missing", "Delay", "Preshipment", "RTS", "Missing Items", "Damaged", "Other"]
  address: Address = new Address;
  itemlist: ItemList[];
  MaxOrderNo: number;
  index: number;
  shippingForm;
  memoForm;
  isManual: boolean = false;
  tempitems: any[];
  estimWeight: string;
  weight: string;
  user: User;
  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private shippingService: ShippingService,
    private itemService: ItemService,
    private authenticationService: AuthenticationService) {
    this.authenticationService.getCustomer().then(x => this.customers = x);
  }


  ngOnInit() {
    this.user = this.authenticationService.userValue;

    this.shippingForm = this.fb.group({
      customer: this.user.customer,
      customerno: '',
      orderno: Number,
      neworderno: 0,
      parentorderno: Number,
      location: '',
      weight: '0',
      shippingOption: '',
      items: this.fb.array([

      ]),

      shipTo: new Address(),

      labels: this.fb.array([
        this.addlabel()
      ]),

      isChargetoCustomer: true,
      content: ['']
    })

    this.shippingService.getMaxOrderNo2(this.shippingForm.get('customer').value)
      .subscribe(data => {
        this.MaxOrderNo = data as number + 1;
        this.shippingForm.get('neworderno').setValue(this.MaxOrderNo);
      });

  }

  customerchange(): void {
    this.shippingService.getMaxOrderNo(this.shippingForm.value).subscribe(data => {
      this.MaxOrderNo = data as number + 1;
      this.shippingForm.get('neworderno').setValue(this.MaxOrderNo);
    });
  }

  submit() {
    this.isLoading = true;
    //shippingOption update
    if (this.shippingOption == null || this.shippingOption == '') {
      alert('please select shippingoption.');
      return;
    }
    else {
      this.shippingForm.get('shippingOption').setValue(this.shippingOption);
    }

    //item update
    for (let i = 0; i < this.tempitems.length; i++) {
      (<FormArray>this.shippingForm.get('items'))
        .push(this.fb.group({
          sku: this.tempitems[i].sku,
          desc: this.tempitems[i].desc,
          qty: this.tempitems[i].qty,
          fulfillableQty: this.tempitems[i].qty
        }));
    }
    //shipTo update
    this.shipTo.setValue(this.address);
    this.getTotalWeight();
    this.shippingService.reOrderCreation(this.shippingForm.value)
      .subscribe(data => {
        if (data['isSuccess'] == true) {
          const currentUser = this.authenticationService.userValue;
          this.memoForm = this.fb.group({ customer: this.shippingForm.get('customer').value, content: 'reorder created by ' + currentUser.username });
          console.log(this.memoForm.value);
          this.shippingService.returnMemo(this.memoForm.value)
            .subscribe(response => {
              console.log(response);
            });

          alert('successfully created at order # ' + data['orderNo']);
          
        } else {
          alert('fail to create the reorder becuase ' + data['errorMsg']);
          console.log(data);
        }
        this.isLoading = false;
        this.ngOnInit();
        this.tempitems = [];
      });

  }


  loadinfo(): void {
    this.itemService.getItemList(this.shippingForm.get('customer').value)
      .subscribe(data => {
        this.itemlist = data as ItemList[];
        console.log(this.itemlist);
      });
    console.log(this.shippingForm.value.orderno);
    this.shippingService.getOrderDetail(this.shippingForm.get('customer').value, this.shippingForm.get('orderno').value)
      .subscribe(data => {

        console.log(data);
        this.shippingForm.get('customerno').setValue(data.customerNo);
        this.shippingForm.get('parentorderno').setValue(data.orderNo);
        //   this.shippingForm.get('orderno').setValue(this.MaxOrderNo);
        this.shippingForm.get('location').setValue(data.location);
        this.address = data.shipTo;
        this.tempitems = data.items;

        for (let i = 0; i < this.tempitems.length; i++) {
          let n = this.itemlist.findIndex(j => j.sku == this.tempitems[i].sku);
          if (n > -1) {
            this.tempitems[i].desc = this.itemlist[n].desc;
          }
        }
        //this.getWeight();
        console.log('submit before', this.shippingForm.value);
      });
  }

  getWeight() {
    var result = 0;
    for (let i = 0; i < this.tempitems.length; i++) {
      let n = this.itemlist.findIndex(j => j.sku == this.tempitems[i].sku);
      if (n > -1) {
        result += this.tempitems[i].qty * this.itemlist[n].weight;
      }
    }
    this.estimWeight = (result / 453.592 + 0.2).toFixed(2);
  }

  get shipTo() {
    return this.shippingForm.get('shipTo');
  }

  addlabel(): FormGroup {
    return this.fb.group({
      IsSignature: [Boolean],
      IsCertified: [Boolean],
      Carrier: String,
      ShippingOption: String
    })
  }

  ordernumberchange(): void {
    console.log('changed');
    this.clearFormArray(this.shippingForm.get('items'));
    console.log(this.items);
  }

  clearFormArray = (formarr: FormArray) => {
    while (formarr.length !== 0) {
      formarr.removeAt(0)
    }
    this.add();
  }

  get labels(): Array<SimpleLabel> {
    return <Array<SimpleLabel>>this.shippingForm.get('labels').value as Array<SimpleLabel>;
  }

  whichCarrier(index: number): string {
    if (this.labels[index].Carrier == 'Endicia') {
      return 'Endicia';
    }
    if (this.labels[index].Carrier == 'FedEx') {
      return 'FedEx';
    }
  }

  get items(): Array<Item> {
    return this.shippingForm.get('items') as Array<Item>;
  }

  add(): void {
    (<FormArray>this.shippingForm.get('items')).push(this.additem());
  }

  additem(): FormGroup {
    return this.fb.group({
      sku: ['', Validators.required],
      desc: [''],
      qty: [Number],
      fulfillableQty: [Number]
    })
  }

  addlist() {
    this.tempitems.push(new Item);
  }

  delete(index): void {
    (<FormArray>this.shippingForm.get('items')).removeAt(index);
    const totalWeight = this.getTotalWeight();
  }

  deletelist(index): void {
    this.tempitems.splice(index, 1);
    const totalWeight = this.getTotalWeight();
    console.log(this.tempitems);
  }

  selectSku(index: number) {
    this.index = this.itemlist.findIndex(i => i.sku == this.tempitems[index].sku);
    this.tempitems[index].desc = this.itemlist[this.index].desc;
    this.tempitems[index].weight = this.itemlist[this.index].weight;
  //  this.shippingForm.setControl('items', this.setDesc(this.tempitems));
    if (!this.tempitems[index].qty) {
      this.tempitems[index].qty = 1;
    }
    console.log(this.tempitems);
    const totalWeight = this.getTotalWeight();
    
    console.log(totalWeight);
  }
  qtyChanged(index: number) {
    const totalWeight = this.getTotalWeight();
  }
  setDesc(item: Item[]): FormArray {
    const formArray = new FormArray([]);
    item.forEach(i => {
      formArray.push(this.fb.group({
        sku: i.sku,
        desc: i.desc,
        qty: i.qty,
        fulfillableQty: i.fulfillableQty
      }));
    });
    return formArray;
  }

  selectDesc(index: number) {
    this.index = this.itemlist.findIndex(i => i.desc == this.tempitems[index].desc);
    this.tempitems[index].sku = this.itemlist[this.index].sku;
    this.tempitems[index].weight = this.itemlist[this.index].weight;
    if (!this.tempitems[index].qty) {
      this.tempitems[index].qty = 1;
    }
  //  this.shippingForm.setControl('items', this.setDesc(this.items));
    const totalWeight = this.getTotalWeight();
    
    console.log(totalWeight);
    console.log(this.tempitems);
  }

  getTotalWeight() {
    const totalWeight = this.tempitems.reduce((prev, curr, index) => {
      const fitleredItems = this.itemlist.filter(x => x.sku == curr.sku);
      let currWeight = 0;
      if (fitleredItems.length > 0) {
        currWeight = fitleredItems[0].weight;
      }

      return prev + (currWeight * (curr.qty ? curr.qty : 1));
    }, 0);
    const lbsConversion = (totalWeight * 0.00220462).toFixed(2);
    this.estimWeight = lbsConversion;
    this.shippingForm.get('weight').setValue(parseInt(lbsConversion));
    return lbsConversion;

}

  public statelist = [
    { name: "Alabama" }, { name: 'Alaska' }, { name: 'Arizona' },
    { name: 'Arkansas' }, { name: 'California' }, { name: 'Colorado' },
    { name: 'Connecticut' }, { name: 'Delaware' }, { name: 'Florida' },
    { name: 'Georgia' }, { name: 'Hawaii' }, { name: 'Idaho' },
    { name: 'Illinois' }, { name: 'Indiana' }, { name: 'Iowa' },
    { name: 'Kansas' }, { name: 'Kentucky' }, { name: 'Louisiana' },
    { name: 'Maine' }, { name: 'Maryland' }, { name: 'Massachusetts' },
    { name: 'Michigan' }, { name: 'Minnesota' }, { name: 'Mississippi' },
    { name: 'Missouri' }, { name: 'Montana' }, { name: 'Nebraska' },
    { name: 'Nevada' }, { name: 'New Hampshire' }, { name: 'New Jersey' },
    { name: 'New Mexico' }, { name: 'New York' }, { name: 'North Carolina' },
    { name: 'North Dakota' }, { name: 'Ohio' }, { name: 'Oklahoma' },
    { name: 'Oregon' }, { name: 'Pennsylvania' }, { name: 'Rhode Island' },
    { name: 'South Carolina' }, { name: 'South Dakota' }, { name: 'Tennessee' },
    { name: 'Texas' }, { name: 'Utah' }, { name: 'Vermont' },
    { name: 'Virginia' }, { name: 'Washington' }, { name: 'AWest Virginia' },
    { name: 'Wisconsin' }, { name: 'Wyoming' }
  ]
}

class ItemList {
  id: string;
  sku: string;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
}

//class Item {
//  sku: string;
//  desc: string;
//  qty: number;
//  fulfillableQty: number;
//}
