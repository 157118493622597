import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { Time } from '@angular/common';
import { ShippingService } from '../../_services/shipping.service';
import { TaskService } from '../../_services/task.service';
import * as XLSX from 'xlsx';
import { MdbCheckboxChange } from 'ng-uikit-pro-standard';
import { AuthenticationService } from '../../_services';
import { ICustomer } from '../../../models';

declare function printLabels(zpl: string, successCallback: () => void, errorCallback: () => void): void;

@Component({
  templateUrl: './packingsliplist-hidden.component.html',
  styleUrls: ['./packingsliplist-hidden.component.scss']
})

export class PackingSlipListHiddenComponent {
  elements: any = [];
  total: number;
  filters: Filter[] = [];
  checkedlist: string[] = [];
  isLoading: boolean = false;
  printProgress: number;
  printTotalCount?: number = null;
  printIndex?: number = null;
  showAll: boolean = true;
  customers: string[] = [];
  mainForm;
  user: User;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private shippingService: ShippingService,
    private taskService: TaskService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute) {
    this.authenticationService.user.subscribe(x => this.user = x);
    this.authenticationService.getCustomer().then(x => this.customers = x);

    activatedRoute.paramMap.subscribe(params => {
      this.showAll = params.get('showAll') ? params.get('showAll') == 'true' : true;
      this.mainForm = fb.group({
        customer: params.get('cus') ? params.get('cus') : this.user.customer,
        taskNo: params.get('no') ? params.get('no') : this.user.location,
        showAllTask: this.showAll,
        customerNos: '',
        page: 1
      });
      this.onSubmit();
    });
  }

  sub;
  cus;
  no;

  @ViewChild('input1') inputEl1: ElementRef;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';

  changeIndividualLocation(value: any, id: string) {
    if (value.currentTarget.checked == true) {
      this.checkedlist.push(id);
    }
    if (value.currentTarget.checked == false) {
      const index: number = this.checkedlist.indexOf(id);
      if (index !== -1) {
        this.checkedlist.splice(index, 1);
      }
    }
    for (let i = 0; i < this.checkedlist.length; i++) {
      console.log(this.checkedlist[i]);
    }
  }


  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `TaskList_${this.mainForm.value.taskNo}.xlsx`);
  }

  ngOnInit() {

    setTimeout(() => this.inputEl1.nativeElement.focus(), 300);
  }
  onSubmit() {
    this.isLoading = true;
    this.shippingService.getshippingListbyTaskNo(this.mainForm.value.customer, this.mainForm.value.taskNo, this.mainForm.value.showAllTask).subscribe(data => {
      this.isLoading = false;
      this.elements = data["list"];
      this.total = data["total"];
    });
  }
  showAllTasks(event: MdbCheckboxChange) {
    this.showAll = event.checked;
    this.mainForm.value.showAllTask = this.showAll;

    this.onSubmit();
  }
  OrderNoList: number[] = [];
  printPackingSlip() {
    if (this.isLoading) {
      return;
    }
    if (confirm("Do you really want to print out selected shipping labels?")) {
      const orderMongoIdList = this.checkedlist;
      const customer = this.mainForm.value.customer;
      const taskNo = this.mainForm.value.taskNo;
      this.printTotalCount = orderMongoIdList.length;
      this.isLoading = true;
      var refresh = () => {
        this.printTotalCount = null;
        this.isLoading = false;
        //this.onSubmit();
      }

      var generateLabelTask = (cust: string, shippingId: string, index: number) => {
        this.printIndex = index + 1;
        this.printProgress = ~~((this.printIndex / this.printTotalCount) * 100);
        console.log('printing ' + shippingId)
        try {
          const shipping = this.elements.filter(x => x.id == shippingId);
          console.log(shipping);

          if (shipping && shipping.length > 0) {
            console.log(shipping[0].packingSlip.dfr + shipping[0].packingSlip.xfr);
            printLabels(shipping[0].packingSlip.dfr + shipping[0].packingSlip.xfr, function () {
              if ((index + 1) % 10 == 0) {
                setTimeout(() => generatePackingSlipLabel(customer, orderMongoIdList, index + 1), 1000);
              }
              else {
                setTimeout(() => generatePackingSlipLabel(customer, orderMongoIdList, index + 1), 300);
              }
            }, function () { generatePackingSlipLabel(customer, orderMongoIdList, index + 1); });
          }
          else {
            generatePackingSlipLabel(customer, orderMongoIdList, index + 1);
          }
        } catch (e) {

        }
        //setTimeout(function () { generateLabel(customer, orderMongoIdList, index + 1) }, 300);
      }
      function generatePackingSlipLabel(customer: string, orderMongoIdList: string[], currentIndex: number) {
        if (orderMongoIdList.length > currentIndex) {

          const mongoShippingId = orderMongoIdList[currentIndex];
          generateLabelTask(customer, mongoShippingId, currentIndex);
        }
        else {
          this.isLoading = false;
          refresh();
          alert(`[${taskNo}] All the shipping labels were printed successfully.`)
        }

      }

      generatePackingSlipLabel(customer, orderMongoIdList, 0);
    }
  }

  onSearchChange(searchValue: string) {
    this.router.navigate(['/packinghiddenlink', this.mainForm.value.customer, searchValue]);
  }

  passthecheckedlist() {
    if (this.checkedlist.length == 0) {alert('No selected orders.'); return;}

    const loc = this.elements[0].location;
    for (let i = 0; i < this.checkedlist.length; i++) {
      for (let j = 0; j < this.elements.length; j++) {
        if (this.checkedlist[i] == this.elements[j].id && this.elements[j].packingEndAt != null) {
          alert('Fail: At least one of the checked items has been packed.');
          return;
        }

        if (this.checkedlist[i] == this.elements[j].id && this.elements[j].closedAt != null) {
          alert('Fail: At least one of the checked items has been closed.');
          return;
        }
      }
    }

    console.log(this.checkedlist);
    this.taskService.changeIndividualTaskLocation(this.mainForm.value.customer, loc, this.checkedlist).subscribe(data => {
      console.log(data);
      if (data == 1) {
        alert('successfully moved.');
      } else {
        alert('fail to move.');
      }
    });
   
  }

 
  removeOrder(orderno: number) {
    console.log(orderno);

    var targetorder = this.elements.find(a => a.orderNo == orderno);
    console.log(targetorder);

    if (targetorder.labels || targetorder.labels != null) {

      if (targetorder.labels.length > 0 && targetorder.labels[0].trackingNo != null) {
        alert('Cannot remove this order from the task: The label has been created.');
      } else {
        this.shippingService.removeOrderFromTask(targetorder.customer, targetorder.orderNo).subscribe(data => {
          alert('order removed');
          this.onSubmit();
        });
      }
    }

    else if (targetorder.packingEndAt != null) {
      alert('Cannot cancel: The items have been packed.');
    }

    else {
      this.shippingService.removeOrderFromTask(targetorder.customer, targetorder.orderNo).subscribe(data => {
        alert('order removed');
        this.onSubmit();
      });
    }
  }
  
  printSelectedOrderLabels() {
    this.generateLabels(this.mainForm.value.customer, this.checkedlist, this.mainForm.value.taskNo);
  }
  generateLabels(customer: string, orderMongoIdList: string[], taskNo: number) {
    if (this.isLoading) {
      return;
    }
    if (confirm("Do you really want to print out selected shipping labels?")) {
      this.printTotalCount = orderMongoIdList.length;
      this.isLoading = true;
      var refresh = () => {
        this.printTotalCount = null;
        this.isLoading = false;
        this.onSubmit();
      }

      var generateLabelTask = (cust: string, shippingId: string, index: number) => {
        this.printIndex = index + 1;
        this.printProgress = ~~((this.printIndex / this.printTotalCount) * 100);
        this.taskService.generateLabel(customer, shippingId).subscribe(response => {
          if (response["isSuccess"]) {
            try {
              printLabels(response["labelImage"], function () {

              }, function () { });
            } catch (e) {

            }
            try {
              printLabels(response["rmaImage"], function () {
                if ((index + 1) % 10 == 0) {
                  setTimeout(() => generateLabel(customer, orderMongoIdList, index + 1), 1000);
                }
                else {
                  setTimeout(() => generateLabel(customer, orderMongoIdList, index + 1), 300);
                }
              }, function () { });
            } catch (e) {

            }
          } else {
            generateLabel(customer, orderMongoIdList, index + 1);
          }
        });
        //setTimeout(function () { generateLabel(customer, orderMongoIdList, index + 1) }, 300);
      }
      function generateLabel(customer: string, orderMongoIdList: string[], currentIndex: number) {
        if (orderMongoIdList.length > currentIndex) {

          const mongoShippingId = orderMongoIdList[currentIndex];
          generateLabelTask(customer, mongoShippingId, currentIndex);
        }
        else {

          refresh();
          alert(`[${taskNo}] All the shipping labels were printed successfully.`)
        }

      }

      generateLabel(customer, orderMongoIdList, 0);
    }
  }
}

class Filter {
  sku: string;
  qty: number;
}
