<nav class="navbar navbar-expand fixed-top navbar-dark bg-dark" *ngIf="user">
  <div class="navbar-nav">
    <div class="logo-container"><a href="/"><span class="awi-logo "><img src="/assets/images/logo-awi.png"></span></a></div>
    <a class="nav-item nav-link" routerLink="/">Home</a>
    <li class="nav-item dropdown" dropdown>
      <a dropdownToggle mdbWavesEffect type="button" (click)="ping()" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
        Order List<span class="caret"></span>
      </a>
      <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/rest-list']">Rest List</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/fulfilled-list']">Fulfilled List</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/task-list']">Task List</a>

        <a *ngIf="isAdmin" class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/taskhiddenlink-list']">Min's Hidden Task List</a>

        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/hold-list']">On Hold List</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/reorder-list']">Re-order List</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/undelivered-list']">Undelivered List</a>

      </div>
    </li>

    <li class="nav-item dropdown" dropdown>
      <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
        Return<span class="caret"></span>
      </a>
      <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/return-list']">Pending</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/restocked-list']">Closed</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/vendor-return-receipt-list']">Vendor Return Receipts</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/vendor-return-receipt']">Create Amazon Return</a>

        <!--<a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['/attention-list']">Attention</a>-->
      </div>
    </li>
    <!--<a class="nav-item nav-link" [routerLink]="['/restocked-list']">Restocked</a>
  <a class="nav-item nav-link" [routerLink]="['/return-list']">Return</a>-->

    <li class="nav-item dropdown" dropdown>
      <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
        Settings<span class="caret"></span>
      </a>
      <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/item-list']">Items</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/box-list']">Boxes</a>
      </div>
    </li>
    <li class="nav-item dropdown" dropdown>
      <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
        Order Creation<span class="caret"></span>
      </a>
      <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/order-creation']">Manual</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/reorder']">Re-order</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/excelorder']">Order Upload</a>
      </div>
    </li>

    <a class="nav-item nav-link" (click)="ping()" [routerLink]="['/orderdetail']">Order Detail</a>
    <li class="nav-item dropdown" dropdown>
      <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
        Labels<span class="caret"></span>
      </a>
      <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/shipment-creation']">Label Creation</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/shipment-list']">Label Print History</a>
      </div>
    </li>

    <li class="nav-item dropdown" dropdown>
      <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
        Reports<span class="caret"></span>
      </a>
      <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/inventory']">Inventory</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/statistics']">Fulfillment Summary</a>
      </div>
    </li>

    <a class="nav-item nav-link user-name" (click)="ping()" *ngIf="!isAdmin">Hi, {{user.firstName}}  {{user.lastName}}</a>
    <a class="nav-item nav-link" (click)="ping()" [routerLink]="['/admin']" *ngIf="isAdmin">Admin</a>

    <a class="nav-item nav-link" (click)="ping()" (click)="logout()">Logout</a>



  </div>
</nav>
