<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>
<div>
  <form [formGroup]="mainForm" (ngSubmit)="onSubmit()">

    <div class="row" style="padding-bottom:5px; padding-right:10px; padding-left: 16px;">
      <div>
        <select formControlName="customer" class="browser-default custom-select" style="width: 100px;font-size: small;">
          <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
        </select>
      </div>
      <div style="padding-left:15px;padding-right:15px;">
        <button mdbBtn class="btn btn-amber" size="sm" type="submit" mdbWavesEffect>Submit</button>
      </div>
    </div>
  </form>
  <div align="right">
    <button (click)="exportToExcel()">Excel</button>
    <button (click)="exportToPDF()">PDF</button>
  </div>

  <div class="row" id="content2" #content2  #TABLE>

    <div class="col-sm-6 mb-3 mb-md-0" style="padding-top:30px;">
      <mdb-card class="card-container">
        <mdb-card-body>
          <mdb-card-title>
            <h4>Inventory Overview (NJ)</h4>
          </mdb-card-title>
          <mdb-card-text>
            <table class="table table-bordered table-sm">
              <thead>
                <tr style="text-align: center; font-weight:bold; background-color: lightgray;">
                  <td>Sku</td>
                  <td>Description</td>
                  <td>On Hand</td>
                  <td>Available</td>
                  <td>Avg Qty / Day</td>
                  <td>Days to Cover</td>
                  <td>Estimated Stock Out Date</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let el of inventoryInSEC; let i = index">
                  <td>{{el.sku}}</td>
                  <td>{{el.description}}</td>
                  <td style="text-align:center;">{{el.onHandQty}}</td>
                  <td style="text-align:center;">{{el.availableQty}}</td>
                  <td style="text-align:center;">{{el.avgSalesQty}}</td>
                  <td style="text-align:center;">{{el.daysToCover}}</td>
                  <td style="text-align:center;">{{el.expectedStockOut | date:'MM-dd-yy':'GMT'}}</td>
                </tr>
                <tr *ngFor="let box of boxInventory">
                  <td>{{box.name}}</td>
                  <td></td>
                  <td></td>
                  <td style="text-align:center;">{{box.onhandQty.awisec}}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </mdb-card-text>
        </mdb-card-body>
      </mdb-card>
    </div>
    <div class="col-sm-6" style="padding-top:30px;">
      <mdb-card class="card-container">
        <mdb-card-body>
          <mdb-card-title>
            <h4>Inventory Overview (CA)</h4>
          </mdb-card-title>
          <mdb-card-text>
            <table class="table table-bordered table-sm">
              <thead>
                <tr style="text-align: center; font-weight:bold; background-color: lightgray;">
                  <td>Sku</td>
                  <td>Description</td>
                  <td>On Hand</td>
                  <td>Available</td>
                  <td>Avg Qty / Day</td>
                  <td>Days to Cover</td>
                  <td>Estimated Stock Out Date</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let el of inventoryInCAS; let i = index">
                  <td>{{el.sku}}</td>
                  <td>{{el.description}}</td>
                  <td style="text-align:center;">{{el.onHandQty}}</td>
                  <td style="text-align:center;">{{el.availableQty}}</td>
                  <td style="text-align:center;">{{el.avgSalesQty }}</td>
                  <td style="text-align:center;">{{el.daysToCover}}</td>
                  <td style="text-align:center;">{{el.expectedStockOut | date:'MM-dd-yy':'GMT'}}</td>
                </tr>
                <tr *ngFor="let box of boxInventory">
                  <td>{{box.name}}</td>
                  <td></td>
                  <td></td>
                  <td style="text-align:center;">{{box.onhandQty.awicas}}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </mdb-card-text>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</div>
