import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { ICustomer } from '../../models';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  public static customers: string[] | null = null;
  public static customerPrefixes: string[] | null = null;
  private isOpen:boolean = true;
  
  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
    
  }

  public async getCustomer() {
    if (AuthenticationService.customers) return AuthenticationService.customers;
   
    await this.getCustomersViaApi();
    return AuthenticationService.customers;
  }

  public async getCustomerPrefix() {
    if (AuthenticationService.customerPrefixes) return AuthenticationService.customerPrefixes;
    await this.getCustomersViaApi();
    return AuthenticationService.customerPrefixes;
  }

  private async getCustomersViaApi() {
    const customers = await this.http.get<ICustomer[]>(`${environment.apiUrl}/home/customers/`)
      .toPromise();
    AuthenticationService.customers = customers.map(x => x.customerName);
    AuthenticationService.customerPrefixes = customers.map(x => x.prefix);
  }

  public get userValue(): User {
    
    return this.userSubject.value;
  }

  public get tokenTimeOut(): string {
    const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now();// - (60 * 1000);

    const numSec = timeout / 1000 / 60;
    return Math.floor(numSec) + 'm ' + Math.floor((numSec - Math.floor(numSec)) * 60) + "s";
  }

  login(username: string, password: string) {
    console.log('login: ' + environment.apiUrl);
    return this.http.post<any>(`${environment.apiUrl}/users/authenticate`, { username, password })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('refreshToken', user.refreshToken);
        localStorage.setItem('uspsCarrier', user.uspsCarrier);
        this.userSubject.next(user);
        return user;
      }));
  }

  ping() {
    
    console.log('ping: ' + environment.apiUrl);
    let refreshToken: string = null;
    
    if (this.userValue != null) {
      refreshToken = this.userValue.refreshToken;
    }
    else {
      this.router.navigate(['/login'], { queryParams: { redirectUrl: location.pathname } });
    }
    return this.http.post<any>(`${environment.apiUrl}/users/ping`, { token: refreshToken })
      .subscribe(user => {
        //localStorage.setItem('refreshToken', user.refreshToken);
        return user;
      });
  }

  logout() {
    console.log('refreshToken', this.userValue);
    //alert(this.userValue);

    let refreshToken: string = null;
    if (this.userValue != null) {
      refreshToken = this.userValue.refreshToken;
    }

    this.http.post<any>(`${environment.apiUrl}/users/revoke-token`, { token: refreshToken }, { withCredentials: true }).subscribe();

    localStorage.removeItem('user');

    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.router.navigate(['/login'], { queryParams: { redirectUrl: location.pathname } });
  }

  refreshToken() {
    console.log('refreshToken');
   // debugger;
    let refreshToken: string = localStorage.getItem('refreshToken');
    //console.log('refreshToken', refreshToken);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    };
    return this.http.post<any>(`${environment.apiUrl}/users/refresh-token`, { token: refreshToken }, httpOptions)
      .pipe(map((user) => {
        console.log('refreshToken pipe', user);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('refreshToken', user.refreshToken);
        this.userSubject.next(user);
        //this.startRefreshTokenTimer();

        this.isOpen = true;

        return user;
      }));
  }
  
  public refreshTokenTimer() {
    if (this.isOpen) {
      this.isOpen = false;
      setTimeout(() => this.refreshToken().subscribe(), 0);
    }
  }

  // helper methods
  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    console.log('startRefreshTokenTimer');
    //debugger;
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    console.log('stopRefreshTokenTimer');
    //debugger;
    clearTimeout(this.refreshTokenTimeout);
  }

  public static stateList = [
    { name: "Alabama", statecode: "AL" }, { name: 'Alaska', statecode: 'AK' }, { name: 'Arizona', statecode: 'AZ' },
    { name: 'Arkansas', statecode: 'AR' }, { name: 'California', statecode: 'CA' }, { name: 'Colorado', statecode: 'CO' },
    { name: 'Connecticut', statecode: 'CT' }, { name: 'Delaware', statecode: 'DE' }, { name: 'Florida', statecode: 'FL' },
    { name: 'Georgia', statecode: 'GA' }, { name: 'Hawaii', statecode: 'HI' }, { name: 'Idaho', statecode: 'ID' },
    { name: 'Illinois', statecode: 'IL' }, { name: 'Indiana', statecode: 'IN' }, { name: 'Iowa', statecode: 'IA' },
    { name: 'Kansas', statecode: 'KS' }, { name: 'Kentucky', statecode: 'KY' }, { name: 'Louisiana', statecode: 'LA' },
    { name: 'Maine', statecode: 'ME' }, { name: 'Maryland', statecode: 'MD' }, { name: 'Massachusetts', statecode: 'MA' },
    { name: 'Michigan', statecode: 'MI' }, { name: 'Minnesota', statecode: 'MN' }, { name: 'Mississippi', statecode: 'MS' },
    { name: 'Missouri', statecode: 'MO' }, { name: 'Montana', statecode: 'MT' }, { name: 'Nebraska', statecode: 'NE' },
    { name: 'Nevada', statecode: 'NV' }, { name: 'New Hampshire', statecode: 'NH' }, { name: 'New Jersey', statecode: 'NJ' },
    { name: 'New Mexico', statecode: 'NM' }, { name: 'New York', statecode: 'NY' }, { name: 'North Carolina', statecode: 'NC' },
    { name: 'North Dakota', statecode: 'ND' }, { name: 'Ohio', statecode: 'OH' }, { name: 'Oklahoma', statecode: 'OK' },
    { name: 'Oregon', statecode: 'OR' }, { name: 'Pennsylvania', statecode: 'PA' }, { name: 'Rhode Island', statecode: 'RI' },
    { name: 'South Carolina', statecode: 'SC' }, { name: 'South Dakota', statecode: 'SD' }, { name: 'Tennessee', statecode: 'TN' },
    { name: 'Texas', statecode: 'TX' }, { name: 'Utah', statecode: 'UT' }, { name: 'Vermont', statecode: 'VT' },
    { name: 'Virginia', statecode: 'VA' }, { name: 'Washington', statecode: 'WA' }, { name: 'West Virginia', statecode: 'WV' },
    { name: 'Wisconsin', statecode: 'WI' }, { name: 'Wyoming', statecode: 'WY' }
  ]

  public static countryCode = [
    { countryName: "Afghanistan", countryCode: "AF" }, { countryName: "Åland Islands", countryCode: "AX" }, { countryName: "Albania", countryCode: "AL" }, { countryName: "Andorra", countryCode: "AD" }, { countryName: "American Samoa", countryCode: "AS" }, { countryName: "Algeria", countryCode: "DZ" },
    { countryName: "Angola", countryCode: "AO" }, { countryName: "Anguilla", countryCode: "AI" }, { countryName: "Antarctica", countryCode: "AQ" }, { countryName: "Antigua and Barbuda", countryCode: "AG" }, { countryName: "Argentina", countryCode: "AR" }, { countryName: "Armenia", countryCode: "AM" },
    { countryName: "Aruba", countryCode: "AW" }, { countryName: "Australia", countryCode: "AU" }, { countryName: "Austria", countryCode: "AT" },
    { countryName: "Azerbaijan", countryCode: "AZ" }, { countryName: "Austria", countryCode: "AT" }, { countryName: "Bahamas", countryCode: "BS" }, { countryName: "Bahrain", countryCode: "BH" },
    { countryName: "Bangladesh", countryCode: "BD" }, { countryName: "Barbados", countryCode: "BB" }, { countryName: "Belarus", countryCode: "BY" }, { countryName: "Belgium", countryCode: "BE" },
    { countryName: "Belize", countryCode: "BZ" }, { countryName: "Benin", countryCode: "BJ" }, { countryName: "Bermuda", countryCode: "BM" },
    { countryName: "Bhutan", countryCode: "BT" }, { countryName: "Bolivia", countryCode: "BO" },
    { countryName: "Bonaire, Saint Eustatius and Saba", countryCode: "BQ" }, { countryName: "Bosnia-Herzegovina", countryCode: "BA" }, { countryName: "Botswana", countryCode: "BW" }, { countryName: "Bouvet Island", countryCode: "BM" },
    { countryName: "Brazil", countryCode: "BR" }, { countryName: "British Indian Ocean Territory", countryCode: "IO" }, { countryName: "Brunei Darussalam", countryCode: "BN" }, { countryName: "Bulgaria", countryCode: "BG" },
    { countryName: "Burkina Faso", countryCode: "BF" }, { countryName: "Burundi", countryCode: "BI" }, { countryName: "Cambodia", countryCode: "KH" }, { countryName: "Cameroon", countryCode: "CM" },
    { countryName: "Canada", countryCode: "CA" }, { countryName: "Cape Verde", countryCode: "CV" }, { countryName: "Cayman Islands", countryCode: "KY" }, { countryName: "Central African Republic", countryCode: "CF" }, { countryName: "Chad", countryCode: "TD" },
    { countryName: "Chile", countryCode: "CL" }, { countryName: "China", countryCode: "CN" },
    { countryName: "Christmas Island", countryCode: "CX" }, { countryName: "Cocos (Keeling) Islands", countryCode: "CC" }, { countryName: "Colombia", countryCode: "CO" }, { countryName: "Comoros", countryCode: "KM" },
    { countryName: "Congo", countryCode: "CG" }, { countryName: "Congo, the Democratic Republic of the", countryCode: "CD" }, { countryName: "Cook Islands", countryCode: "CK" }, { countryName: "Costa Rica", countryCode: "CR" },
    { countryName: "Côte d’Ivoire", countryCode: "CI" }, { countryName: "Croatia", countryCode: "HR" }, { countryName: "Cuba", countryCode: "CU" }, { countryName: "Curacao", countryCode: "CW" },
    { countryName: "Cyprus", countryCode: "CY" }, { countryName: "Czech Republic", countryCode: "CZ" }, { countryName: "Denmark", countryCode: "DK" }, { countryName: "Djibouti", countryCode: "DJ" },
    { countryName: "Dominica", countryCode: "DM" }, { countryName: "Dominican Republic", countryCode: "DO" }, { countryName: "Ecuador", countryCode: "EC" }, { countryName: "Egypt", countryCode: "EG" }, { countryName: "El Salvador", countryCode: "SV" },
    { countryName: "Equatorial Guinea", countryCode: "GQ" },
    { countryName: "Eritrea", countryCode: "ER" }, { countryName: "Estonia", countryCode: "EE" }, { countryName: "Ethiopia", countryCode: "ET" }, { countryName: "Falkland Islands", countryCode: "FK" },
    { countryName: "Faroe Islands", countryCode: "FO" }, { countryName: "Fiji", countryCode: "FJ" }, { countryName: "Finland", countryCode: "FI" }, { countryName: "France", countryCode: "FR" },
    { countryName: "Gambia", countryCode: "GM" }, { countryName: "Georgia", countryCode: "GE" }, { countryName: "Germany", countryCode: "DE" }, { countryName: "Ghana", countryCode: "GH" },
    { countryName: "Gibraltar", countryCode: "GI" }, { countryName: "Greece", countryCode: "GR" }, { countryName: "Greenland", countryCode: "GL" }, { countryName: "Grenada", countryCode: "GD" },
    { countryName: "Guadeloupe", countryCode: "GP" }, { countryName: "Guam", countryCode: "GU" }, { countryName: "Guatemala", countryCode: "GT" }, { countryName: "Guernsey", countryCode: "GG" },
    { countryName: "Guinea", countryCode: "GN" }, { countryName: "Guinea-Bissau", countryCode: "GW" },
    { countryName: "Guyana", countryCode: "GY" }, { countryName: "Haiti", countryCode: "HT" }, { countryName: "Holy See (Vatican City State)", countryCode: "VA" },
    { countryName: "Heard Island and McDonald Islands", countryCode: "HM" }, { countryName: "Honduras", countryCode: "HN" }, { countryName: "Hong Kong", countryCode: "HK" }, { countryName: "Iceland", countryCode: "IS" },
    { countryName: "India", countryCode: "IN" }, { countryName: "Indonesia", countryCode: "ID" }, { countryName: "Iran, Islamic Republic of", countryCode: "IR" }, { countryName: "Iraq", countryCode: "IQ" },
    { countryName: "Ireland", countryCode: "IE" }, { countryName: "Isle of Man", countryCode: "IM" }, { countryName: "Israel", countryCode: "IL" }, { countryName: "Italy", countryCode: "IT" },
    { countryName: "Jamaica", countryCode: "JM" }, { countryName: "Japan", countryCode: "JP" }, { countryName: "Jersey", countryCode: "JE" }, { countryName: "Jordan", countryCode: "JO" },
    { countryName: "Kazakhstan", countryCode: "KZ" }, { countryName: "Kenya", countryCode: "KE" }, { countryName: "Kiribati", countryCode: "KI" }, { countryName: "North Korea", countryCode: "KP" },
    { countryName: "Korea", countryCode: "KR" }, { countryName: "Kuwait", countryCode: "KW" }, { countryName: "Kyrgyzstan", countryCode: "KG" }, { countryName: "Lao People’s Democratic Republic", countryCode: "LA" },
    { countryName: "Latvia", countryCode: "LV" }, { countryName: "Lebanon", countryCode: "LB" }, { countryName: "Lesotho", countryCode: "LS" }, { countryName: "Liberia", countryCode: "LR" },
    { countryName: "Libyan Arab Jamahiriya", countryCode: "LY" }, { countryName: "Liechtenstein", countryCode: "LI" }, { countryName: "Lithuania", countryCode: "LT" },
    { countryName: "Luxembourg", countryCode: "LU" }, { countryName: "Macao", countryCode: "MO" }, { countryName: "Macedonia, the former Yugoslav Republic of", countryCode: "MK" }, { countryName: "Madagascar", countryCode: "MG" },
    { countryName: "Malawi", countryCode: "MW" }, { countryName: "Malaysia", countryCode: "MY" }, { countryName: "Maldives", countryCode: "MV" }, { countryName: "Mali", countryCode: "ML" },
    { countryName: "Malta", countryCode: "MT" }, { countryName: "Marshall Islands", countryCode: "MH" }, { countryName: "Martinique", countryCode: "MQ" }, { countryName: "Mauritania", countryCode: "MR" },
    { countryName: "Mauritius", countryCode: "MU" }, { countryName: "Mayotte", countryCode: "YT" }, { countryName: "Mexico", countryCode: "MX" }, { countryName: "Micronesia, Federated States of", countryCode: "FM" },
    { countryName: "Moldova, Republic of", countryCode: "MD" }, { countryName: "Monaco", countryCode: "MC" }, { countryName: "Mongolia", countryCode: "MN" }, { countryName: "Montenegro", countryCode: "ME" },
    { countryName: "Montserrat", countryCode: "MS" }, { countryName: "Morocco", countryCode: "MA" }, { countryName: "Mozambique", countryCode: "MZ" }, { countryName: "Myanmar", countryCode: "MM" },
    { countryName: "Namibia", countryCode: "NA" }, { countryName: "Nauru", countryCode: "NR" }, { countryName: "Nepal", countryCode: "NP" }, { countryName: "Netherlands", countryCode: "NL" },
    { countryName: "New Caledonia", countryCode: "NC" }, { countryName: "New Zealand", countryCode: "NZ" }, { countryName: "Nicaragua", countryCode: "NI" }, { countryName: "Niger", countryCode: "NE" },
    { countryName: "Nigeria", countryCode: "NG" }, { countryName: "Niue", countryCode: "NU" }, { countryName: "Norfolk Island", countryCode: "NF" },
    { countryName: "Northern Mariana Islands", countryCode: "MP" }, { countryName: "Norway", countryCode: "NO" }, { countryName: "Oman", countryCode: "OM" },
    { countryName: "Pakistan", countryCode: "PK" }, { countryName: "Palau", countryCode: "PW" }, { countryName: "Palestinian Territory, Occupied", countryCode: "PS" }, { countryName: "Panama", countryCode: "PA" },
    { countryName: "Papua New Guinea", countryCode: "PG" }, { countryName: "Paraguay", countryCode: "PY" }, { countryName: "Peru", countryCode: "PE" }, { countryName: "Philippines", countryCode: "PH" },
    { countryName: "Pitcairn", countryCode: "PN" }, { countryName: "Poland", countryCode: "PL" }, { countryName: "Portugal", countryCode: "PT" }, { countryName: "Puerto Rico", countryCode: "PR" },
    { countryName: "Qatar", countryCode: "QA" }, { countryName: "Réunion", countryCode: "RE" }, { countryName: "Romania", countryCode: "RO" }, { countryName: "Russian Federation", countryCode: "RU" },
    { countryName: "Rwanda", countryCode: "RW" }, { countryName: "Saint Barthélemy", countryCode: "BL" }, { countryName: "Saint Helena, Ascension and Tristan da Cunha", countryCode: "SH" }, { countryName: "Saint Kitts and Nevis", countryCode: "KN" },
    { countryName: "Saint Lucia", countryCode: "LC" }, { countryName: "Saint Martin (French part)", countryCode: "MF" }, { countryName: "Saint Helena, Ascension and Tristan da Cunha", countryCode: "SH" }, { countryName: "Saint Kitts and Nevis", countryCode: "KN" },
    { countryName: "Saint Helena, Ascension and Tristan da Cunha", countryCode: "SH" }, { countryName: "Saint Kitts and Nevis", countryCode: "KN" }, { countryName: "Samoa", countryCode: "WS" }, { countryName: "San Marino", countryCode: "SM" },
    { countryName: "Sao Tome and Principe", countryCode: "ST" }, { countryName: "Saudi Arabia", countryCode: "SA" }, { countryName: "Senegal", countryCode: "SN" }, { countryName: "Serbia", countryCode: "RS" },
    { countryName: "Seychelles", countryCode: "SC" }, { countryName: "Sierra Leone", countryCode: "SL" }, { countryName: "Singapore", countryCode: "SG" },
    { countryName: "Sint Maarten (Dutch part)", countryCode: "SG" }, { countryName: "Slovakia", countryCode: "SK" }, { countryName: "Slovenia", countryCode: "SI" }, { countryName: "Solomon Islands", countryCode: "SB" },
    { countryName: "Somalia", countryCode: "SO" }, { countryName: "South Africa", countryCode: "ZA" }, { countryName: "South Georgia and the South Sandwich Islands", countryCode: "GS" }, { countryName: "Spain", countryCode: "ES" },
    { countryName: "Sri Lanka", countryCode: "LK" }, { countryName: "Sudan", countryCode: "SD" }, { countryName: "Suriname", countryCode: "SR" }, { countryName: "Svalbard and Jan Mayen", countryCode: "SJ" },
    { countryName: "Swaziland", countryCode: "SZ" }, { countryName: "Sweden", countryCode: "SE" }, { countryName: "Switzerland", countryCode: "CH" }, { countryName: "Syrian Arab Republic", countryCode: "SY" },
    { countryName: "Taiwan, Province of China", countryCode: "TW" }, { countryName: "Tajikistan", countryCode: "TJ" }, { countryName: "Tanzania, United Republic of", countryCode: "TZ" }, { countryName: "Thailand", countryCode: "TH" },
    { countryName: "Timor-Leste", countryCode: "TL" }, { countryName: "Togo", countryCode: "TG" }, { countryName: "Tokelau", countryCode: "TK" }, { countryName: "Tonga", countryCode: "TO" },
    { countryName: "Trinidad and Tobago", countryCode: "TT" }, { countryName: "Tunisia", countryCode: "TN" }, { countryName: "Turkey", countryCode: "TR" }, { countryName: "Turkmenistan", countryCode: "TM" },
    { countryName: "Turks and Caicos Islands", countryCode: "TC" }, { countryName: "Tuvalu", countryCode: "TV" }, { countryName: "Uganda", countryCode: "UG" }, { countryName: "Ukraine", countryCode: "UA" },
    { countryName: "United Arab Emirates", countryCode: "AE" }, { countryName: "United Kingdom", countryCode: "GB" }, { countryName: "United States", countryCode: "US" }, { countryName: "United States Minor Outlying Islands", countryCode: "UM" },
    { countryName: "Uruguay", countryCode: "UY" }, { countryName: "Uzbekistan", countryCode: "GB" }, { countryName: "Vanuatu", countryCode: "VU" }, { countryName: "Venezuela, Bolivarian Republic of", countryCode: "VE" },
    { countryName: "Viet Nam", countryCode: "VN" }, { countryName: "Virgin Islands, British", countryCode: "VG" }, { countryName: "Virgin Islands, U.S.", countryCode: "VI" }, { countryName: "Wallis and Futuna", countryCode: "WF" },
    { countryName: "Western Sahara", countryCode: "EH" }, { countryName: "Yemen", countryCode: "YE" }, { countryName: "Zambia", countryCode: "ZM" }, { countryName: "Zimbabwe", countryCode: "ZW" },
    ]

}
