import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })

export class ReturnService {
  constructor(private http: HttpClient) { }

  deleteRR(cus, rNo) {
    var url: string = `${environment.apiUrl}/return/DeleteRR`;
    return this.http.post(url, {customer: cus, rMANo: rNo});
  }

  getAttention(cus) {
    var url: string = `${environment.apiUrl}/return/GetAttentionList`;
    return this.http.post(url, { customer: cus });
  }

  getRestockList(formData) {
    var url: string = `${environment.apiUrl}/return/GetRestockedList`;
    return this.http.post(url, formData);
  }

  getRRList(formData) {
    var url: string = `${environment.apiUrl}/return/GetRRList`;
    return this.http.post(url, formData);
  }

  getReturnReceivingwithrma(cus, rma) {
    var url: string = `${environment.apiUrl}/return/GetReturnReceiving`;
    return this.http.post(url, { customer: cus, rMANo: rma });
  }

  getReturnReceivingwithloc(cus, loc) {
    var url: string = `${environment.apiUrl}/return/GetReturnReceiving`;
    return this.http.post(url, { customer: cus, location: loc });
  }

  postReturnReceiving(receivingOrshipping) {
    var url: string = `${environment.apiUrl}/return/PostReturnReceiving`;
    return this.http.post(url, receivingOrshipping);
  }

  updateReturnReceiving(receivingOrshipping) {
    var url: string = `${environment.apiUrl}/return/UpdateReturnReceiving`;
    return this.http.post(url, receivingOrshipping);
  }


  returnMemo(formData) {
    return this.http.post(`${environment.apiUrl}/return/memo`, formData);
  }


  putAttention(receivingOrshipping) {
      var url: string = `${environment.apiUrl}/return/PutAttention`;
    return this.http.post(url, receivingOrshipping);
  }

  confirmReceived(receivingOrshipping) {
    var url: string = `${environment.apiUrl}/return/Confirmation`;
    return this.http.post(url, receivingOrshipping);
  }

  getVendorReturnReceiptList(customer, location) {
    var url: string = `${environment.apiUrl}/return/GetVendorReturnReceipt/?customer=${customer}&location=${location}`;
    return this.http.get(url);
  }

  getNewVendorReturnReceipt(customer, location) {
    var url: string = `${environment.apiUrl}/return/GetNewVendorReturnReceipt`;
    return this.http.post(url, { customer: customer, location: location });
  }

  confirmVendorReturnReceipt(receipt) {
    var url: string = `${environment.apiUrl}/return/InsertVendorReturnReceipt`;
    return this.http.post(url, receipt);
  }
  
  unholdRR(cus, rma) {
    var url: string = `${environment.apiUrl}/return/UnHold`;
    return this.http.post(url, { customer: cus, rMANo: rma });
  }

  unholdRR2(cus, rma, sku, qty, grade) {
    var url: string = `${environment.apiUrl}/return/UnHold2`;
    return this.http.post(url, { customer: cus, rMANo: rma, sku: sku, unheldQty: qty, grade: grade });
  }

}

