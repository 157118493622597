import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Shipping, Item } from '../../Class';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { ReturnService } from '../../_services/return.service';
import { ItemService } from '../../_services/item.service';
import { DatePipe, Location } from '@angular/common';
import { AuthenticationService } from '../../_services';
import { IMyOptions } from 'ng-uikit-pro-standard';



declare function print_zpl(zpl: string): void;

@Component({
  //selector: 'app-return',
  templateUrl: './vendor-return-receipt.component.html',
  styleUrls: ['./vendor-return-receipt.component.scss']
})

export class VendorReturnReceiptComponent {
  receiving: any;
  RMANo: string;
  retruncodes: string[] = ["A - Item Damaged",
    "B - Wrong Item Shipped", "C - Not as Described",
    "D - Not as Expected", "E - Do not Want",
    "INSUFFICIENT ADDRESS", "ATTEMPTED - NOT KNOWN", "REFUSED", "UNCLAIMED", "OTHER"];
  returncodeinputs: string[];
  returncode: string;
  iteminputs: RMAList[] = [];
  customers: string[] = AuthenticationService.customers;
  itemList: any[] = [];
  barcodeValue: string;
  locations: string[] = ["AWISEC", "AWICAS"];
  customer: string;
  location: string;
  isLoading: boolean = false;
  trackingNo: string;

  @ViewChild('input1') inputEl1: ElementRef;
  @ViewChild('input2') inputEl2: ElementRef;

  public myDatePickerOptions: IMyOptions = {
    dateFormat: 'mm/dd/yyyy',
    firstDayOfWeek: 'su' // 'mo' - Monday, 'tu' - Tuesday, 'we' - Wednesday, 'th' - Thursday, 'fr' - Friday, 'sa' - Saturday, 'su' - Sunday
  };

  constructor(private fb: FormBuilder, private http: HttpClient,
    private returnService: ReturnService,
    private itemService: ItemService,
    private plocation: Location,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute) {
    this.authenticationService.getCustomer().then(x => this.customers = x);
    activatedRoute.paramMap.subscribe(params => {
      this.location = params.get('loc') ? params.get('loc') : '';
      this.RMANo = params.get('rmano') ? params.get('rmano') : '';
    });
  }

  

  onSubmit() {
    if (this.customer == null || this.location == null || this.location == "") {
      alert('please select customer and/or location.');
      return;
    }

    this.returnService.getNewVendorReturnReceipt(this.customer, this.location)
    .subscribe(response => {
      if (response["isSuccess"]) {
        console.log(response["data"]);
        this.receiving = response["data"];
        this.iteminputs = response["data"].items;
        this.trackingNo = "";
        setTimeout(() => this.inputEl2.nativeElement.focus());
      }
    });

    this.itemService.getItemList(this.customer).subscribe(data => {
      this.itemList = data;
      console.log(this.itemList);
    });
      
  }

  onExit() {
    this.receiving = null;
    this.iteminputs = [];
    this.plocation.replaceState(`/vendor-return-receipt`);
    setTimeout(() => this.inputEl2.nativeElement.focus());
  }

  onSkuChange(barcode: string) {
    if (!barcode || barcode == "") {
      alert('Please enter a barcode.');
      return;
    }

    let temp: RMAList = {
      receiptQty: 1,
      sku: barcode,
      upc: barcode,
      customer: this.customer,
     // expirationDate: new Date(),
      shelfName: "",
      note: "",
      trackingNo: "",
    };

    this.iteminputs.push(temp);
    this.barcodeValue = "";
    setTimeout(() => {
      var tableRows = (document.querySelector('#tblRetrunList') as HTMLTableElement).querySelectorAll('tr');
      if (tableRows) {
        var lastRow = tableRows[tableRows.length - 1];
        (lastRow.querySelector('.location-textbox') as HTMLInputElement).focus();
      }
    }, 300);
  }

  focusNextElement(el: HTMLInputElement) {
    (el.parentElement.nextSibling as any).querySelector('input').focus();
  }

  focusBarcodeText() {
    (document.querySelector('#txtItemBarcode') as HTMLInputElement).focus();
  }

  deleteRow(index: number) {
    this.iteminputs.splice(index, 1);
  }

  confirmation(receivedNo) {
    this.isLoading = true;
    this.receiving.trackingNo = this.trackingNo;
    this.receiving.items = this.iteminputs;
    console.log(this.receiving);
    this.returnService.confirmVendorReturnReceipt(this.receiving).subscribe(response => {
      if (response['isSuccess'] == true) {
        this.isLoading = false;
        alert('Your return request has been received successfully.');
        location.reload();
      } else {
        this.isLoading = false;
        alert(`Found some error(s). Please check erorr(s) below: \r\n\r\n${response['errorMsg']}`);
      }
    });

  }

  confirmAndKeepScanning(receivedNo) {
    this.isLoading = true;
    this.receiving.trackingNo = this.trackingNo;
    this.receiving.items = this.iteminputs;
    console.log(this.receiving);
    this.returnService.confirmVendorReturnReceipt(this.receiving).subscribe(response => {
      if (response['isSuccess'] == true) {
        alert('Your return request has been received successfully.');
        this.onSubmit();
        this.isLoading = false;
      } else {
        this.isLoading = false;
        alert(`Found some error(s). Please check erorr(s) below: \r\n\r\n${response['errorMsg']}`);
      }
    });
  }


}


class ItemList {
  id: string;
  sku: string;
  qty: number;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
  shelfName?: string;
  note?: string;
}

class RMAList {
  receiptQty: number;
  sku: string;
  //  qty: number;
  upc: string;
  //  upc2: string;
  //desc: string;
  //  weight: number;
  customer: string;
  //  isEditable: boolean = false;
  //grade: string;
  //note: string;
  expirationDate?: Date;
  shelfName: string;
  note: string;
  trackingNo: string;
}

