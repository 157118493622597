import { Component, OnInit, ViewChild, Pipe, PipeTransform, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { ItemService } from '../../_services/item.service';
import * as XLSX from 'xlsx';
import { AuthenticationService } from '../../_services';


@Component({
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'] })

export class ItemListComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ItemList.xlsx');
  }
  isLoading: boolean = false;
  searchText: string;
  mainForm;
  itemlist: ItemList[];
  customers: string[] = AuthenticationService.customers;
  fileData: File = null;
  user: User;
  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,
    private authenticationService: AuthenticationService,
    private itemService: ItemService) {
    this.user = this.authenticationService.userValue;
    this.authenticationService.getCustomer().then(x => this.customers = x);
    this.mainForm = fb.group({
      customer: this.user.customer
    });
    this.onSubmit(this.mainForm);
  }

  add(): void {
    var temp = new ItemList;
    temp.isEditable = true;
    this.itemlist.unshift(temp);
  }

  delete(index) {

    this.itemlist[index].customer = this.mainForm.get('customer').value;
    this.itemService.deleteItem(this.itemlist[index]).subscribe(data => {
      alert('successfully deleted.');
      this.onSubmit(this.mainForm);
    });

  }

  edit(index): void {
    this.itemlist[index].isEditable = !this.itemlist[index].isEditable;
    console.log(this.mainForm.get('customer').value);
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    //this.onSubmit();
  }
  onExcelUpload() {
    if (!this.fileData) {
      alert(`Please select an excel file to upload.`);
      return;
    }
    else {
      if (confirm(`Do you want to download the excel file for current item list?\r\nBecuase you could be losing current data after uploading.\r\nWe recommend to get current list just in case.`)) {
        this.ExportTOExcel();
      }
    }
    this.isLoading = true;
    const isOverwrite = true;
    const initAllLocations = true;
    this.itemService.uploadExcelFile(this.mainForm.get('customer').value, this.fileData, { isOverwrite, initAllLocations })
      .subscribe(res => {
        console.log(res);
        //res.data.filePath;
        this.isLoading = false;
        if (res["success"]) {
          //alert(`${res['newShippingDraftList'].length} order{${res['newShippingDraftList'].length > 0 ? 's ' : ''} created successfully.`);
          
          const result = res["value"];
          alert(`Uploaded successfully.\r\n${result.messages.map(x => `- ${x.message}`).join('\r\n')}`);
          this.onSubmit(this.mainForm);
          
        }
        else {
          alert(`${res['errorMsg']}`);
        }
      });
  }
  onSubmit(formData) {
    this.isLoading = true;
    this.itemService.getItemList(this.mainForm.get('customer').value)
      .subscribe(data => {
        this.isLoading = false;
        console.log(data);
        this.itemlist = data;
      });
  }

  update(index): void {
    if (!this.itemlist[index]) {
      alert('Not found item.');
    }
    this.itemlist[index].isEditable = !this.itemlist[index].isEditable;
    this.itemlist[index].sku = this.itemlist[index].sku.trim();
    this.itemlist[index].desc = this.itemlist[index].desc ? this.itemlist[index].desc.trim() : '';
    this.itemlist[index].customer = this.mainForm.get('customer').value;

    if (this.itemlist[index].id == null) {
      this.itemService.addItem(this.itemlist[index]).subscribe(data => {
        console.log(data);
        alert('successfully updated');
      });
    } else {
      this.itemService.updateItemList(this.itemlist[index]).subscribe(data => {
        console.log(data);
        alert('successfully updated');
      });
    }

   
  }

}

class ItemList {
  id: string;
  sku: string;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
}


